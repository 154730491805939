import React, { useState, useEffect, useContext } from "react";

import RouterContext from "../../context/RouterContext";
import "../../App.css";
import {updateSedute} from "./Utils";
import {properties} from "../../utils/properties";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

export default function MeetingList() {
    var dateStyle = {
        display: "flex",
        paddingLeft: "40px",        
    };

    const [meetingData, setMeetingData] = useState([]);
    const [bothRoles, setBothRoles] = useState(false);
    const [threeRoles, setThreeRoles] = useState(false);
    const [secondLevelButtons, setSecondLevelButtons]= useState(false);    
    const { pageRouter, setPageRouter } = useContext(RouterContext);    
    const navigate = useNavigate();

    const updateUI = function(jsonData) {      
        if( properties.verboseLog==1)
            console.log("UPDATEUI-LV");
        var entireMeeting = Object.entries(jsonData);                    
        var orderMeeting = [];
        for (var i = entireMeeting.length - 1; i >= 0; i--) {
            var eachMeeting = {
                id: entireMeeting[i][0],
                order: entireMeeting[i][1]["order"],                
                date: entireMeeting[i][1]["dataSeduta"],                
                };        
            orderMeeting.push(eachMeeting);
        }        
        orderMeeting.sort((a, b) => a.order > b.order ? 1 : -1)
        setMeetingData(orderMeeting);
    }

    const doPostSedute2 = function() {
        if( properties.verboseLog==1)
            console.log("doing sedute post LV");
        var xhr2 = new XMLHttpRequest();                        
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSeduteJson.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK DOPOSTSEDUTE",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        console.log("OK MEETINGLIST 204",xhr2);
                        const jsonListSedute = JSON.parse(localStorage.getItem("listSedute"));
                        updateUI(jsonListSedute);
                    }              
                    else{
                        //status 201
                        console.log("OK MEETINGLIST 201",xhr2);                            
                        try{
                            localStorage.setItem("listSedute", obj.content);
                            localStorage.setItem("listSeduteVersion", obj.date);                                                                                                                                           
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        const jsonListSedute = JSON.parse(obj.content);
                        updateUI(jsonListSedute);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    alert("Errore recuperando la seduta");  
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSedute!=='' &&localStorage.listSedute!==undefined){            
            //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersion");        
            urlEncodedData = "date=" + currentData;                    
        }            
        xhr2.send(urlEncodedData);
    }

    useEffect(() => {     
        if( properties.verboseLog==1){
            console.log("USEEFFECT CV");
            console.log("Arrivo qui", document.referrer);   
        }
        if(localStorage.getItem("new_ui")==="true"){
                navigate("/calendar",{ replace: true });  
        }          
                
        if (!window.cordova)
        {
          if(localStorage.getItem("ruolo")==="1"||localStorage.getItem("ruolo")==="2"){
              setBothRoles(true);
          }    
          if(localStorage.getItem("ruolo")==="12"|| localStorage.getItem("ruolo")==="21"){
            setThreeRoles(true);
            }    
        }    
        else {
          var onSuccess = function(response)
          {
            if( properties.verboseLog==1)
                console.log("OK LOGIN 33");
            var obj=JSON.parse(response);
            var ruolo = obj.Ruolo;

            if(ruolo=="1"||ruolo=="2"){
                setBothRoles(true);
            }
            if(ruolo =="12" || ruolo =="21" ){
                setThreeRoles(true);
            }
          }

          var onError = function() {}

          window.getInfo3(onSuccess, onError);
        }             

        if(!window.cordova){
            
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                            
            xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
            xhr.withCredentials = true;
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                                      
                        if( properties.verboseLog==1)
                            console.log("OK",xhr);                                                
                        updateSedute("Comitato",doPostSedute2, updateUI);
                } else {
                    console.error("Error 21", xhr);     
                    localStorage.clear();               
                    console.log("QWERTY 1",localStorage.getItem("name"));              
                    navigate("/login");
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 22", xhr);                                
                localStorage.clear();       
                if( properties.verboseLog==1)
                    console.log("QWERTY 2",localStorage.getItem("name"));                
                navigate("/login");
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
           updateSedute("Comitato",doPostSedute2, updateUI);   
        }
                         
    }, []);

    const onFabClick= (e ) => {           
        if( properties.verboseLog==1)
            console.log("OFC",e);    
        navigate("/consiglio");        
    };

    const onFabClick2= (e ) => {                   
        if( properties.verboseLog==1)
            console.log("OFC-2",e);        
        navigate("/collegio");
      };



    const topicList = (id) => {
        if( properties.verboseLog==1)
            console.log("called topic list LV",id);        
        let aa="/fascicoli/"+id;        
        navigate(aa);
    };

    return (
        <div className="content">
        {bothRoles ?(
         <Tooltip title="Consiglio Generale" placement="left">
            <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {onFabClick(event) }}                
                style={{
                    margin: 0,
                    //background: "#30720f",
                    background: "#BF360C",
                    top: 'auto',
                    right: 10,
                    bottom: 25,
                    color: '0xFFFFFF',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <ArrowForwardIosIcon />                    
            </Fab>  
          </Tooltip>
       ):( <></>)}
             {threeRoles ?(
   <Tooltip title="Altri Organi" placement="left">
      <Fab aria-label="add"                 
          //variant="contained"           
          onClick={(event) => {setSecondLevelButtons(!secondLevelButtons) }}           
          style={{
              margin: 0,
              //background: "#30720f",
              background: "#CCCCCC",
              top: 'auto',
              //left: 10,
              right: 10,
              bottom: 25,
              color: '0xFFFFFF',
              left: 'auto',
              position: 'fixed',}}>                    
              <MoreHorizIcon />                  
      </Fab>  
    </Tooltip>
 ):( <></>)}
   {threeRoles && secondLevelButtons ? (
    <div> 
        <Tooltip title="Consiglio Generale" placement="left">
            <Fab aria-label="add2"                 
                  //variant="contained" 
                  size='medium'
                  onClick={(event) => {onFabClick(event) }}
                  
                  style={{
                      margin: 0,
                      background: "#BF360C",
                      top: 'auto',                     
                      right: 10,
                      bottom: 90,                      
                      left: 'auto',
                      position: 'fixed',}}>                    
                      <ArrowForwardIosIcon />                        
            </Fab>       
        </Tooltip>
        <Tooltip title="Collegio Sindacale" placement="left">
            <Fab aria-label="add3"                                   
                  size='medium'
                  onClick={(event) => {onFabClick2(event) }}                  
                  style={{
                      margin: 0,
                      background: "#666999",
                      top: 'auto',                     
                      right: 10,
                      bottom: 145,
                      //color: '0xFF0000',
                      left: 'auto',
                      position: 'fixed',}}>                    
                      <ArrowForwardIosIcon />
            </Fab>
        </Tooltip>       
                  </div> 
            ): (<></>)}



            {meetingData.map((item, index) => (
                <div key={uuidv4()} className="scheda-item" style={{ borderRightColor: "#30720f" }} > 
                <div
                    className="common-list"
                    key={index}
                    style={{
                                cursor: "pointer",
                            }}
                    onClick={() => topicList(item.id)}
                >
                    <div style={dateStyle}>
                    <p className= "textBlackSedute">
                        Seduta del {item.date}
                        </p>
                    </div>
                    <div
                        style={{
                            paddingRight: "25px",
                            color: "#879497",
                            display: "flex",
                        }}
                    >
                        {index === 0 ? (
                            <div
                                style={{
                                    //background: "#ff5a60",
                                    background: "#30720f",
                                    marginRight: "40px",
                                    padding: "8px 10px",
                                    color: "white",
                                    borderRadius: "30px",
                                    fontSize: "13px",
                                }}
                            >
                                Nuova
                            </div>
                        ) : (
                            <></>
                        )}

                        <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>
                        
                    </div>
                </div>
                </div>
            ))}
        </div>
    );
}
