import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useDragOver } from "@minoru/react-dnd-treeview";
import { ArrowRight } from '@mui/icons-material';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import ClassificationMenu from "./ClassificationMenu3"
import "./sessionManagerStyles.css";
import TypeIcon from "./TypeIcon";
import {properties} from "../../utils/properties";

const CustomNode = (props) => {

  const {
    handleDelete,
    handleAddNewNode,
    handleChangeText,
    handleChangeColor,
    newNodeId,
    lockedFile,
    isCreated,
    handleMove,
    isOpen,
  } = props

  const { id, droppable, data } = props.node;
  const indent = props.depth * 24;
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleInput, setVisibleInput] = useState(false)
  const [labelText, setLabelText] = useState('')

  const handleToggle = () => {
    if( properties.verboseLog==1)    
      console.log("TOGGLE");
    props.onToggle(props.node.id);
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  const handleClick = (event) => {
    if( properties.verboseLog==1)
      console.log("EV",event,event.currentTarget);
    event.preventDefault();
    if (!lockedFile) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUpdateText = (e) => {
    if (e.key === "Enter") {
      if (newNodeId === "") {
        setVisibleInput(false)
      }      
      handleChangeText(id, labelText)
    }
  }

  const open = Boolean(anchorEl);

  const handleMovePos = (direction) => {
    handleClose()
    handleMove(direction, id)
  }

  useEffect(() => {   
    if( properties.verboseLog==1)
      console.log("CUSTOMNODE",props,data, props.node);
    props.node.isOpen=true;
    
    if (newNodeId === id) {     
      setVisibleInput(true)
      setLabelText('')
    } else {
      setVisibleInput(false)
    }    
  }, [newNodeId, props.node.text])  

  return (
    <>
     <div
    className="tree-node"
    style={{
      paddingInlineStart: indent,
      alignItems: "center",
      height: "36px",
      paddingInlineEnd: "8px",
      width: "80%",
      display: "flex",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    }}
    //aria-controls={`menu-${id}`}
    //aria-haspopup="true"
    
    onContextMenu={handleClick}
    {...dragOverProps}
  >
    <div 
      style={{
        alignItems: "center",
        fontSize: "0",
        cursor: "pointer",
        display: "flex",
        height: "24px",
        justifyContent: "center",
        width: "24px",
        transition: "transform linear 0.1s",
        transform: props.isOpen ? "rotate(90deg)" : "rotate(0deg)",
      }}
    >
      {props.node.droppable  && (
        //console.log("props",props.node),
        <div onClick={handleToggle}>
          <ArrowRight //style={{color:"blue",backgroundColor:"red"}}
          />
        </div>
      )} 
    </div>
    <div>
      <TypeIcon
        droppable={droppable}
        fileType={data?.fileType}
        folderType={data?.color}
        other={props.node}
        
      />
    </div>
    <div style={{ paddingInlineStart: "12px" }}>
      
      {visibleInput ? (
        <TextField
          id={`text-field-${id}`}
          variant="outlined"
          value={labelText}
          onChange={(e) => setLabelText(e.target.value)}
          onKeyPress={handleUpdateText}
          autoFocus
          sx={{
            "& .MuiInputBase-input": {
              paddingTop: "6px",
              paddingBottom: "6px",
              background: "white",
              width: "600px",
              
              //backgroundColor:"red",
              fontSize: "16px",
            },            
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black', 
              },
            },
            
          }}
        />
      ) : (        
        <Typography style={{fontSize: "16px" }} variant="body2">{props.node.text}</Typography>
      )}
    </div>
  </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          style={{  maxWidth: "360px",
          minWidth: "150px"}}
        >
          <ListItem button onClick={() => { handleAddNewNode(id); handleClose() }}>
            <ListItemText primary="Crea" />
          </ListItem>
          {
            id !== 1 && (
              <>
                <ListItem button onClick={() => { setLabelText(props.node.text); setVisibleInput(true); handleClose(); }}>
                  <ListItemText primary="Rinomina" />
                </ListItem>
                <ListItem button onClick={() => handleDelete(id)}>
                  <ListItemText primary="Elimina" />
                </ListItem>
                <ClassificationMenu handleChange={(_color) => { handleChangeColor(id, _color); handleClose(); }} />
                <ListItem button onClick={() => handleMovePos('up')}>
                  <ListItemText primary="Sposta su" />
                </ListItem>
                <ListItem button onClick={() => handleMovePos('down')}>
                  <ListItemText primary="Sposta giù" />
                </ListItem>
              </>
            )
          }

        </List>
      </Popover>
    </>
  )
}

export default CustomNode