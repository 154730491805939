import {React,  useRef} from "react";

import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import RouterContext from "../../context/RouterContext";
import {updateSedute} from "./Utils";
import {properties} from "../../utils/properties";
import multiMonthPlugin from '@fullcalendar/multimonth'
import FullCalendar from '@fullcalendar/react'
import itLocale from '@fullcalendar/core/locales/it'
import interactionPlugin from "@fullcalendar/interaction" 
import Popover from "@mui/material/Popover";
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText } from "@mui/material";

import '../../assets/bootstrap-custom.css';


export default function CalendarView() {
  
  var myRef= useRef()
  const navigate = useNavigate();
  const [meetingData, setMeetingData] = useState([]);
  const [bothRoles, setBothRoles] = useState(false);
  const [threeRoles, setThreeRoles] = useState(false);
  const [secondLevelButtons, setSecondLevelButtons]= useState(false);    
  const { pageRouter, setPageRouter } = useContext(RouterContext);
  const [startingYear, setStartingYear]= useState(0);
  
  
  const [eventsForDay, setEventsForDay] = useState([]); 
  const [selectedDate, setSelectedDate] = useState(""); 
  const [anchorEl, setAnchorEl] = useState(null);


  const open = Boolean(anchorEl);

  const handleClose = () => {    
    setAnchorEl(null);
  };
  
  
 
  const updateUI = function(jsonData) {      
        if( properties.verboseLog==1)
            console.log("UPDATEUI-LV");
        var entireMeeting = Object.entries(jsonData);                    
        if( properties.verboseLog==1)
            console.log("UPDATEUI-LV",entireMeeting);
        var orderMeeting = [];
        var colore='#30720f';
        for (var i = entireMeeting.length - 1; i >= 0; i--) {
            //"Entrambi", "Tetrambi","ConsColl","ComiColl"
            var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
            if(entireMeeting[i][1]["organo"]==="Entrambi"){
                var eachMeeting = {                            
                    name: 'Comitato: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                    
                    color: '#30720f',
                    organo:"Comitato",
                    type:"Entrambi",
                };        
                var eachMeeting2 = {                                       
                    name: 'Consiglio: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                    
                    color: '#BF360C',
                    organo:"Consiglio",
                    type:"Entrambi",
                };                 
                orderMeeting.push(eachMeeting);
                orderMeeting.push(eachMeeting2);                 
            }
            if(entireMeeting[i][1]["organo"]==="ComiColl"){
                var eachMeeting = {                    
                    name: 'Comitato: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                    
                    color: '#30720f',
                    organo:"Comitato",
                    type:"ComiColl",
                };        
                orderMeeting.push(eachMeeting);
                var eachMeeting2 = {                    
                    name: 'Collegio: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                    
                    color: '#666999',
                    organo:"Collegio",
                    type:"ComiColl",
                };        
                orderMeeting.push(eachMeeting2);
            }
            if(entireMeeting[i][1]["organo"]==="ConsColl"){                                
                var eachMeeting2 = {                    
                    name: 'Consiglio: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                    
                    color: '#BF360C',
                    organo:"Consiglio",
                    type:"ConsColl",
                };        
                orderMeeting.push(eachMeeting2);
                var eachMeeting = {                    
                    name: 'Collegio: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                    
                    color: '#666999',
                    organo:"Collegio",
                    type:"ConsColl",
                };      
                orderMeeting.push(eachMeeting);  
            }
            if(entireMeeting[i][1]["organo"]==="Tetrambi"){
                var eachMeeting = {              
                    name: 'Comitato: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                                        
                    color:'conic-gradient(red 0deg 120deg, green 120deg 240deg, blue 240deg 360deg)',
                    organo:"Comitato",
                    type:"Tetrambi"
                };        
                orderMeeting.push(eachMeeting);
                var eachMeeting2 = {                    
                    name: 'Consiglio: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                                        
                    color:'conic-gradient(red 0deg 120deg, green 120deg 240deg, blue 240deg 360deg)',
                    organo:"Consiglio",
                    type:"Tetrambi"
                };        
                orderMeeting.push(eachMeeting2);
                var eachMeeting3 = {                   
                    name: 'Collegio: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                                        
                    color:'conic-gradient(red 0deg 120deg, green 120deg 240deg, blue 240deg 360deg)',
                    organo:"Collegio",
                    type:"Tetrambi"
                };        
                orderMeeting.push(eachMeeting3);
            }
            
            if(entireMeeting[i][1]["organo"]==="Consiglio"){
                colore='#BF360C';                            
                var eachMeeting = {                    
                    name: 'Consiglio: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                    
                    color: '#BF360C',
                    organo:"Consiglio",
                    type:"Consiglio"
                };        
                orderMeeting.push(eachMeeting);
            }
            if(entireMeeting[i][1]["organo"]==="Comitato"||entireMeeting[i][1]["organo"]== undefined){                            
                colore='#30720f';        
                var eachMeeting = {                    
                    name: 'Comitato: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                    
                    color: '#30720f',
                    organo:"Comitato",
                    type:"Comitato"
                };
                orderMeeting.push(eachMeeting);
            }
            if(entireMeeting[i][1]["organo"]==="Collegio" ){                            
                colore='#666999';        
                var eachMeeting = {                    
                    name: 'Collegio: Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                                 
                    date:dateLabel,                    
                    color: '#666999',
                    organo:"Collegio",
                    type:"Collegio"
                };
                orderMeeting.push(eachMeeting);
            }
        }  
        //orderMeeting.sort((a, b) => a.order > b.order ? 1 : -1)
        orderMeeting.sort((a, b) => a.otherId < b.otherId ? 1 : -1);        
        if( properties.verboseLog==1)
            console.log("MEETING",orderMeeting);
        setMeetingData(orderMeeting);
    }

  
    //6
    const doPostSedute6 = function(jsonList) {
        if( properties.verboseLog==1)
            console.log("doing sedute post 6");        

        var xhr2 = new XMLHttpRequest();                        
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSeduteJsonCollegio.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {     
                    if( properties.verboseLog==1)                         
                        console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                        console.log("OK93",obj, obj.status);          
                    if(obj.status==="204" ){                             
                        console.log("OK CALENDARVIEWALL-6 204",xhr2);   
                        var mergedObject =undefined;                     
                        var jsonListSedute = JSON.parse(localStorage.getItem("listSeduteCollegio"));
                        if( properties.verboseLog==1)
                            console.log("BRUT",jsonListSedute,jsonList);
                        if(jsonListSedute!=undefined && jsonListSedute!=null &&jsonListSedute!=""){                          
                            if( properties.verboseLog==1)
                                console.log("2COL",jsonListSedute, "33COL", jsonList);                                                    
                            var uno=Object.entries(jsonList);
                            var die=Object.entries(jsonListSedute);
                            for(var i = 0, l = die.length; i < l; i++) {                               
                               if(die[i][1].order==0)
                               die[i][1].nuovo ="New";   
                            }
                            for(var i = 0, l = uno.length; i < l; i++) {                                
                                if(uno[i][1].order==0)
                               uno[i][1].nuovo ="New";   
                            }
                            if( properties.verboseLog==1){
                                console.log("UnoUnoCOL",uno);
                                console.log("DueUnoCOL",die);
                            }
                            for(var i = 0, l = die.length; i < l; i++) {
                                for(var j = 0, ll = uno.length; j < ll; j++) {                        
                                    if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                        if( properties.verboseLog==1)
                                            console.log("found2");
                                        if((uno[j][1]).organo=="Entrambi")
                                            die[i][1].organo="Tetrambi";
                                        if((uno[j][1]).organo=="Consiglio")
                                            die[i][1].organo="ConsColl";
                                        if((uno[j][1]).organo==undefined)
                                            die[i][1].organo="ComiColl";
                                        //die[i][1].organo="Entrambi";
                                        if(uno[j][1].nuovo=="New")
                                            die[i][1].nuovo="New";
                                    }                                   
                                }
                            }
                            jsonListSedute=Object.fromEntries(die);
                            jsonList=Object.fromEntries(uno);
                            if( properties.verboseLog==1)
                                console.log("PUS UNO COL",jsonListSedute);
                                                                                
                            mergedObject = {
                                ...jsonList,
                                ...jsonListSedute
                              };
                            if( properties.verboseLog==1)
                                console.log("212-COL",mergedObject);
                        }
                        else    
                            mergedObject= jsonList;
                        if( properties.verboseLog==1)
                            console.log("our coll",mergedObject);                        
                        updateUI(mergedObject);
                    }              
                    else{
                        //status 201
                        console.log("OK CALENDARVIEWALL-6 201",xhr2);  
                        
                        try{
                            localStorage.setItem("listSeduteCollegio", obj.content);
                            localStorage.setItem("listSeduteVersionCollegio", obj.date);                                      
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        var jsonListSedute2 = JSON.parse(obj.content);
                        var mergedObject=undefined;
                        if( properties.verboseLog==1)
                            console.log("AQUI2",jsonListSedute2,jsonList);
                        if(jsonListSedute2!=undefined && jsonListSedute2!=null &&jsonListSedute2!=""){                            

                            var uno=Object.entries(jsonList);
                            var die=Object.entries(jsonListSedute2);
                            if( properties.verboseLog==1){
                                console.log("Uno-",uno);
                                console.log("Due-",die);
                            }
                            for(var i = 0, l = die.length; i < l; i++) {
                                if( properties.verboseLog==1)
                                    console.log("Due-------",die[i][1]);
                                if(die[i][1].order == 0)
                                    die[i][1].nuovo = "New";                                    
                            }
                            for(var i = 0, l = uno.length; i < l; i++) {                                
                                if(uno[i][1].order == 0)
                                    uno[i][1].nuovo = "New";    
                            }       
                            if( properties.verboseLog==1){                     
                                console.log("Uno",uno);
                                console.log("Due",die);
                            }
                            for(var i = 0, l = die.length; i < l; i++) {
                                for(var j = 0, ll = uno.length; j < ll; j++) {
                                    if( properties.verboseLog==1)
                                        console.log("CHECK2", die[i] , uno[j]);
                                    if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                        if( properties.verboseLog==1)
                                            console.log("found2");
                                        //die[i][1].organo="Entrambi";                                           
                                        //
                                        if((uno[j][1]).organo=="Entrambi")
                                            die[i][1].organo="Tetrambi";
                                        if((uno[j][1]).organo=="Consiglio")
                                            die[i][1].organo="ConsColl";
                                        if((uno[j][1]).organo==undefined)
                                            die[i][1].organo="ComiColl";                                
                                        if(uno[j][1].nuovo=="New")
                                            die[i][1].nuovo="New";
                                        //
                                    }                                   
                                }
                            }
                            jsonListSedute2=Object.fromEntries(die);                            
                            jsonList=Object.fromEntries(uno);
                            if( properties.verboseLog==1)
                                console.log("PUS-COL",jsonListSedute2);
                            
                            mergedObject = {
                                ...jsonList,
                                ...jsonListSedute2
                              }; 
                            if( properties.verboseLog==1)
                                console.log("412-COL",mergedObject);
                        }
                            
                        else                                
                            mergedObject=jsonList;
                        if( properties.verboseLog==1)
                            console.log("our coll",mergedObject);                    
                        updateUI(mergedObject);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    //alert("Errore recuperando la lista sedute");  
                    var mergedObject=undefined;
                    console.log("Errore 23: Recuperando la lista sedute o non presenti sedute per il ruolo dell'utente");  
                    if(jsonList!=undefined && jsonList!=null &&jsonList!=""){                       
                        mergedObject = jsonList;
                        if( properties.verboseLog==1)
                            console.log("413 COL",mergedObject);
                    }                        
                    else                            
                        mergedObject=null;
                    if( properties.verboseLog==1)
                        console.log("our coll 2",mergedObject);                    
                    updateUI(mergedObject);
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSeduteCollegio!=='' &&localStorage.listSeduteCollegio!==undefined){            
            if( properties.verboseLog==1)
                console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersionCollegio");        
            urlEncodedData = "date=" + currentData;                    
        }       
        if( properties.verboseLog==1)
            console.log("CALVIEW",urlEncodedData);
        xhr2.send(urlEncodedData);
    }
    //6


    //4
    const doPostSedute4 = function(jsonList) {
            if( properties.verboseLog==1)
                console.log("doing sedute post 4");        

            var xhr2 = new XMLHttpRequest();                
            xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSeduteJsonConsiglio.php',true);
            xhr2.withCredentials = true;        
            xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
            xhr2.onload = function (e) {      
                if (xhr2.readyState === 4) {
                    if (xhr2.status === 200) {                              
                        if( properties.verboseLog==1)
                            console.log("OK92",xhr2);
                        var responseText = xhr2.responseText;                    
                        var obj=JSON.parse(responseText);
                        if( properties.verboseLog==1)
                            console.log("OK93",obj, obj.status);          
                        if(obj.status==="204" ){                             
                            console.log("OK CALENDARVIEWALL-4 204",xhr2);   
                            var mergedObject =undefined;                     
                            var jsonListSedute = JSON.parse(localStorage.getItem("listSeduteConsiglio"));
                            if( properties.verboseLog==1)
                                console.log("AQUI",jsonListSedute,jsonList);
                            if(jsonListSedute!=undefined && jsonListSedute!=null &&jsonListSedute!=""){                          
                                if( properties.verboseLog==1)
                                    console.log("2",jsonListSedute, "33", jsonList);
                                                                
                                var uno=Object.entries(jsonList);
                                var die=Object.entries(jsonListSedute);
                                for(var i = 0, l = die.length; i < l; i++) {                               
                                   if(die[i][1].order==0)
                                   die[i][1].nuovo ="New";   
                                }
                                for(var i = 0, l = uno.length; i < l; i++) {                                
                                    if(uno[i][1].order==0)
                                   uno[i][1].nuovo ="New";   
                                }
                                if( properties.verboseLog==1){
                                    console.log("UnoUno",uno);
                                    console.log("DueUno",die);
                                }                                
                                for(var i = 0, l = die.length; i < l; i++) {
                                    for(var j = 0, ll = uno.length; j < ll; j++) {
                                        if( properties.verboseLog==1)
                                            console.log("CHECK2", die[i] , uno[j]);
                                        if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                            if( properties.verboseLog==1)
                                                console.log("found2");
                                            die[i][1].organo="Entrambi";
                                            if(uno[j][1].nuovo=="New")
                                                die[i][1].nuovo="New";
                                        }                                   
                                    }
                                }
                                jsonListSedute=Object.fromEntries(die);
                                jsonList=Object.fromEntries(uno);
                                if( properties.verboseLog==1)
                                    console.log("PUS UNO",jsonListSedute);
                                                                                    
                                mergedObject = {
                                    ...jsonList,
                                    ...jsonListSedute
                                  };
                                if( properties.verboseLog==1)
                                    console.log("212",mergedObject);
                            }
                            else    
                                mergedObject= jsonList;
                            if( properties.verboseLog==1)
                                console.log("our1",mergedObject);
                            if(localStorage.getItem("ruolo")=="12" ||localStorage.getItem("ruolo")=="21")
                                doPostSedute6(mergedObject);
                            else
                                updateUI(mergedObject);                            
                        }              
                        else{
                            //status 201                            
                            console.log("OK CALENDARVIEWALL-4 201",xhr2);  
                            
                            try{
                                localStorage.setItem("listSeduteConsiglio", obj.content);
                                localStorage.setItem("listSeduteVersionConsiglio", obj.date);                                      
                            }
                            catch(error) {
                                console.log("LOCALSTORAGE ISSUE", error);
                            }  
                            
                            var jsonListSedute2 = JSON.parse(obj.content);
                            var mergedObject=undefined;
                            if( properties.verboseLog==1)
                                console.log("AQUI2",jsonListSedute2,jsonList);
                            if(jsonListSedute2!=undefined && jsonListSedute2!=null &&jsonListSedute2!=""){                            
                                var uno=Object.entries(jsonList);
                                var die=Object.entries(jsonListSedute2);
                                if( properties.verboseLog==1){
                                    console.log("Uno-",uno);
                                    console.log("Due-",die);
                                }
                                for(var i = 0, l = die.length; i < l; i++) {
                                    if( properties.verboseLog==1)
                                        console.log("Due-------",die[i][1]);
                                    if(die[i][1].order == 0)
                                        die[i][1].nuovo = "New";                                    
                                }
                                for(var i = 0, l = uno.length; i < l; i++) {                                
                                    if(uno[i][1].order == 0)
                                        uno[i][1].nuovo = "New";    
                                }       
                                if( properties.verboseLog==1){                     
                                    console.log("Uno",uno);
                                    console.log("Due",die);       
                                }                     
                                for(var i = 0, l = die.length; i < l; i++) {
                                    for(var j = 0, ll = uno.length; j < ll; j++) {
                                        if( properties.verboseLog==1)
                                            console.log("CHECK2", die[i] , uno[j]);
                                        if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                            if( properties.verboseLog==1)
                                                console.log("found2");
                                            die[i][1].organo="Entrambi";                                                                              
                                        }                                   
                                    }
                                }
                                jsonListSedute2=Object.fromEntries(die);                            
                                jsonList=Object.fromEntries(uno);
                                if( properties.verboseLog==1)
                                    console.log("PUS",jsonListSedute2);                                
                                mergedObject = {
                                    ...jsonList,
                                    ...jsonListSedute2
                                  }; 
                                if( properties.verboseLog==1)
                                    console.log("412",mergedObject);
                            }                                
                            else                                 
                                mergedObject=jsonList;
                            if( properties.verboseLog==1)
                                console.log("our2",mergedObject);                            
                            if(localStorage.getItem("ruolo")=="12" ||localStorage.getItem("ruolo")=="21")
                                doPostSedute6(mergedObject);
                            else    
                                updateUI(mergedObject);                            
                        }
                    } 
                    else {                        
                        var mergedObject=undefined;
                        console.log("Errore 2: Recuperando la lista sedute o non presenti sedute per il ruolo dell'utente");  
                        if(jsonList!=undefined && jsonList!=null &&jsonList!=""){                       
                            mergedObject = jsonList;
                            if( properties.verboseLog==1)
                                console.log("413",mergedObject);
                        }                        
                        else                                
                            mergedObject=null;
                        if( properties.verboseLog==1)
                            console.log("our3",mergedObject);
                        
                        if(localStorage.getItem("ruolo")=="12" ||localStorage.getItem("ruolo")=="21")
                            doPostSedute6(mergedObject);
                        else
                            updateUI(mergedObject);
                    }
                }
            };

            xhr2.onerror = function (e) {
                console.error("Error 22", xhr2.statusText);
            };
            
            let urlEncodedData = null; //"idf=" + meetingId;
            if (localStorage.listSeduteConsiglio!=='' &&localStorage.listSeduteConsiglio!==undefined){            
                if( properties.verboseLog==1)
                    console.log( "QUINDI00");
                const currentData=localStorage.getItem("listSeduteVersionConsiglio");        
                urlEncodedData = "date=" + currentData;                    
            }       
            if( properties.verboseLog==1)
                console.log("CALVIEW",urlEncodedData);
            xhr2.send(urlEncodedData);
    }
    //4

    const doPostSedute2 = function() {
        if( properties.verboseLog==1)
            console.log("doing sedute post LV");
        var xhr2 = new XMLHttpRequest();                        
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSeduteJson.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    if( properties.verboseLog==1)
                        console.log("OK DOPOSTSEDUTE",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                        console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        console.log("OK CALENDARVIEWLL-2 204",xhr2);
                        const jsonListSedute = JSON.parse(localStorage.getItem("listSedute"));                        
                        doPostSedute4(jsonListSedute);
                    }              
                    else{
                        //status 201
                        console.log("OK CALENDARVIEWALL-2 201",xhr2);                            
                        try{
                            localStorage.setItem("listSedute", obj.content);
                            localStorage.setItem("listSeduteVersion", obj.date);                                                                                                                                           
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        const jsonListSedute = JSON.parse(obj.content);                        
                        doPostSedute4(jsonListSedute);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    alert("Errore recuperando la seduta");  
                    doPostSedute4(null);
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSedute!=='' &&localStorage.listSedute!==undefined){            
            if( properties.verboseLog==1)
                console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersion");        
            urlEncodedData = "date=" + currentData;                    
        }            
        xhr2.send(urlEncodedData);
    }
 
    useEffect(() => {     
        if( properties.verboseLog==1){
            console.log("USEEFFECT CV");    
            console.log("Arrivo qui", document.referrer);  
        }
        setAnchorEl(null);
        if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null || localStorage.getItem("new_ui")=== "false"){                    
            navigate("/fascicoli",{ replace: true });  
        }              

        if (!window.cordova)
        {
          if(localStorage.getItem("ruolo")==="1"||localStorage.getItem("ruolo")==="2"){
              setBothRoles(true);
          }     
          if(localStorage.getItem("ruolo")==="12"||localStorage.getItem("ruolo")==="21"){
            setThreeRoles(true);
            }     
        }
        else {
          var onSuccess = function(response)
          {
            if( properties.verboseLog==1)
                console.log("OK LOGIN 33");
            var obj=JSON.parse(response);
            var ruolo = obj.Ruolo;
        
            if(ruolo=="1"||ruolo=="2"){
                setBothRoles(true);
            }
            if(ruolo =="12" ||ruolo =="21" ){
                setThreeRoles(true);
            }
          }
        
          var onError = function() {}
        
          window.getInfo3(onSuccess, onError);
        }             

                           
        if(!window.cordova){        
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                            
            xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
            xhr.withCredentials = true;
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                         
                        if( properties.verboseLog==1)
                            console.log("OK",xhr);   
                    updateSedute("Comitato",doPostSedute2, updateUI);                               
                } else {
                    console.error("Error 1", xhr);   
                    localStorage.clear();                                     
                    navigate("/login");  
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();                
                navigate("/login");  
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
           updateSedute("Comitato",doPostSedute2, updateUI);   
        }
       
    }, []);

    function printDate(dateString) {
        var dateLabel=dateString.substring(0, 2).concat("/").concat(dateString.substring(2, 4)).concat("/").concat(dateString.substring(4, 8));
        return dateLabel;
}


    function formatDate(dateString) {
        const [year, month, day] = dateString.split("-");
        return `${day}${month}${year}`;
}

const handleEventClickToday = (e,dateOk) => {    
    if( properties.verboseLog==1)
        console.log("HANDLING", e,selectedDate );
    if(e.organo=="Comitato di Gestione"){
        setAnchorEl(null);
        navigate("/fascicoli/"+ dateOk+"/summary" );
    }
    if(e.organo=="Consiglio Generale"){
        setAnchorEl(null);
        navigate("/consiglio/"+ dateOk );
    }
    if(e.organo=="Collegio Sindacale"){
        setAnchorEl(null);
        navigate("/collegio/"+ dateOk );
    }    
}

function containsColor( val, rgbColor){
    const dataDateAttr = val.attributes['data-date'];
    if (dataDateAttr && dataDateAttr.ownerElement) {
      const ownerElement = dataDateAttr.ownerElement;      
      const allDescendants = ownerElement.querySelectorAll('*');      
      const hasTargetBackground = Array.from(allDescendants).some(el => {
        const style = window.getComputedStyle(el);
        //console.log(style.background);
        return style.backgroundColor === rgbColor;
        });

      if (hasTargetBackground) {
        console.log('Trovato un elemento con background-color di interesse');
        return true;
        
      } else {
        console.log('Nessun elemento con background-color di interesse');
      }
    } else {
      console.log('Attributo data-date non trovato.');
    }
    return false;
  } 

  function containsMultiColor( val, rgbColor){
    const dataDateAttr = val.attributes['data-date'];
    if (dataDateAttr && dataDateAttr.ownerElement) {
      const ownerElement = dataDateAttr.ownerElement;      
      const allDescendants = ownerElement.querySelectorAll('*');      
      const hasTargetBackground = Array.from(allDescendants).some(el => {
        const style = window.getComputedStyle(el);        
        return style.background === rgbColor;
        });

      if (hasTargetBackground) {
        console.log('Trovato un elemento con background-color di interesse');
        return true;
        
      } else {
        console.log('Nessun elemento con background-color di interesse');
      }
    } else {
      console.log('Attributo data-date non trovato.');
    }
    return false;
  }



   const topicList = (e) => {    
    const clickedCell = e.jsEvent.target;
    if( properties.verboseLog==1)
        console.log("called topic list CV",e);
    const dateOk=formatDate(e.dateStr);    
    const cellBackgroundColor = window.getComputedStyle(clickedCell).backgroundColor;
    //console.log("TL-backgroundColor", cellBackgroundColor);
    const cellBackground = window.getComputedStyle(clickedCell).background;
    //console.log("TL-background", cellBackground);
    var eventi= [];
    //if(cellBackground == "rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(191, 54, 12) 50%, rgb(48, 114, 15) 50%) repeat scroll 0% 0% / auto padding-box border-box"){
    if (containsMultiColor(e.dayEl,"rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(191, 54, 12) 50%, rgb(48, 114, 15) 50%) repeat scroll 0% 0% / auto padding-box border-box")){     
      if( properties.verboseLog==1)
            console.log("Cliccato un giorno con eventi comitato + consiglio."); 
      var evento = {        
        organo: "Comitato di Gestione",        
        color: '#30720f'                
      };         
      var evento2 = {        
        organo: "Consiglio Generale",        
        color: '#BF360C'                
      };         

      //info.el.style.background= "conic-gradient(# 0deg 120deg, #30720f 120deg 240deg, #666999 240deg 360deg)";
      //eventi.push("Comitato di Gestione");
      //eventi.push("Consiglio Generale");
      eventi.push(evento);
      eventi.push(evento2);
      setSelectedDate(dateOk);
      setEventsForDay(eventi);
      setAnchorEl(e.jsEvent.target); 
      //setOpen(true);

    } 
    //else if (cellBackground == "rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(102, 105, 153) 50%, rgb(48, 114, 15) 50%) repeat scroll 0% 0% / auto padding-box border-box"){
    else if (containsMultiColor(e.dayEl,"rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(102, 105, 153) 50%, rgb(48, 114, 15) 50%) repeat scroll 0% 0% / auto padding-box border-box")){ 
        if( properties.verboseLog==1)
            console.log("Cliccato un giorno con eventi collegio + comitato.");  
        var evento = {        
            organo: "Comitato di Gestione",        
            color: '#30720f'                
          };         
          var evento2 = {        
            organo: "Collegio Sindacale",        
            color: '#666999'                
          };         
    
          //info.el.style.background= "conic-gradient(# 0deg 120deg, #30720f 120deg 240deg, #666999 240deg 360deg)";          
          eventi.push(evento);
          eventi.push(evento2);
          setSelectedDate(dateOk);
          setEventsForDay(eventi);
          setAnchorEl(e.jsEvent.target); 
          //setOpen(true);
    }
    else if (containsMultiColor(e.dayEl,"rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(102, 105, 153) 50%, rgb(191, 54, 12) 50%) repeat scroll 0% 0% / auto padding-box border-box")){ 
    //else if (cellBackground == "rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(102, 105, 153) 50%, rgb(191, 54, 12) 50%) repeat scroll 0% 0% / auto padding-box border-box"){
        if( properties.verboseLog==1)
            console.log("Cliccato un giorno con eventi collegio + consiglio.");  
        var evento = {        
            organo: "Consiglio Generale",        
            color: '#BF360C'                
          };         
          var evento2 = {        
            organo: "Collegio Sindacale",        
            color: '#666999'                
          };         
    
          //info.el.style.background= "conic-gradient(# 0deg 120deg, #30720f 120deg 240deg, #666999 240deg 360deg)";          
          eventi.push(evento);
          eventi.push(evento2);
          setSelectedDate(dateOk);
          setEventsForDay(eventi);
          setAnchorEl(e.jsEvent.target); 
          //setOpen(true);
    } 
    else if (containsMultiColor(e.dayEl,"rgba(0, 0, 0, 0) conic-gradient(rgb(191, 54, 12) 0deg, rgb(191, 54, 12) 120deg, rgb(48, 114, 15) 120deg, rgb(48, 114, 15) 240deg, rgb(102, 105, 153) 240deg, rgb(102, 105, 153) 360deg) repeat scroll 0% 0% / auto padding-box border-box")){
    //else if (cellBackground == "rgba(0, 0, 0, 0) conic-gradient(rgb(191, 54, 12) 0deg, rgb(191, 54, 12) 120deg, rgb(48, 114, 15) 120deg, rgb(48, 114, 15) 240deg, rgb(102, 105, 153) 240deg, rgb(102, 105, 153) 360deg) repeat scroll 0% 0% / auto padding-box border-box"){
        if( properties.verboseLog==1)
            console.log("Cliccato un giorno con tutti gli eventi comitato + collegio + consiglio.");  
        var evento = {        
            organo: "Comitato di Gestione",        
            color: '#30720f'        
        };         
        var evento2 = {        
            organo: "Consiglio Generale",        
            color: '#BF360C'                
        };         
        var evento3 = {        
            organo: "Collegio Sindacale",        
            color: '#666999'                
          };         
          //info.el.style.background= "conic-gradient(# 0deg 120deg, #30720f 120deg 240deg, #666999 240deg 360deg)";          
          eventi.push(evento);
          eventi.push(evento2);
          eventi.push(evento3);
          setSelectedDate(dateOk);
          setEventsForDay(eventi);
          setAnchorEl(e.jsEvent.target); 
          //setOpen(true);
    } 
    //else if (cellBackgroundColor === "rgb(102, 105, 153)") { // Collegio Sindacale        
    else if (containsColor(e.dayEl,"rgb(102, 105, 153)")) { // Collegio Sindacale  
        if( properties.verboseLog==1)
            console.log("Cliccato un giorno con eventi collegio.");
      navigate("/collegio/"+ dateOk );
    } 
    //else if (cellBackgroundColor === "rgb(191, 54, 12)") { // Consiglio Generale
    else if (containsColor(e.dayEl,"rgb(191, 54, 12)")) { // Consiglio Generale
        navigate("/consiglio/"+ dateOk );
      if( properties.verboseLog==1)
        console.log("Cliccato un giorno con eventi consiglio generale");
    } 
    //else if (cellBackgroundColor === "rgb(48, 114, 15)") { // Comitato di Gestione
    else if (containsColor(e.dayEl,"rgb(48, 114, 15)")) { // Comitato di Gestione
        if( properties.verboseLog==1)
            console.log("Cliccato un giorno con eventi comitato di gestione");
        navigate("/fascicoli/"+ dateOk+"/summary" );
    }else {
        console.log("Nessuna logica specifica per questo colore.");
    }
    
};


 
  return (
    <div style={{marginTop:"10px",paddingRight:"20px",paddingLeft:"20px", height:"100%"}}>
           
    <FullCalendar      
            ref={myRef}
            plugins={[ multiMonthPlugin,interactionPlugin ]}
            initialView="multiMonthYear"
            locale={itLocale}
            height="auto"            
            multiMonthMaxColumns= {4}            
            eventDisplay={'background'}
            contentHeight="auto"
     
            aspectRatio={2.5} 
         
            eventDidMount={(info) => {    
                const eventType = info.event.extendedProps.type;          
                if(eventType=="Tetrambi") {
                    console.log("tetrambi",info);                
                    info.el.style.background= "conic-gradient(#BF360C 0deg 120deg, #30720f 120deg 240deg, #666999 240deg 360deg)";                
                    info.el.style.opacity = "0.8"; 
                }              
                else if(eventType=="Entrambi"){
                    info.el.style.background = "linear-gradient(135deg, #BF360C 50%, #30720F 50%)";
                    info.el.style.opacity = "0.8"; 
                }
                else if(eventType=="ConsColl"){                    
                    info.el.style.background = "linear-gradient(135deg, #666999 50%, #BF360C 50%)";                    
                    info.el.style.opacity = "0.8"; 
                }
                else if(eventType=="ComiColl"){                    
                    info.el.style.background = "linear-gradient(135deg, #666999 50%, #30720F 50%)";                    
                    info.el.style.opacity = "0.8"; 
                }                
                else{
                info.el.style.backgroundColor = info.event.extendedProps.color;
                info.el.style.borderColor = info.event.extendedProps.color;
                info.el.style.opacity = "0.9"; 
                }
             }}            
            headerToolbar={{
                right: 'prev,next today',
                center: 'title',
                left: '',
            }}                       
            dateClick={e => topicList(e)}          
            events={meetingData}   
          
            />        
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: "16px", maxWidth: "300px" }}>
          <h3>Sedute del giorno:  {printDate(selectedDate)}</h3>
          {eventsForDay.length > 0 ? (
            <List>
              {eventsForDay.map((event, index) => (
                   <ListItem button key={event}  onClick={() => handleEventClickToday(event,selectedDate)} >
                      <div
              style={{
                width: "25px",
                //height: "16px",
                height: "25px",
                backgroundColor: event.color || "gray", // Usa il colore dell'evento o un default
                marginRight: "8px",
                borderRadius: "4px", // Opzionale, per un effetto più moderno
              }}
            ></div>
                   <ListItemText
                     primary={event.organo}
                     //secondary={` ${event.organo}`}
                   />
                 </ListItem>
              ))}
            </List>
          ) : (
            <p>Nessun evento per questa data.</p>
          )}
        </div>
      </Popover>
      </div>
  );
  
  }  