import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";
import {properties} from "../../utils/properties";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function TopicList3() {    
    let { idDossier, odgkey } = useParams();
    const [topicList, setTopicList] = useState([]);    
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    const navigate = useNavigate();    
    const meetingId = idDossier ;
    if( properties.verboseLog==1)
        console.log("id", idDossier);            
    if( properties.verboseLog==1)
        console.log("id1", odgkey);

    const updateUI = function(meetingData) {            
        const keyTopicList = Object.entries(meetingData)[6][1];        
        const valueTopicList = Object.values(keyTopicList);            
        const temp1= Object.entries(keyTopicList);
        var temp2 = [];
        temp1.forEach( entry => {
                let key= entry[0];
                let value= entry[1];                                
                if(value.odgkey===odgkey){                
                    temp2.push(entry);
                }
                      
        }); 
        const meetingId = idDossier ;
        if( properties.verboseLog==1)
            console.log("qq47",temp2);        
        setTopicList(temp2);                  
    }



    const doPostSeduta2 = function() {
        if( properties.verboseLog==1)
            console.log("doing sedute post");
        var xhr2 = new XMLHttpRequest();                    
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSeduta.php',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.withCredentials = true;    
        xhr2.onload = function (e) {      
        if (xhr2.readyState === 4) {
            if (xhr2.status === 200) {                              
                if( properties.verboseLog==1)
                    console.log("OK92",xhr2);
                var responseText = xhr2.responseText;                    
                var obj=JSON.parse(responseText);
                if( properties.verboseLog==1)
                    console.log("OK93",obj);          
                if(obj.status==="204" ){ 
                    console.log("OK TOPICLIST3 204",xhr2);    
                    const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));                        
                    updateUI(MeetingInfo);
                }              
                else{
                    //status 201                    
                    console.log("OK TOPICLIST3 201",xhr2);    
                    try{
                        localStorage.setItem("meetingContent", obj.content);
                        localStorage.setItem("meeting", meetingId);
                        localStorage.setItem("meetingVersion", obj.date);                              
                    }
                    catch(error) {
                        console.log("LOCALSTORAGE ISSUE", error);
                    }  
                    const MeetingInfo = JSON.parse(obj.content);
                    updateUI(MeetingInfo);
                }
            } 
            else {
            console.error("Error 1", xhr2.statusText);          
            alert("Errore recuperando la seduta");  
            }
        }
        };

        xhr2.onerror = function (e) {
        console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = "idf=" + meetingId;
        if (localStorage.meeting===meetingId){            
            if( properties.verboseLog==1)
                console.log( "QUINDICI00");
            const currentData=localStorage.getItem("meetingVersion");        
            urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
            }    
        xhr2.send(urlEncodedData);
    }

    useEffect(() => {
    if( !window.cordova){            
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                
        
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                    
                    if( properties.verboseLog==1)
                        console.log("OK",xhr);
                    updateSeduta(meetingId,doPostSeduta2,updateUI);                    
                } else {
                    console.error("Error 1", xhr);      
                    localStorage.clear();              
                    navigate("/login");                  
                }
            }
        };
    
        xhr.onerror = function (e) {
            console.error("Error 2", xhr);
            localStorage.clear();          
            navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
    
        if (window.cordova){
            updateSeduta(meetingId,doPostSeduta2,updateUI);
        }
    
    }, []);

    const topicArgument = (index, ref) => {
        if( properties.verboseLog==1){
            console.log("qq33", ref);
            console.log("qq40",index);
            console.log("qq47",topicList);
        }
        var cntCard = topicList[index][1]["cardIds"].length;

        if (cntCard) {
            var cardType = topicList[index][1]["cardIds"][0].item_id;
            if (cardType === "../../proposteintervento/") {  
                navigate("/fascicoli/" + meetingId + "/"+ ref);  
            } else {                
                navigate("/fascicoli/" + meetingId + "/"+ ref);  
            }
        } else {            
            alert("Non sono stati caricati approfondimenti per l'argomento selezionato.");
        }
    };

    return (
        <div className="content">        
            {topicList.map((item, index) => (                
                <div
                    className="common-list"
                    key={index}                    
                    onClick={() => topicArgument(index, item[0])}
                    style={{                    
                        cursor: "pointer",
                        paddingLeft: "40px",
                    }}
                >
                    <p className= "textBlackSedute">
                        {item[1].label}
                    </p>
                    <div
                        style={{
                            color: "#879497",
                            display: "flex",
                            alignItems: "center",                                            
                        }}
                    >
                        <div
                            style={{
                                paddingRight: "25px",
                                color: "#879497",
                                display: "flex",
                            }}
                        >
                            <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>
                        </div>
                        <div
                            style={{
                                marginLeft: "10px",
                                width: "10px",
                                height: "63px",
                                //background: item.color,
                                background: item[1].color,
                                borderBottomRightRadius: "8px",
                                borderTopRightRadius: "8px",
                            }}
                        ></div>
                    </div>
                </div>
            ))}
        </div>
    );
}