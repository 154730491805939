import React, { Component } from "react";

import "../../App.css";
import axios from 'axios';
import {properties} from "../../utils/properties";

import { FormControlLabel, Checkbox } from '@mui/material';
import {ThreeDots} from 'react-loader-spinner';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";


export default function PreviewScheda() {     
     const navigate = useNavigate();
     const [loading, setLoading]= React.useState(false);    
     const [forcing, setForcing]= React.useState(false);    
     

     function handleChange3(event) {            
      setForcing(event.target.checked);
      if( properties.verboseLog==1)
        console.log("HC3",forcing);      
    }
  
    const forceUpdate= function(idScheda){       
        if (!loading) { setLoading(true);} 
        
        axios.get(properties.productionUrl+'/postIDSF?IDS='+idScheda).then(response => {                        
          if( properties.verboseLog==1)
            console.log("THEN",response);
        
        }).catch(({response}) => {                      
            console.log("CATCH",response);
            
        }).finally(() => {
          if( properties.verboseLog==1)
              console.log("FINALLY",);   
          navigate("/schede/" + idScheda);                                                
        });
        
    
    }

     const doPost2 = function(idScheda)  {    
        var xhr2 = new XMLHttpRequest();                        
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkScheda.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {
                    if( properties.verboseLog==1)                              
                      console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                      console.log("OK93",obj);          
                    if(obj.status==="204" ){                
                         console.log("OK PREVIEWSCHEDA 204",obj.date);
                         navigate("/schede/" + idScheda);      
                    }              
                    else{
                        //status 201
                        console.log("OK PREVIEWSCHEDA 201",obj.date);                                                  
                        try{
                          localStorage.setItem("schedaContent", obj.content);
                          localStorage.setItem("scheda", idScheda);
                          localStorage.setItem("schedaVersion", obj.date);                                                                                                                                      
                        }
                        catch(error) {
                          console.log("LOCALSTORAGE ISSUE", error);
                        }                                                               
                         navigate("/schede/" + idScheda);      
                    }
                } 
                else {                          
                    alert("Errore recuperando la scheda");  
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
    
        let urlEncodedData = "ids=" + idScheda;
        if (localStorage.scheda===idScheda){     
            if( properties.verboseLog==1)       
              console.log( "QUINDIC=00");
            const currentData=localStorage.getItem("schedaVersion");        
            urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
        xhr2.send(urlEncodedData);
    }




  const handleSubmit = e => {
    e.preventDefault();
    if (!e.target.idScheda.value) {
      alert("E' necessario inserire un ID scheda");
    }  
    else {
      if(forcing){
        if( properties.verboseLog==1)
          console.log("True Value Checkbox", forcing);
        forceUpdate(e.target.idScheda.value);  
      }
      else{
        if( properties.verboseLog==1)
          console.log("False Value Checkbox", forcing);
        doPost2(e.target.idScheda.value);
      }
    } 
  };

  
  
return (   
  <div> 
  {loading ? (
      <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
      marginLeft: "auto", marginRight: "auto" }}> 
       <ThreeDots color="#30720f" height='350' width='150'/>
       </div> 
  ):(
      <div className="content" style = {{alignItems: "center", display: "flex", height: "100%",  justifyContent: "center"}}>

      <div className="AppLogin">
      
        <h2 style={{color: "#30720f"}}> Anteprima Scheda </h2>
        <form className="form1" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="idScheda">Inserire l'ID della scheda di cui si vuole visualizzare l'anteprima</label>
            <input type="username" name="idScheda" placeholder="ID scheda" />  <br />
            <FormControlLabel key={uuidv4()} style={{paddingLeft:"20px", color:"black"}}
                                            control={<Checkbox key={uuidv4()} style ={{color: "#30720f"}} checked={forcing} 
                                            onChange={(event) => handleChange3(event)} />} label="Forza aggiornamento dati della scheda " />                                    
          </div>
          
          <button className="button11">Anteprima</button>
        </form>
        
      </div>
      </div>
    )}</div>     
    );
  }