import React, { useEffect } from "react";

import { v4 as uuidv4 } from 'uuid';

import {ThreeDots} from 'react-loader-spinner';
import axios from 'axios';
import "../../App.css";
import {properties} from "../../utils/properties";
import { useNavigate } from "react-router-dom";


export default function InitIndice() {
  
  const navigate = useNavigate();
  const [loading, setLoading]= React.useState(true); 
  

useEffect(() => {
  if( properties.verboseLog==1)
    console.log("UE DI INIT");  
  if(!window.cordova){
      if( properties.verboseLog==1)
         console.log("UE DI INIT 2");   
      //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                
        //xhr.open('POST', properties.productionUrl+'/rest/ping99',true);
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
        xhr.withCredentials = true;        
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                                        
                    if( properties.verboseLog==1)
                      console.log("OK",xhr);
                    //IF PREVIEWERS                    
                    var error=false;
                    var lastId=undefined;
                    var newUI=false;
                    var isPreviewer=false;
                    if( properties.verboseLog==1)                                                        
                      console.log("After");
                    if(localStorage.getItem("ruolo")=='15'|| localStorage.getItem("ruolo")=='12'||localStorage.getItem("ruolo")=='10'||
                        localStorage.getItem("ruolo")=='5'||localStorage.getItem("ruolo")=='4'||localStorage.getItem("ruolo")=='1'){
                        isPreviewer=true;      
                    }                    
                    if( properties.verboseLog==1)
                      console.log("Info:"+ newUI+ " "+isPreviewer);    
                    if(isPreviewer){                      
                        if( properties.verboseLog==1)
                          console.log("Info2: Inside isPReviewer");                                                                                                          
                        navigate("/indice/calendar",{ replace: true });                                                                                                                                        
                    }
                    //FINE IF PREVIEWERS
                    else{                      
                        navigate("/indice/calendar",{ replace: true });                        
                    }                         
            } else {
                console.error("Error 321", xhr); 
                localStorage.clear();                               
                navigate("/login");
            }
        }
        };

        xhr.onerror = function (e) {
            console.error("Error 322", xhr);
            localStorage.clear();       
            navigate("/login");
        };
         
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
    //CHECK AUTHENTICATION
    }        
},
   []);


  return (
    <div key={uuidv4()}> 
    
    {loading ? (
      <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
      marginLeft: "auto", marginRight: "auto" }}> 
       <ThreeDots color="#30720f" height='350' width='150'/>
       </div> 
      ):(
        <></>)
    }         
    </div>
  );
  
  }  



