import React, { useState, useEffect } from 'react';

import {
  Typography, List, ListItem, ListItemIcon,
  ListItemText, TextField, Collapse, Menu, MenuItem
} from '@mui/material';

import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';

import { useNavigate } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from 'axios';
import { AiFillFilePpt } from "react-icons/ai";
import { AiOutlineFilePdf } from "react-icons/ai";
import { AiOutlineFileImage } from "react-icons/ai";
import { AiOutlineFile } from "react-icons/ai";
import { AiOutlineFileWord } from "react-icons/ai";
import { AiOutlineFileExcel } from "react-icons/ai";

import { AiFillFolderOpen } from "react-icons/ai";
import { AiFillFolder } from "react-icons/ai";
import {properties} from "../../utils/properties";
import {ThreeDots} from 'react-loader-spinner';


const FileExplorer = () => {
  const [fileTree, setFileTree] = useState({ folders: {}, files: [] });  
  const [currentDirectory, setCurrentDirectory] = useState('/');    
  const [openFolders, setOpenFolders] = useState({});    
  const [isEditing, setIsEditing] = useState(null);   
  const [loading, setLoading]= useState(true);  
  const navigate = useNavigate();

  useEffect(() => {
    if( properties.verboseLog==1)
      console.log("USE EFFECT!");
    if(!window.cordova){            
      //CHECK AUTHENTICATION
      var xhr = new XMLHttpRequest();                
      
      xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
      xhr.withCredentials = true;
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      xhr.onload = function (e) {
          if (xhr.readyState === 4) {
              if (xhr.status === 200) {                                      
                
          } else {
              console.error("Error 1", xhr);     
              localStorage.clear();               
              if( properties.verboseLog==1)
                console.log("QWERTY 1",localStorage.getItem("name"));              
              navigate("/login");    
          }
      }
      };

      xhr.onerror = function (e) {
          console.error("Error 2", xhr);                                          
          localStorage.clear();       
          if( properties.verboseLog==1)
            console.log("QWERTY 2",localStorage.getItem("name"));          
          navigate("/login");    
      };
       
      let urlEncodedData = null; 
      xhr.send(urlEncodedData);
      //CHECK AUTHENTICATION
      }
    fetchFiles();
  }, [currentDirectory]);

  const fetchFiles = async () => {
    if( properties.verboseLog==1)
      console.log("FECTCH FILES");
    const formData = new FormData();              
    formData.append('action', "list");
    if (!window.cordova){
        try {            
          const endpoint = properties.productionUrl+properties.productionUrlSuffix +"/core/rest/fmFunctions.php"; 
          const response = await axios.post(endpoint,formData, { withCredentials: true });
          const data = response.data;          
          if (data && typeof data === 'object') {
            if( properties.verboseLog==1)
              console.log("DATA",data);
            setFileTree(data);
            const initialOpenFolders = {};            
            const setInitialFolders = (node) => {
              Object.entries(node.folders).forEach(([folder,value]) => {
                if( properties.verboseLog==1)
                  console.log("II",folder,value, value.fullPath);
                initialOpenFolders[value.fullPath] = true; 
                setInitialFolders(node.folders[folder]); 
              });
            };
            setInitialFolders(data);
            setOpenFolders(initialOpenFolders);
            setLoading(false);
          } else {
            console.error("Invalid data format");
          }
        } catch (error) {
          console.error("Error fetching files", error);
        }
    }
    else{
      if( properties.verboseLog==1)
        console.log("CORDOVA");
      const bodyasString = new URLSearchParams(formData).toString();
      if( properties.verboseLog==1)
        console.log("SETTINGS",bodyasString);

      var onSuccess = function(response){      
        if( properties.verboseLog==1)
          console.log("on success",response);
        var obj=JSON.parse(response);
                   
        if( properties.verboseLog==1)
          console.log("on success2",obj);             
        const data = obj;   
        if( properties.verboseLog==1)
          console.log("on success3",data);
        if (data && typeof data === 'object') {
          if( properties.verboseLog==1){
            console.log("on success3","into");
            console.log("DATA",data);
          }
          setFileTree(data);
          const initialOpenFolders = {};          
          const setInitialFolders = (node) => {
            Object.entries(node.folders).forEach(([folder,value]) => {
              if( properties.verboseLog==1)
                console.log("II",folder,value, value.fullPath);
              initialOpenFolders[value.fullPath] = true; 
              setInitialFolders(node.folders[folder]); 
            });
          };
          setInitialFolders(data);
          setOpenFolders(initialOpenFolders);
          setLoading(false);  
      }
      else{
          console.error("Invalid data format");      
      }
    }
      var onError = function() {
          console.log("on error");
          setLoading(false);  
      }      
      
      window.getRepo(bodyasString,onSuccess, onError);
    }
  
    
  };

 


  const findFilePath = (tree, targetName, currentPath = []) => {
    
    if (tree.files && tree.files.some(file => file.name === targetName)) {
      return [...currentPath]; 
    }
  
    if (tree.folders && tree.folders.hasOwnProperty(targetName)) {
        return [...currentPath, targetName]; 
    }
    
    if (tree.folders) {
      for (let folderName in tree.folders) {
        const result = findFilePath(tree.folders[folderName], targetName, [...currentPath, folderName]);
        if (result) {
          return result;
        }
      }
    }
  
    return null;
  };


  const handleFolderClick = (folderName, fullPath, id) => {
    if( properties.verboseLog==1)
        console.log("HANDLEFOLDERCLiK",folderName, fullPath, id, isEditing,openFolders);
    if (isEditing && isEditing.id === id &&isEditing.fullPath===fullPath) return;
    setOpenFolders((prevOpenFolders) => ({
      ...prevOpenFolders,
      [fullPath]: !prevOpenFolders[fullPath], 
    }));
  };

  const handleFileClick = (fullPath, id) => {
    if( properties.verboseLog==1)
      console.log("HANDLEFileCLiK", fullPath, id);
    window.open(properties.productionUrl+"/fileManager/files/fm24"+fullPath);    
  };

  function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    if(hour<10)
      hour="0"+hour;
    var min = a.getMinutes();
    if(min<10)
      min="0"+min;
    var sec = a.getSeconds();
    if(sec<10)
      sec="0"+sec;
    var time = date + ' ' + month + ' ' + year + ' ,' + hour + ':' + min + ':' + sec ;
    return time;
  }

  const renderFileTree = (node, parentPath = '', level = 0) => {
    if( properties.verboseLog==1)
      console.log("RTRE",isEditing,node);
    return (
      <>
        {node.files.map(file => (
           
          <ListItem
            
            key={file.name}                                          
            onClick={() => (isEditing?.id === file.id && isEditing?.fullPath===file.fullPath)? undefined : handleFileClick(file.fullPath,file.id)}
            style={{ paddingLeft: `${level * 30}px`, cursor: 'pointer' }}  // Indentation
          >
            <ListItemIcon>
              {file.fullPath.endsWith(".pdf")?(
                <AiOutlineFilePdf size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".jpg") ||file.fullPath.endsWith(".jpeg")||file.fullPath.endsWith(".png")||file.fullPath.endsWith(".gif")?(                
                <AiOutlineFileImage size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".ppt") ||file.fullPath.endsWith(".pptx")?(                                
                <AiFillFilePpt size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".doc") ||file.fullPath.endsWith(".docx")?(                                                
                <AiOutlineFileWord size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".xls") ||file.fullPath.endsWith(".xlsx")?(                                
                <AiOutlineFileExcel size='2.2em' color='#4A4C4D'/>
              )
              :(                
                <AiOutlineFile size='2.2em' color='#4A4C4D'/>
              )}              
            </ListItemIcon>                        
            <ListItemText primary={file.name} secondary={timeConverter(file.id)}></ListItemText>             
          </ListItem>
        ))}

        {Object.keys(node.folders).map(key => {
          const folder = node.folders[key];
          if( properties.verboseLog==1)
            console.log("RFT23",node,key, folder,folder.fullPath, );
          return (
            <React.Fragment key={key}>
              <ListItem                          
                onClick={() => handleFolderClick(key, folder.fullPath,folder.id)}            
                style={{ paddingLeft: `${level * 30}px` }}
              >
                <ListItemIcon >
                {(Object.keys(folder.folders).length >0 ||Object.keys(folder.files).length >0) && folder.fullPath.startsWith("/Segretario Generale") ? (                          
                    <AiFillFolderOpen size='2.2em' color='#000000'/>
                  ):
                  (Object.keys(folder.folders).length >0 ||Object.keys(folder.files).length >0) && folder.fullPath.startsWith("/Materiale Istituzionale") ? (
                    <AiFillFolderOpen size='2.2em' color='#BDBDBD'/>
                  ):
                  (Object.keys(folder.folders).length >0 ||Object.keys(folder.files).length >0) && folder.fullPath.startsWith("/Comitato di Gestione") ? (
                    <AiFillFolderOpen size='2.2em' color='#30720f'/>
                  ):
                  (Object.keys(folder.folders).length >0 ||Object.keys(folder.files).length >0) && folder.fullPath.startsWith("/Consiglio Generale") ? (
                    <AiFillFolderOpen size='2.2em' color='#BF360C'/>
                  ):                  
                  (Object.keys(folder.folders).length ==0 && Object.keys(folder.files).length ==0) && folder.fullPath.startsWith("/Segretario Generale") ? (                          
                    <AiFillFolder size='2.2em' color='#000000'/>
                  ):
                  (Object.keys(folder.folders).length ==0 && Object.keys(folder.files).length ==0) && folder.fullPath.startsWith("/Materiale Istituzionale") ? (
                    <AiFillFolder size='2.2em' color='#BDBDBD'/>
                  ):
                  (Object.keys(folder.folders).length ==0 && Object.keys(folder.files).length ==0) && folder.fullPath.startsWith("/Comitato di Gestione") ? (
                    <AiFillFolder size='2.2em' color='#30720f'/>
                  ):
                  (Object.keys(folder.folders).length ==0 && Object.keys(folder.files).length ==0) && folder.fullPath.startsWith("/Consiglio Generale") ? (
                    <AiFillFolder size='2.2em' color='#BF360C'/>
                  ):
                  (
                    <AiFillFolder size='2.2em' color='#30720f'/>
                  )}
                </ListItemIcon>             
                <ListItemText primary={key}  />                             
                {openFolders[folder.fullPath] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openFolders[folder.fullPath]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {renderFileTree(folder, `${parentPath}/${key}`, level + 1)}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <div className="content">
 {loading ? (
    <div style={{ width:"25%",   display: "flex",
        marginLeft: "auto", marginRight: "auto" }}>
         <ThreeDots color="#30720f" height='350' width='150'/>
    </div>
    ):(
      <div style={{ backgroundColor:"white", padding: '20px', paddingLeft:"30px",paddingRight:"30px", borderRadius:4,
      border: "1px solid #C5C5C5"}}>       
     <Tooltip title="Aggiorna" placement="left">
            <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {fetchFiles() }}                
                style={{
                    margin: 0,
                    background: "#30720f",
                    top: 'auto',
                    right: 5,
                    bottom: 25,
                    //color: '0xFF0000',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <RefreshIcon fontSize='large'/>                 
                </Fab>          
            </Tooltip> 
      <List >
        {renderFileTree(fileTree)}
      </List>

     
    </div>)}
    </div>
  );
};

export default FileExplorer;
