import React, { useState, useCallback, useRef,useEffect } from "react";
import { useDropzone } from 'react-dropzone'
import { useDragOver } from "@minoru/react-dnd-treeview";
import * as CryptoJS from 'crypto-js'
import {properties} from "../../utils/properties";
import { ArrowRight } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import TypeIcon from "./TypeIcon";
import "./customNodeStyles.css"

const FileNode = (props) => {
  const fileInputRef = useRef(null);
  const {
    handleDeleteFile,
    handleAddFile,
    handleRenameFile,
    showProgress,
    handleMove
  } = props
  const { id, droppable, data } = props.node;
  const indent = props.depth * 24;
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleInput, setVisibleInput] = useState(false)
  const [labelText, setLabelText] = useState(props.node.text)

  const handleToggle = (e) => {
    if( properties.verboseLog==1)
      console.log("FN HTOGGLE", e);
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  const handleClick = (event) => {
    if( properties.verboseLog==1)
      console.log("FN HCLICK", event.currentTarget);
    event.preventDefault();
    if (props.node.data.isFile) {    
        setAnchorEl(event.currentTarget);    
    }
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUpdateText = (e) => {
    if (e.key === "Enter") {
      setVisibleInput(false)
      handleRenameFile(id, labelText)
    }
  }

  const open = Boolean(anchorEl);

  
  const handleFileChange = (event) => {
    const acceptedFiles = event.target.files[0];
    if( properties.verboseLog==1)
      console.log("FN ON DROP", acceptedFiles);
    if(acceptedFiles!=undefined) {   
        const fileName = acceptedFiles.name
        const fileType = acceptedFiles.type
        const reader = new FileReader()
        reader.readAsArrayBuffer(acceptedFiles);
        reader.onload = () => {
          var wordArray = CryptoJS.lib.WordArray.create(reader.result);
          var md5 = CryptoJS.MD5(wordArray).toString();
          var base64 = CryptoJS.enc.Base64.stringify(wordArray);
          handleAddFile(fileName, fileType, base64, md5, props.node.id)
        }
    }  
  }

const handleNodeClick = () => {  
  if( properties.verboseLog==1)
    console.log("HNC",fileInputRef);
  if(fileInputRef.current!=null)
    fileInputRef.current.click();
};

  const handlePreviewFile = () => {
    const url = properties.productionUrl+`/fileManager/openFileConsiglio.php?file=${props.node.realPath}/${props.node.text}`
    window.open(url);
  }

  const handleMovePos = (direction) => {
    if( properties.verboseLog==1)
      console.log("FN HMP", direction);
    handleClose()
    handleMove(direction, id)
  }
  
  useEffect(() => {
    setLabelText(props.node.text)
  }, [props.node.text])

  useEffect(() => {
    setLabelText(props.node.text)
  }, [handleRenameFile])

  return (
    <>
      <div
        className="tree-node"  
        style={{ 
          paddingInlineStart: indent,
          alignItems: "center",
          height: "36px",          
          paddingInlineEnd: "8px",
          //width: "80%",
          minWidth: '250px',
          width: 'fit-content',
          display: "flex",
          cursor: "pointer",          
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
          } 
        }}
        onClick={handleNodeClick}
        //aria-controls={`menu-${id}`}
        //aria-haspopup="true"
        onContextMenu={handleClick}
        {...dragOverProps}
      >
      <div 
      style={{
        alignItems: "center",
        fontSize: "0",
        cursor: "pointer",
        display: "flex",
        height: "24px",
        justifyContent: "center",
        width: "24px",
        transition: "transform linear 0.1s",
        transform: props.isOpen ? "rotate(90deg)" : "rotate(0deg)",
      }}
      >
          {props.node.droppable && (
            <div onClick={handleToggle}>
              <ArrowRight />
            </div>
          )}
          {
            !props.node.droppable && (
              <div>
                <ArrowRight />
              </div>
            )
          }
        </div>
        <div>
          <TypeIcon
            droppable={droppable}
            fileType={data?.fileType}
            folderType={data?.color}
          />
        </div>
        <Box sx={{ paddingInlineStart: "8px"}} position="relative">
          {
            visibleInput ? (
              <TextField
                id={`text-field-${id}`}
                variant="outlined"
                value={labelText}
                onChange={(e) => setLabelText(e.target.value)}
                sx= {{"& .MuiInputBase-input": {
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  background: "white",
                  width:"600px",
                  fontSize: "16px"}}}
                onKeyPress={handleUpdateText}
                autoFocus
              />
            ) : (
              <Typography style={{fontSize: "16px" }} variant="body2">{props.node.text}</Typography>
            )
          }
        
          {
            props.node.data.isFolder && (
              //console.log("ECCCOOO",props.node.data),
               <Box >
                <input  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange} />
              </Box>
            )
          }
        </Box>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          style={{    maxWidth: "360px",
          minWidth: "150px"}}
        >
          <ListItem button onClick={() => { handlePreviewFile(); handleClose(); }}>
            <ListItemText primary="Apri" />
          </ListItem>
          <ListItem button onClick={() => { setVisibleInput(true); handleClose(); }}>
            <ListItemText primary="Rinomina" />
          </ListItem>
          <ListItem button onClick={() => { handleDeleteFile(id); handleClose() }}>
            <ListItemText primary="Elimina" />
          </ListItem>
          <ListItem button onClick={() => handleMovePos('up')}>
            <ListItemText primary="Sposta su" />
          </ListItem>
          <ListItem button onClick={() => handleMovePos('down')}>
            <ListItemText primary="Sposta giù" />
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

export default FileNode