import React, { Component,useEffect } from "react";
import { useParams, } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import {ThreeDots} from 'react-loader-spinner';
import SweetAlert from "react-bootstrap-sweetalert";
import "../../App.css";
import Select from 'react-select';
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { List } from 'react-virtualized';
import {properties} from "../../utils/properties";
import '../../assets/bootstrap-custom.css';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function Presence3() {
  dayjs.locale('it');  
  const navigate = useNavigate();
  const [alert, setAlert] = React.useState(null)
  let {idDossier} = useParams();
  const [loading, setLoading]= React.useState(true);
  const [loading2, setLoading2]= React.useState();
  const [disabled, setDisabled]= React.useState(false);
  
  const [RevisoriList, setRevisoriList] = React.useState([]);  
  const [RevisoriPresenze, setRevisoriPresenze] = React.useState([]);
  const [AltriList, setAltriList] = React.useState([]);
  const [AltriPresenze, setAltriPresenze] = React.useState([]);
  const [orderMeeting, setOrderMeeting] = React.useState([]);  

  const [availableDates, setAvailableDates] = React.useState([]);
  const [verbaleDate, setVerbaleDate] = React.useState(null);
  
  let redirectId="current";

  const organiValues =[
    {value: "10", label:"Comitato di Gestione"},
    {value: "20", label:"Consiglio Generale"},
    {value: "30", label:"Collegio Sindacale"}
    //{value: "30", label:"Altri Orgai"}
];



  const MenuList = props => {
    const rows = props.children;
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
      <div key={key} style={style}>{rows[index]}</div>
    );
  
    return (
      <List
        style={{ width: '100%' }}
        width={300}
        height={300}
        rowHeight={30}
        rowCount={rows.length}
        rowRenderer={rowRenderer}
      />
    )
  }

  const customStyles = {
    control: base => ({
      ...base,
      textAlign: 'left',
      height: 55,
      minHeight: 55
    })
  };

  function handleChange1(event, idDossier) {
    if (event && event.value){
      if( properties.verboseLog==1)
       console.log("P3",event, idDossier);
      if( event.value=="10"){
            //COMITATO DI GESTIONE
            if( properties.verboseLog==1)
              console.log("GO TOWARDS COMITATO DI GESTIONE");            
            navigate("/presenze");
       }
       else{
            if( event.value=="20"){
              //CONSIGLIO GENERALE
              if( properties.verboseLog==1)
                console.log("GO TOWARDS CONSIGLIO GENERALE");              
              navigate("/presenze2");
            }
            else{
              if( event.value=="30"){
                //COLLEGIO SINDACALE
                if( properties.verboseLog==1)
                  console.log("REMAINS ON COLLEGIO SINDACALE");
              }
            }
        }
     }
  }

  const showAlert = () => {
    if( properties.verboseLog==1)
      console.log("showing alert");
    setAlert(
      <SweetAlert
      warning
      style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
      title={"Impossibile accedere al foglio delle presenze per la seduta " + idDossier}
      text="ciao"      
      onConfirm={() => { navigate(-1) }}
      onCancel={() => { navigate(-1) }}
      confirmBtnCssClass="custom-btn custom-btn-success" 
      cancelBtnCssClass="custom-btn custom-btn-cancel"      
      confirmBtnText="Indietro"      
    >
    </SweetAlert>
    );
};

  const handleSubmit = e => {
    e.preventDefault();    
    setLoading(true);    
    let endpoint = properties.productionUrl+properties.productionUrlSuffix +"/core/rest/updatePresenzeFieldCollegio.php";                             
    var bodyFormData=new FormData();
    bodyFormData.append('idf', idDossier);
    bodyFormData.append('role', 'submit');
    bodyFormData.append('index', 1);
    bodyFormData.append('value', 1);

    if (!window.cordova){
        axios({
                method: 'post',
                url: endpoint,
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(function (response) {
                if( properties.verboseLog==1)
                  console.log(response);
                setRevisoriList(response.data.Revisori);
                setAltriList(response.data.Altri);
                setAltriPresenze(response.data.presenzeAltri);
                setRevisoriPresenze(response.data.presenzeRevisori);
                setDisabled(response.data.Submitted);
                setLoading(false);
            })
            .catch(function (response) {
                console.log("Si è verificato un errore aggiornando le presenze!");
            });

    }
    else {
      var onSuccess = function(r)
      {
        var response = JSON.parse(r);
        if( properties.verboseLog==1)
          console.log(response);
        setRevisoriList(response.Revisori);
        setAltriList(response.Altri);
        setAltriPresenze(response.presenzeAltri);
        setRevisoriPresenze(response.presenzeRevisori);
        setDisabled(response.Submitted)
        setLoading(false);
      }

      var onError = function() {}

      const bodyasString = new URLSearchParams(bodyFormData).toString();      
      window.updatePresenzeCollegio(bodyasString,onSuccess, onError);
    }

};

  const getPresenze = (issueName) => {
    if (!window.cordova){
        if( properties.verboseLog==1)
          console.log("GetPresenze", issueName);          
        let endpoint = properties.productionUrl+properties.productionUrlSuffix +"/core/rest/getPresenzeCollegio.php";   
        var bodyFormData=new FormData();
          
        bodyFormData.append('idf', issueName);
          axios({
              method: 'post',
              url: endpoint,
              data: bodyFormData,
              withCredentials: true, 
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (response) {
              if( properties.verboseLog==1)
                console.log("GBC", response.data);
              setRevisoriList(response.data.Revisori);
              setAltriList(response.data.Altri);              
              setAltriPresenze(response.data.presenzeAltri);     
              setRevisoriPresenze(response.data.presenzeRevisori);
              setDisabled(response.data.Submitted);              
              if( properties.verboseLog==1)
                console.log("AQUI",issueName);
              if(issueName.length==8){                
                  var dateTmp=issueName.substring(2,4)+"/"+issueName.substring(0,2)+"/"+issueName.substring(4,8);
                  if( properties.verboseLog==1)
                    console.log("NEXT1",dateTmp);
                  setVerbaleDate(dateTmp);                  
              }
              else
                  setVerbaleDate(issueName);             
              setLoading(false);              
              setAlert(null);
          })
          .catch(function (error) {
              if (error.response) {              
                if(error.response.status===404){                  
                  idDossier=issueName;                  
                  setLoading(false);
                  showAlert();
              }

              }
          }


          );
    }
    else {
          var onSuccess = function(r){
            if( properties.verboseLog==1)
              console.log('notte' + r);
            var tm2=r;
            tm2.replaceAll("\n1","");
            tm2=tm2.replaceAll("\n","");
            var response = JSON.parse(tm2);
            if( properties.verboseLog==1)
              console.log("GBC", response.data);
            setRevisoriList(response.Revisori);
            setAltriList(response.Altri);            
            setAltriPresenze(response.presenzeAltri);   
            setRevisoriPresenze(response.presenzeRevisori);
            setDisabled(response.Submitted);
            if( properties.verboseLog==1)
              console.log("AQUI",issueName);
            if(issueName.length==8){              
                var dateTmp=issueName.substring(2,4)+"/"+issueName.substring(0,2)+"/"+issueName.substring(4,8);                 
                if( properties.verboseLog==1)
                  console.log("NEXT2",dateTmp);
                setVerbaleDate(dateTmp);
            }
            else
                setVerbaleDate(issueName);           
            setLoading(false);            
            setAlert(null);
          }

          var onError = function() {}

      window.getPresenzeCollegio(issueName,onSuccess, onError);
    }
}

function handleChange3(event, role, id, index) {    
    setLoading2(id);
    let val=0;
    if(event.target.checked)
        val=1
    
    let endpoint = properties.productionUrl+properties.productionUrlSuffix +"/core/rest/updatePresenzeFieldCollegio.php";      
    var bodyFormData=new FormData();
    bodyFormData.append('idf', idDossier);
    bodyFormData.append('role', role);
    bodyFormData.append('index', index);
    bodyFormData.append('value', val);

    if (!window.cordova){
      axios({
              method: 'post',
              url: endpoint,
              data: bodyFormData,
              withCredentials: true, 
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (response) {
              if( properties.verboseLog==1)  
                console.log(response);
              setRevisoriList(response.data.Revisori);
              setAltriList(response.data.Altri);                            
              setAltriPresenze(response.data.presenzeAltri);
              setRevisoriPresenze(response.data.presenzeRevisori);
              setDisabled(response.data.Submitted)
              setLoading2();
          })
          .catch(function (response) {
            console.log("si èverificato un errore aggiornando il foglio presenze");
          });
      }
      else {

        var onSuccess = function(r){          
          var response = JSON.parse(r);
          setRevisoriList(response.Revisori);
          setAltriList(response.Altri);
          setAltriPresenze(response.presenzeAltri); 
          setRevisoriPresenze(response.presenzeRevisori);
          setDisabled(response.Submitted)
          setLoading2();
        }

        var onError = function() {}

        const bodyasString = new URLSearchParams(bodyFormData).toString();

        window.updatePresenzeCollegio(bodyasString,onSuccess, onError);

      }

  }

function formatDate(date){
    return date.substring(0,2)+"/"+date.substring(2,4)+"/"+date.substring(4,8);
}
function formatDateYMA(date){
  return date.substring(4,8)+"-"+date.substring(2,4)+"-"+date.substring(0,2);
}


const resolveCurrent2 = function(jsonList){
  if( properties.verboseLog==1)
    console.log("RC",jsonList);
  var entireMeeting = Object.entries(jsonList);
  var orderMeeting = [];
  for (var i = entireMeeting.length - 1; i >= 0; i--) {
      var eachMeeting = {
          id: entireMeeting[i][0],
          order: entireMeeting[i][1]["order"],
          date: entireMeeting[i][1]["dataSeduta"],
          };
      orderMeeting.push(eachMeeting);
      if( properties.verboseLog==1)
          console.log("11",eachMeeting);      
  }
  orderMeeting.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
  var meee=orderMeeting.shift();
  if( properties.verboseLog==1)
    console.log("SHIFT",meee);  
  idDossier=meee.id;
  redirectId=meee.id;  
  
  navigate("/presenze3/"+redirectId,{ replace: true });
  getPresenze(idDossier);  
  if( properties.verboseLog==1)
    console.log("fine resolve current2")
}


const doPostSedute2 = function() {
  if( properties.verboseLog==1)
    console.log("doing sedute post111");
  var xhr2 = new XMLHttpRequest();  
  xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSeduteJsonCollegio.php',true);
  xhr2.withCredentials = true;
  xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhr2.onload = function (e) {
      if (xhr2.readyState === 4) {
          if (xhr2.status === 200) {
              var responseText = xhr2.responseText;
              var obj=JSON.parse(responseText);
              var jsonListSedute;
              if(obj.status==="204" ){
                console.log("OK PRESENCE3 204",xhr2);
                jsonListSedute = JSON.parse(localStorage.getItem("listSeduteCollegio"));
              }
              else{
                  //status 201
                  console.log("OK PRESENCE3 201",xhr2);
                  if( properties.verboseLog==1)
                    console.log("OK95",obj.date);

                  try{
                    localStorage.setItem("listSeduteCollegio", obj.content);
                    localStorage.setItem("listSeduteVersionCollegio", obj.date);
                  }
                  catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                  }
                  jsonListSedute = JSON.parse(obj.content);
              }          
          var entireMeeting = Object.entries(jsonListSedute);
          var meeting = [];
          var meetingDates=[];
          for (var i = entireMeeting.length - 1; i >= 0; i--) {
              var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
              var eachMeeting = {
                  id: entireMeeting[i][0],
                  name: dateLabel.replace("/","-"),
                  location: entireMeeting[i][0],
                  startDate: dateLabel.replace("/",""),
                  color: '#30720f',
              };
              meeting.push(eachMeeting);              
              meetingDates.push(dayjs(eachMeeting.startDate));
          }
          if( properties.verboseLog==1)
            console.log("AvailableDates",meetingDates);
          setAvailableDates(meetingDates);
          if( properties.verboseLog==1){
            console.log("2231",meeting);
            console.log("2232",meetingDates);
          }
          
          if(window.location.href.endsWith("/current")||window.location.href.endsWith("/current/")){
            resolveCurrent2(jsonListSedute);
          }
          else{
            if(idDossier!=null){
                if( properties.verboseLog==1)
                  console.log("IDS:"+idDossier,idDossier.length);
                if(idDossier.length==8)
                    getPresenze(idDossier);
                else
                    resolveCurrent2(jsonListSedute);
            }
            else{
                if( properties.verboseLog==1)
                  console.log("IDS is null");
                resolveCurrent2(jsonListSedute);
            }
          }
        }
          else {
              console.error("Error 1", xhr2.statusText);
              alert("Errore recuperando la lista delle sedute");
          }
          if( properties.verboseLog==1)
            console.log("fine dopostsedute2");
      }
      
  };

  xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
  };

  let urlEncodedData = null; //"idf=" + meetingId;
  if (localStorage.listSeduteCollegio!=='' &&localStorage.listSeduteCollegio!==undefined){
      const currentData=localStorage.getItem("listSeduteVersionCollegio");
      urlEncodedData = "date=" + currentData;
  }
  xhr2.send(urlEncodedData);
}

const handleDateChange = (date) => {
    if( properties.verboseLog==1)
      console.log("HDC1",date);    
    var rr=date.format('DDMMYYYY');
    if( properties.verboseLog==1)
      console.log("HDC2",rr);
    navigate("/presenze3/"+rr,{ replace: true });
    setLoading(true);
    getPresenze(rr);
  };


  const updateUI = function(jsonData) {
          var entireMeeting = Object.entries(jsonData);
          var meeting = [];
          var meetingDates=[];
          for (var i = entireMeeting.length - 1; i >= 0; i--) {
              var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
              var eachMeeting = {
                  id: entireMeeting[i][0],
                  name: dateLabel.replace("/","-"),
                  location: entireMeeting[i][0],
                  startDate: dateLabel.replace("/",""),
                  color: '#30720f',
              };
              meeting.push(eachMeeting);              
              meetingDates.push(dayjs(eachMeeting.startDate));
          }
          if( properties.verboseLog==1)
            console.log("AvailableDates",meetingDates);
          setAvailableDates(meetingDates);
          if( properties.verboseLog==1){          
            console.log("2231",meeting);
            console.log("2232",meetingDates);
          }
          if(window.location.href.endsWith("/current")||window.location.href.endsWith("/current/")){
            resolveCurrent2(jsonData);
          }
          else{
            if(idDossier!=null){
                if( properties.verboseLog==1)
                  console.log("IDS:"+idDossier);
                if(idDossier.length==8)
                    getPresenze(idDossier);
                else
                    resolveCurrent2(jsonData);
            }
            else{
              if( properties.verboseLog==1)
                console.log("IDS is null");
            }
                resolveCurrent2(jsonData);
          }
  }
   
  const shouldDisableDate = (date) => {
    return !availableDates.some(eventDate => eventDate.isSame(date, 'day'));
  };

  
  

useEffect(() => {  
  if(!window.cordova){
      //CHECK AUTHENTICATION
      var xhr = new XMLHttpRequest();      
      xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
      xhr.withCredentials = true;
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      xhr.onload = function (e) {
          if (xhr.readyState === 4) {
              if (xhr.status === 200) {              
                  if( properties.verboseLog==1)
                    console.log("OK",xhr);
              doPostSedute2();
          } else {
              console.error("Error 1", xhr);
              localStorage.clear();              
              navigate("/login");
          }
      }
      };

      xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();          
          navigate("/login");
      };

      let urlEncodedData = null;
      xhr.send(urlEncodedData);
      //CHECK AUTHENTICATION
    } 
    if (window.cordova){
       var onSuccess = function(data) {
          var obj = JSON.parse(data);
          var sedute = JSON.parse(obj.content);
          if( properties.verboseLog==1)
            console.log(sedute);
          updateUI(sedute);
        }

        var onError = function() {
           //alert("Impossibile scaricare l'elenco delle sedute, si prega di riprovare");
        }

      window.checkSeduteJsonCollegio(onSuccess, onError);
    }
 
},
   []);


  return (
    <div key={uuidv4()}>
    {loading ? (
      <div key={uuidv4()} style={{ width:"25%",   display: "flex",
      marginLeft: "auto", marginRight: "auto" }}>
       <ThreeDots color="#30720f" height='350' width='150'/>
       </div>
      ):(
        <div className="content">
            <Container maxWidth="xl">
            
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it" >
            <Grid container spacing={2}>
              <Grid item xs={8} sm={6} md={8}>
                <Box display="flex" alignItems="center" height="100%">
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    Organo relativo alla riunione:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sm={6} md={4}>             
                <Select key={uuidv4()}     
                    isSearchable= {false}                                  
                    component={{MenuList}}  
                    fullWidth                 
                    styles={customStyles}                 
                    onChange={(event) => handleChange1(event, idDossier)}
                    defaultValue={{
                      label: "Collegio Sindacale",
                      value: "30"
                    }}     
                    options={organiValues}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#a2ee7d',
                        primary: 'black',
                      },
                    })}   

                  /> 
              </Grid>

              <Grid item xs={8} sm={6} md={8}>
                    <Box display="flex" alignItems="center" height="100%">
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                    Data della riunione:
                    </Typography>
                    </Box>
              </Grid>
              <Grid item xs={4} sm={6} md={4}>                
                  <DatePicker
                      disableToolbar                                                                        
                      onKeyDown={(e) => {
                          e.preventDefault();
                      }}
                      value={dayjs(verbaleDate)}
                      shouldDisableDate={shouldDisableDate}
                      onChange={handleDateChange}
                      sx={{width:'100%', backgroundColor:"white", "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black"
                          },
                         
                      } }}
                        
                      slotProps={{                          
                          field: {
                              readOnly: true
                          },
                          day: ({ day, selectedDate, isInCurrentMonth }) => {
                            const isEventDate = availableDates.some((eventDate) => eventDate.isSame(day, 'day'));
                            const isSelected =  day.isSame(selectedDate, 'day');
                            
                            return {
                              sx: {
                                '&.Mui-selected': {
                                  backgroundColor: '#a3a5c2 !important', 
                                  color: 'white',
                                  fontWeight: 'bold',
                                  '&:hover': {
                                    backgroundColor: '#666999 !important', 
                                  },
                                },
                                ...(isEventDate && !isSelected && {
                                  backgroundColor: '#666999', 
                                  color: 'white', 
                                  borderRadius: '50%',
                                  fontWeight: 'bold',
                                  '&:hover': {
                                    backgroundColor: '#3d3f5c', 
                                  },
                                }),                          
                              },
                             
                            };
                          }                                                
                      }}                                                             
                    />
               
                 
               </Grid>
               
               <Grid item xs={12}>
                <form className="form1" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <div style={{ maxWidth: "100%",  paddingTop: "10px",width: "100%", alignItems: "left",margin: "auto"}}>
                        <Typography variant="body1" style={{textAlign: "left", fontWeight: "bold", paddingBottom:"5px",paddingTop:"15px"}}>
                        Elenco dei partecipanti alla seduta del Collegio Sindacale del {formatDate(idDossier)}:
                        </Typography>               
                                                                                           
                            <div className="common-list" key={uuidv4()} style = {{alignItems: "center", display: "block", height: "100%", paddingTop:"20px", justifyContent: "center"}}>
                                <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"40px"}}>
                                Componenti Collegio Sindacale
                                </p>
                                {RevisoriList.map((item, index) => (
                                    <Grid key={uuidv4()}container spacing={3} alignItems="stretch">
                                        <Grid item xs={12} sm={5}>
                                        <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"60px"}}>
                                        {item} </p>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormControlLabel key={uuidv4()} style={{paddingLeft:"20px", color:"black"}}control={<Checkbox key={uuidv4()} style ={{color: "#666999"}} disabled={disabled} checked={(RevisoriPresenze[index]=== 1) ?? false}/>} label="Presente" onChange={(event) => handleChange3(event, "collegio",item, index)}/>
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                        {loading2==item ? (
                                                <div style={{marginRight: "20px",marginTop:"5px"}}>
                                                <CircularProgress size={22} color= "success" />
                                                </div>
                                            ):( <></> )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </div>
                           <div className="common-list" key={uuidv4()} style = {{alignItems: "center", display: "block", height: "100%", paddingTop:"20px", justifyContent: "center"}}>
                                <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"40px"}}>
                                    Altri Partecipanti
                                </p>
                                {AltriList.map((item, index) => (
                                    <Grid key={uuidv4()} container spacing={3} alignItems="stretch">
                                        <Grid item xs={12} sm={5}>
                                            <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"60px"}}>
                                            {item} </p>
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <FormControlLabel key={uuidv4()} style={{paddingLeft:"20px", color:"black"}}control={<Checkbox key={uuidv4()} style ={{color: "#666999"}} disabled={disabled} checked={(AltriPresenze[index]=== 1) ?? false}/>} label="Presente" onChange={(event) => handleChange3(event, "altri",item, index)}/>
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            {loading2==item ? (
                                                <div style={{marginRight: "20px",marginTop:"5px"}}>
                                                <CircularProgress size={22} color= "success" />
                                                </div>
                                            ):( <></> )}
                                        </Grid>
                                    </Grid>
                                ))}
                                <br />
                            </div>    
                        </div>
                        <br />
                        {!disabled ? (
                        <button className="button354" disabled={disabled} >Invia lista partecipanti</button>
                        ):(<></>)}
                        <br /><br />
                    </div>
                </form>
                        </Grid> 
            </Grid>
            </LocalizationProvider>
            
            </Container>
      </div>
      )}

            {alert}

      </div>
  );

  }
