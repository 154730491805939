import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import {properties} from "../../utils/properties";
import '../../assets/bootstrap-custom.css';

export default function PubHandler() {    
    const [alert, setAlert] = useState(null);    
    const navigate = useNavigate();
     
    const showAlert = () => {
        if( properties.verboseLog==1)
            console.log("showing alert");
        setAlert(
          <SweetAlert
          warning
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
          title="Consolidare la seduta?"
          onConfirm={() => handlePublishing()}
          onCancel={() => { navigate(-1) }}        
          confirmBtnCssClass="custom-btn custom-btn-success" 
          cancelBtnCssClass="custom-btn custom-btn-cancel"
          confirmBtnText="Si"
          cancelBtnText="No"
          showCancel
        >
        </SweetAlert>
        );
    };

    const handleisFreezed = () => {        
        if( properties.verboseLog==1)
            console.log("handleisFreezed");        
        setAlert(null);            
        let endpoint = properties.productionUrl+properties.productionUrlSuffix +"/core/rest/doCons.php";                             
        var bodyFormData=new FormData();                      
        bodyFormData.append('action', 'check');    
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            withCredentials: true, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            if( properties.verboseLog==1)
                console.log(response);
            if(response.data.idf==undefined){
                if( properties.verboseLog==1){
                    console.log(response.data.idf);
                    console.log(response.data.freezed);
                }
                navigate("/error/200"); 
            }
            else if(response.data.idf!=undefined && response.data.freezed!=undefined && response.data.freezed==false){
                //C'è un fascicolo non freezato         
                navigate("/error/201");            
            }
            else if(response.data.idf!=undefined && response.data.freezed!=undefined && response.data.freezed==true){
                showAlert();
            }
        })
        .catch(function (response) {            
            toast.error("Si è verificato un errore pubblicando la seduta!");            
        });
    }



    const handlePublishing = () => {
        //const issueName = `Seduta del ${format(new Date(_inviatationDate), 'dd-MM-yyyy')}`
        if( properties.verboseLog==1)
            console.log("handlePublishing");        
        setAlert(null);                    
        let endpoint = properties.productionUrl+properties.productionUrlSuffix +"/core/rest/doCons.php";                             
        var bodyFormData=new FormData();                      
        bodyFormData.append('action', 'consolida');    
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            withCredentials: true, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {            
            localStorage.removeItem("meeting");
            localStorage.removeItem("meetingContent");
            localStorage.removeItem("meetingVersion");            
            navigate("/fascicoli/",{ replace: true });
        })
        .catch(function (response) {            
            toast.error("Si è verificato un errore pubblicando la seduta!");            
        });
    }

   
    useEffect(() => {              
        handleisFreezed();
    }, []);





return (
  <div key={uuidv4()}>         
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 

             <div>
                {alert}        
            </div>
    </div>
    );
}
