import React from "react";
import FolderIcon from '@mui/icons-material/Folder';
import EventIcon from '@mui/icons-material/Event';
import { AiOutlineFilePdf, AiOutlineFileWord, AiOutlineFileImage,AiOutlineFilePpt,AiOutlineFileExcel } from "react-icons/ai";
import {properties} from "../../utils/properties";

const TypeIcon = (props) => {
  
const colorMapping = {
  assets: "#00CC33",
  red: "#E80000",
  green: "#00CC33",
  blue: "#C2EFFF",
  yellow: "#FFE118",
  brown: "#8b4513",
  orange: "#E98624",
  purple: "#9370db",
  hidden: "#444444",
  std: "#CCCCCC",
  root: "#FFFFFF",
  std_consiglio: "#BF360C",
  hidden_consiglio: "#444444",
  std_collegio: "#666999",
  hidden_collegio: "#444444",
  std_cgpianeta: "#008FD1",
  std_cgcultura: "#ce0f69",
  std_cgpersone: "#F0B323",
  std_cgfinanza: "#046A38",
  std_cgstatuto: "#CCCCCC",
};

const { folderType } = props

  if (props.droppable) {
    if( properties.verboseLog==1)
      console.log("DROPPABLE",props);   
    if(props.folderType==undefined){ //DEVO DIFFERENZIARE PER CAPIRE SE ROOT O MENO
      if( properties.verboseLog==1)
        console.log("-DROPPABLE",props);              
      return <EventIcon />;
    }
    else{        
      return <FolderIcon  sx={{
        color: colorMapping[folderType] || "#000",
      }} />;
    }
  }

  switch (props.fileType) {
    case "application/pdf":      
      return <AiOutlineFilePdf />;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":      
      return <AiOutlineFileWord/>;
    case "application/msword":     
      return <AiOutlineFileWord />;
    case "application/vnd.ms-excel":      
      return <AiOutlineFileExcel/>;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":    
      return <AiOutlineFileExcel/>;
    case "image/jpeg":
      return <AiOutlineFileImage/>;
    case "image/png":      
      return <AiOutlineFileImage/>;
    case "image/x-png":      
      return <AiOutlineFileImage />;
    case "image/gif":      
      return <AiOutlineFileImage />;
    case "image/bmp":      
      return <AiOutlineFileImage />;
    case "image/tiff":      
      return <AiOutlineFileImage />;
    case "application/vnd.ms-powerpoint":      
      return <AiOutlineFilePpt />;
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":      
      return <AiOutlineFilePpt/>;
    default:
      return null;
  }
};

export default TypeIcon
