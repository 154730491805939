import {React,  useRef} from "react";

import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import RouterContext from "../../context/RouterContext";
import {updateSedute} from "./Utils";
import {properties} from "../../utils/properties";
import multiMonthPlugin from '@fullcalendar/multimonth'
import FullCalendar from '@fullcalendar/react'
import itLocale from '@fullcalendar/core/locales/it'
import interactionPlugin from "@fullcalendar/interaction" 

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';


export default function CalendarView() {  
  var myRef= useRef()
  const navigate = useNavigate();
  const [meetingData, setMeetingData] = useState([]);
  const [bothRoles, setBothRoles] = useState(false);
  const [threeRoles, setThreeRoles] = useState(false);
  const [secondLevelButtons, setSecondLevelButtons]= useState(false);    
  const { pageRouter, setPageRouter } = useContext(RouterContext);
  const [startingYear, setStartingYear]= useState(0);
  var lastMeeting= useRef(null);
  
  const updateUI = function(jsonData) { 
        if( properties.verboseLog==1)
            console.log("UPDATEUI-CV");
        var entireMeeting = Object.entries(jsonData);                    
        var orderMeeting = [];
        var colore='#30720f';
        for (var i = entireMeeting.length - 1; i >= 0; i--) {          
            if(entireMeeting[i][1]["organo"]==="Consiglio")
                colore='#BF360C';
            else
                colore='#30720f';
            var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
            if( properties.verboseLog==1)
                console.log(dateLabel);
            var eachMeeting = {                
                name: 'Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                                              
                date:dateLabel,                
                color: colore,
            };            
            orderMeeting.push(eachMeeting);            
            if(lastMeeting.current===undefined) {
                if( properties.verboseLog==1)
                    console.log("1",lastMeeting, eachMeeting.startDate);
                lastMeeting.current=eachMeeting.startDate;
            }
            else{
                if (lastMeeting.current < eachMeeting.startDate){                
                    lastMeeting.current=eachMeeting.startDate;              
                }
            }
        }      
        if( properties.verboseLog==1)
            console.log("MEETING",orderMeeting);
        setMeetingData(orderMeeting);
  }
  
   const doPostSedute2 = function() {
        if( properties.verboseLog==1)
            console.log("doing sedute post CV");
        var xhr2 = new XMLHttpRequest();                        
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSeduteJson.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {     
                    if( properties.verboseLog==1)                         
                        console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                        console.log("OK93",obj, obj.status);          
                    if(obj.status==="204" ){                             
                        console.log("OK CALENDARVIEW 204",xhr2);                        
                        const jsonListSedute = JSON.parse(localStorage.getItem("listSedute"));
                        updateUI(jsonListSedute);
                        
                    }              
                    else{
                        //status 201
                        console.log("OK CALENDARVIEW 201",xhr2);  
                        
                        try{
                            localStorage.setItem("listSedute", obj.content);
                            localStorage.setItem("listSeduteVersion", obj.date);                                      
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }                          
                        const jsonListSedute = JSON.parse(obj.content);
                        updateUI(jsonListSedute);                        
                    }
                } 
                else {                    
                    console.log("Errore 3: Recuperando la lista sedute o non presenti sedute CdG per il ruolo dell'utente");                      
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSedute!=='' &&localStorage.listSedute!==undefined){            
            if( properties.verboseLog==1)
                console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersion");        
            urlEncodedData = "date=" + currentData;                    
        }       
        if( properties.verboseLog==1)
            console.log("CALVIEW",urlEncodedData);
        xhr2.send(urlEncodedData);
    }


 
    useEffect(() => {     
        if( properties.verboseLog==1){
            console.log("USEEFFECT CV");    
            console.log("Arrivo qui", document.referrer);  
        }
        if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null || localStorage.getItem("new_ui")=== "false"){                    
            navigate("/fascicoli",{ replace: true });  
        }              

        if (!window.cordova)
        {
          if(localStorage.getItem("ruolo")==="1"||localStorage.getItem("ruolo")==="2"){
              setBothRoles(true);
          }     
          if(localStorage.getItem("ruolo")==="12"||localStorage.getItem("ruolo")==="21"){
            setThreeRoles(true);
            }     
        }
        else {
          var onSuccess = function(response)
          {
            if( properties.verboseLog==1)
                console.log("OK LOGIN 33");
            var obj=JSON.parse(response);
            var ruolo = obj.Ruolo;
        
            if(ruolo=="1"||ruolo=="2"){
                setBothRoles(true);
            }
            if(ruolo =="12" ||ruolo =="21" ){
                setThreeRoles(true);
            }
          }
        
          var onError = function() {}
        
          window.getInfo3(onSuccess, onError);
        }             

                           
        if(!window.cordova){        
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                            
            xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
            xhr.withCredentials = true;
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                         
                        if( properties.verboseLog==1)
                            console.log("OK",xhr);   
                        updateSedute("Comitato",doPostSedute2, updateUI);                               
                } else {
                    console.error("Error 1", xhr);   
                    localStorage.clear();                                     
                    navigate("/login");  
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();                
                navigate("/login");  
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
           updateSedute("Comitato",doPostSedute2, updateUI);   
        }
       
    }, []);


    const onFabClick= (e ) => {           
        //FAB aggiunta ambito
        if( properties.verboseLog==1)
            console.log("OFC",e);            
        //navigate("/consiglio"); 
        navigate("/calendarConsiglio");         
      };

      const onFabClick2= (e ) => {                   
        if( properties.verboseLog==1)
            console.log("OFC-2",e);            
        //navigate("/collegio");  
        navigate("/calendarCollegio");  
      };

    function formatDate(dateString) {
        const [year, month, day] = dateString.split("-");
        return `${day}${month}${year}`;
}
/*
function checkStyle(clickedCell) {
    if (!clickedCell) return false; 
  
    const computedStyle = window.getComputedStyle(clickedCell);
      
    for (let property of computedStyle) {
      const value = computedStyle.getPropertyValue(property);
      console.log("21",value);
      if (value === 'rgb(48, 114, 15)') {
        return true;
      }
    }
  
    return false; 
  } */

  function findDataDateElementsRecursive(element, results = []) {
    if (!element) return results;
  
    // Controlla se l'elemento corrente ha l'attributo 'data-date'
    if (element.hasAttribute('data-date')) {
      results.push({
        element,
        value: element.getAttribute('data-date'),
      });
    }
  
    // Itera sui figli e richiama ricorsivamente la funzione
    for (let child of element.children) {
      findDataDateElementsRecursive(child, results);
    }
  
    return results;
  }

  function containsColor( val, rgbColor){
    const dataDateAttr = val.attributes['data-date'];
    if (dataDateAttr && dataDateAttr.ownerElement) {
      const ownerElement = dataDateAttr.ownerElement;      
      const allDescendants = ownerElement.querySelectorAll('*');      
      const hasTargetBackground = Array.from(allDescendants).some(el => {
        const style = window.getComputedStyle(el);
        return style.backgroundColor === rgbColor;
        });

      if (hasTargetBackground) {
        console.log('Trovato un elemento con background-color di interesse');
        return true;
        
      } else {
        console.log('Nessun elemento con background-color di interesse');
      }
    } else {
      console.log('Attributo data-date non trovato.');
    }
    return false;
  } 
  


   const topicList = (e) => {    
    if( properties.verboseLog==1)
        console.log("called topic list CV",e);
            
    const dateOk=formatDate(e.dateStr);
    //const clickedCell = e.jsEvent.target;          

    //const cellBackgroundColor = window.getComputedStyle(clickedCell).backgroundColor;
    //if( properties.verboseLog==1)
    //    console.log("PDPM",e.dayEl, clickedCell, window.getComputedStyle(clickedCell),cellBackgroundColor,clickedCell);
   
    
    //if (cellBackgroundColor === "rgb(102, 105, 153)") { // Collegio Sindacale        
    if (containsColor(e.dayEl,"rgb(102, 105, 153)")) { // Collegio Sindacale        
        if( properties.verboseLog==1)
            console.log("Cliccato un giorno con eventi collegio.");
        navigate("/collegio/"+ dateOk );
    } 
    //else if (cellBackgroundColor === "rgb(191, 54, 12)") { // Consiglio Generale
    else if (containsColor(e.dayEl,"rgb(191, 54, 12)")) { // Consiglio Generale
          navigate("/consiglio/"+ dateOk );
          if( properties.verboseLog==1)
            console.log("Cliccato un giorno con eventi consiglio generale");
    }     
    //else if (cellBackgroundColor === "rgb(48, 114, 15)" || containsColor(e.dayEl,"rgb(48, 114, 15)")) { // Comitato di Gestione
    else if (containsColor(e.dayEl,"rgb(48, 114, 15)")) { // Comitato di Gestione
            if( properties.verboseLog==1)  
                console.log("Cliccato un giorno con eventi comitato di gestione");
            navigate("/fascicoli/"+ dateOk+"/summary" );
    }
    else {
          console.log("Nessuna logica specifica per questo colore.");
    }

    };


 
  return (
    <div style={{marginTop:"10px",paddingRight:"20px",paddingLeft:"20px"}}>
        {bothRoles ?(
         <Tooltip title="Consiglio Generale" placement="left">
            <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {onFabClick(event) }}                
                style={{
                    margin: 0,
                    //background: "#30720f",
                    background: "#BF360C",
                    top: 'auto',
                    right: 10,
                    bottom: 25,
                    color: '0xFFFFFF',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <ArrowForwardIosIcon />                    
            </Fab>  
          </Tooltip>
       ):( <></>)}
           {threeRoles ?(
   <Tooltip title="Altri Organi" placement="left">
      <Fab aria-label="add"                 
          //variant="contained"           
          onClick={(event) => {setSecondLevelButtons(!secondLevelButtons) }}           
          style={{
              margin: 0,
              //background: "#30720f",
              background: "#CCCCCC",
              top: 'auto',
              //left: 10,
              right: 10,
              bottom: 25,
              color: '0xFFFFFF',
              left: 'auto',
              position: 'fixed',}}>                    
              <MoreHorizIcon />                  
      </Fab>  
    </Tooltip>
 ):( <></>)}
   {threeRoles && secondLevelButtons ? (
    <div> 
        <Tooltip title="Consiglio Generale" placement="left">
            <Fab aria-label="add2"                 
                  //variant="contained" 
                  size='medium'
                  onClick={(event) => {onFabClick(event) }}
                  
                  style={{
                      margin: 0,
                      background: "#BF360C",
                      top: 'auto',                     
                      right: 10,
                      bottom: 90,                      
                      left: 'auto',
                      position: 'fixed',}}>                    
                      <ArrowForwardIosIcon />                        
            </Fab>       
        </Tooltip>
        <Tooltip title="Collegio Sindacale" placement="left">
            <Fab aria-label="add3"                                   
                  size='medium'
                  onClick={(event) => {onFabClick2(event) }}                  
                  style={{
                      margin: 0,
                      background: "#666999",
                      top: 'auto',                     
                      right: 10,
                      bottom: 145,
                      //color: '0xFF0000',
                      left: 'auto',
                      position: 'fixed',}}>                    
                      <ArrowForwardIosIcon />
            </Fab>
        </Tooltip>       
                  </div> 
            ): (<></>)}
    
    <FullCalendar      
            ref={myRef}
            plugins={[ multiMonthPlugin,interactionPlugin ]}
            initialView="multiMonthYear"
            locale={itLocale}
                     
            height="auto"            
            multiMonthMaxColumns= {4}            
            eventDisplay={'background'}
            contentHeight="auto"     
            aspectRatio={2.5} 
                     
            //aspectRatio={1.7} 

            eventDidMount={(info) => {              
                info.el.style.backgroundColor = info.event.extendedProps.color;
                info.el.style.borderColor = info.event.extendedProps.color;
                info.el.style.opacity = "0.8"; 
            }}

            eventColor="#30720E"
            headerToolbar={{
                //left: 'prev,next today',
                right: 'prev,next today',
                center: 'title',
                left: '',
            }}
            //eventColor={'red'}
            dateClick={e => topicList(e)}
            //dateClick={topicList}
            //events={events}    
            events={meetingData}    
            />
        {/*    <style>
                {`
                .fc-toolbar.fc-header-toolbar .fc-customLabel-button {
                    background: none;
                    border: none;
                    font-size: 1.5em;
                    color: green; 
                    cursor: default;
                    font-weight: bold;
                    padding: 0;
                    //pointer-events: none;
                }
                .fc-event,.fc-event-bg {
                    background-color: #30720E !important; 
                    opacity: 1 !important;  
                    border-color: #30720E !important;
                }
                `}
            </style>  */}
      </div>
  );
  
  }  
