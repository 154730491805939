import React, { useEffect,useState } from "react";
import axios from 'axios';

import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import {properties} from "../../utils/properties";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";


export default function Rigenerator() {    
    let {idDossier} = useParams();
    const [alert, setAlert] = useState(null);
    
    const navigate = useNavigate();
    const resolveLast = function(){
        
    let endpoint = properties.productionUrl+properties.productionUrlSuffix +"/core/rest/getLast.php?edited";                         
    axios({
            method: 'get',
            url: endpoint,                
            withCredentials: true,                 
    })
        .then(res => {
        const lastId = res.data;
        idDossier=res.data;
        rigeneraSeduta();
        if( properties.verboseLog==1)
            console.log("resolveLast",lastId);
        //console.log("rl2",window.location.href);            
        })
    }

    const rigeneraSeduta = function(){
        
        axios.get(properties.productionUrl+'/postIDSF?IDFNOWS='+idDossier).
        then(response => {                        
            if( properties.verboseLog==1)
                console.log("THEN",response);   
            setAlert(
                <SweetAlert
                success
                closeOnClickOutside ={true}
                showCancel ={false}
                showConfirm={true}
                style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}                    
                title={"La seduta è stata rigenerata con successo!"}
                onConfirm={() => {
                    setAlert(null); 
                    navigate("/fascicoli/"+idDossier,{ replace: true });                    
                }}           
                onCancel={() => {
                    setAlert(null);   
                    navigate("/fascicoli/"+idDossier,{ replace: true });                      
                }}
                confirmBtnText={"Continua"}    
                focusCancelBtn= {true}                    
                cancelBtnText={"No"}                       
                confirmBtnCssClass="custom-btn custom-btn-success" 
                cancelBtnCssClass="custom-btn custom-btn-cancel"                                                            
                >
                </SweetAlert>);                
        }).catch(({response}) => {            
            console.log("CATCH",response);
            setAlert(
                <SweetAlert
                error
                closeOnClickOutside ={true}
                showCancel ={false}
                showConfirm={false}
                style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}                    
                title={"La seduta è bloccata e, pertanto, non è possibile rigenerarla. Sbloccare la seduta e riprovare!"}
                onConfirm={() => {
                    setAlert(null); 
                    navigate("/fascicoli/"+idDossier,{ replace: true });                    
                }}           
                onCancel={() => {
                    setAlert(null);   
                    navigate("/fascicoli/"+idDossier,{ replace: true });                      
                }}              
              >
              </SweetAlert>);    
            
        }).finally(() => {
            if( properties.verboseLog==1)
                console.log("FINALLY",);                                           
        });
    }


  useEffect(() => {    
    if( properties.verboseLog==1){
        console.log(window.location.href); 
        console.log(idDossier);   
    }
    if(window.location.href.endsWith("/last")||window.location.href.endsWith("/last/")){        
        if( properties.verboseLog==1)
            console.log("ends with last");   
        resolveLast();            
    }
    else{
        if( properties.verboseLog==1)
            console.log("ends with an id");   
        rigeneraSeduta();
    }

    }, []);

return (
  <div key={uuidv4()}>         
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 
             {alert}   
    </div>
    );
}