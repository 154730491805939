import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import RouterContext from "../../context/RouterContext";
import "../../App.css";
import Typography from '@mui/material/Typography';
import Select,{ components }  from 'react-select';
import {getQueryString, getQueryStringValue, setQueryStringValue,setQueryStringWithoutPageReload } from "./queryString";
import {ThreeDots} from 'react-loader-spinner';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {properties} from "../../utils/properties";
import CircularProgress from "@mui/material/CircularProgress";

import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { Radio, Box,FormControl, FormControlLabel, RadioGroup } from '@mui/material';

export default function UpdateAttachments() {

    const isMobile = window.matchMedia("(pointer: coarse) and (hover: none)").matches;
    const { searchQS } = useLocation();
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    const navigate = useNavigate();
    const [resultData, setResultData] = useState([]);
    const [occurrences, setOccurrences]=useState();    
    const [loading, setLoading]= React.useState(true);
    const [loading2, setLoading2]= React.useState(false);
    const [viewVerbali,setViewVerbali]= React.useState(true);
    const [viewError,setViewError]= React.useState(true);
    const [viewResults,setViewResults]= React.useState(true);
    const [viewButtons,setViewButtons]= React.useState(true);
    const [viewPrevious,setViewPrevious]= React.useState(true);
    const [viewNext,setViewNext]= React.useState(true);        
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch]=React.useState("");
    const [sort, setSort] = useState(0);
    const [tmpValue, setTmpValue] = useState(searchValue);
    var toBeRemoved=true;
    var pag2="";
    
    const handleClear = () => {
        setTmpValue('');
        setSearchValue('');
    };
        
    const handleSearchClick = () => {
        handleSearch2(tmpValue);
    };

    const handleInputChange = (event) => {
        //setTmpValue(event.target.value);
        const { value, selectionStart } = event.target;
        setTmpValue(event.target.value);   
        requestAnimationFrame(() => {
            const input = document.querySelector('input[placeholder="Cerca"]');
            if (input) {
              input.setSelectionRange(selectionStart, selectionStart);
            }
          });         
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch2(tmpValue);
        }
    };

    const OptionWithIcon = ({ innerProps, label, data }) => (
        <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
              <span>{label}</span>
              <span style={{ marginLeft: '5px', paddingTop: '5px' }}>{data.icon}</span>      
      </div>
    );

    var dateStyle = {
        display: "flex",
        paddingLeft: "40px",
    };
    
    function b64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
        }));
    }

    const handleSelect = (event) => {
        if( properties.verboseLog==1)
            console.log("handleSelect", event);           
        setPage(0);     
        if (event && event.value){
            if( properties.verboseLog==1)
                console.log("HS1",event.value);                       
            setSort(event.value);
            handleSearchWithParams(0,search,event.value);
        }
    }

    const clearAll=()=>{
        setResultData([]);
        setViewResults(false);
        setViewButtons(false);
        setViewError(false);
        setLoading2(false);
    }

    const updatePage = () => {
        if( properties.verboseLog==1)
            console.log("updatePage called");
        pag2=parseInt(page)-10;
        setPage(pag2);        
        setSearchValue(search);
        if( properties.verboseLog==1)
            console.log("PREV",pag2,search);        
        handleSearchWithParams(pag2,search,sort);
    }
    const updatePageNext = () => {
        if( properties.verboseLog==1)
            console.log("updatePageNext called");
        pag2=parseInt(page)+10;
        setPage(pag2);        
        setSearchValue(search);
        if( properties.verboseLog==1)
            console.log("NEXT",pag2,search);        
        handleSearchWithParams(pag2,search,sort);
    }

    const handleSearch2 = (searching) => {
        if( properties.verboseLog==1)
            console.log("handleSearch2", searching);
        setSearchValue(searching);
        //RIPULISCO LA SITUAZIONE DI EVENTUALI PRECEDENTI RICHIESTE
        setResultData([]);
        setViewResults(false);
        setViewButtons(false);
        setViewError(false);
        setLoading2(true);        
        var search21=searching;    
        var qsAnto="?p=0";
        var qsvSort=getQueryStringValue("s");
        if( properties.verboseLog==1)
            console.log("QSVSORT",qsvSort, sort);
        if(qsvSort==undefined||qsvSort==""){            
            setSort("0");
            qsvSort=0;
        }
        
        qsAnto=qsAnto+"&s="+qsvSort;
        qsAnto=qsAnto+"&q="+search21;
        if( properties.verboseLog==1)
            console.log(qsAnto);
        setQueryStringWithoutPageReload(qsAnto);
        if( properties.verboseLog==1)
            console.log("WWWWWWWW", search21);
        var search3=searching;
        if(searching.includes(" ")){
            search3=searching.replaceAll(" ", " +");
            search3="+".concat(search3);
        }
        if( properties.verboseLog==1)
            console.log("handling search2", search3);
        setSearch(searching);

        setPage("0");
        if( properties.verboseLog==1){
            console.log("1",search21);        
            console.log("3",search3);
        }
        let endpoint = properties.productionUrl+properties.productionUrlSuffix +"/core/rest/doSearch.php"; 
        var bodyFormData=new FormData();        
        bodyFormData.append('action', 'searchVerbali');        
        bodyFormData.append('query', b64EncodeUnicode(search3));
        bodyFormData.append('start', "0");
        bodyFormData.append('sort', qsvSort);        
        if (!window.cordova){
          axios({
              method: 'post',
              url: endpoint,
              data: bodyFormData,
              withCredentials: true, 
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (response) {
              if( properties.verboseLog==1)
                console.log("RRR",response);              
                            
              var tm2=response.data;
              tm2=tm2.replaceAll("\n1","");
              tm2=tm2.replaceAll("\n","");           
              var obj=JSON.parse(tm2);
              if( properties.verboseLog==1){
                console.log("RRR3",obj);
                console.log("RRR3",response.data.response);;
                console.log("RRR4",obj.response);
                console.log("RRR5",obj.highlighting);
                console.log("#FOUND",obj.response.numFound);
                console.log("#START",obj.response.start);
              }
              var all=false;
              if(obj.response.start>=10){
                  setViewPrevious(true);
                  all=true;
              }
              else
                  setViewPrevious(false);
              if(obj.response.start>=(obj.response.numFound-10))
                  setViewNext(false);
              else{
                  setViewNext(true);
                  all=true;
              }
              if(all)
                  setViewButtons(true);
              else
                  setViewButtons(false);
              if( properties.verboseLog==1){
                console.log("PRIMO1",obj.response.docs[0]);              
                console.log("RRR4",response.data.highlighting);
              }
              var results = [];
              for (var i = 0; i < obj.response.docs.length; i++) {                
                  var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]);
                  a=a.trim();
                  if( properties.verboseLog==1)
                    console.log("PDPM",a);
                  a=a.replaceAll("],","");
                  a=a.replace(/\\n/g," ");
                  a=a.replace(/[\n\r]/g," ");
                  a=a.replace(/\n/g," ");
                  a=a.replaceAll("{","");
                  a=a.replaceAll("[","");
                  a=a.replaceAll("]","");
                  a=a.replaceAll("}","");
                  a=a.replaceAll(":\"",": ");
                  a=a.replaceAll("\"","");
                  a=a.replaceAll("<em>","<em style='color: #30720F;'>");   

                  var dseduta= obj.response.docs[i].id;
                  dseduta=dseduta.substring(dseduta.indexOf("SedutaDel")+9);
                  dseduta=dseduta.substring(0,dseduta.indexOf("/"));
                  var aaa=obj.response.docs[i].id.substring(obj.response.docs[i].id.lastIndexOf("/")+1);
                  var file=aaa.substring(0,aaa.lastIndexOf("-page"));                        
                  var page=aaa.substring(aaa.lastIndexOf("-page")+5,aaa.lastIndexOf("."));                    
                  var title_a="Verbale della seduta del "+file.substring(9,11)+"/"+file.substring(7,9)+"/"+file.substring(3,7);
                      
                  var eachResult = {
                        denominazione: obj.response.docs[i].id,                       
                        dataSeduta: dseduta,
                        idRichiesta: obj.response.docs[i].IdRichiesta,
                        title: title_a,                        
                        ipotesi: "Pagina "+page,                                                                   
                        matching: a,
                  };
                results.push(eachResult);                    
              }
              if( properties.verboseLog==1)
                console.log("POAT",results);
              setResultData(results);
              setOccurrences(obj.response.numFound);
              setViewResults(true);              
              setLoading2(false);
          })
          .catch(function (response) {
              //toast.error("Si è verificato un errore pubblicando la seduta!");
              if( properties.verboseLog==1)
                console.log("Esticazzi pensa", "ci sia stato un errore");
              setViewError(true);
              setLoading2(false);
          });
        }
        else {
            var onSuccess = function(response){
                if( properties.verboseLog==1)
                    console.log("RRR",response);                           
              var tm2=response;
              tm2=tm2.replaceAll("\n1","");
              tm2=tm2.replaceAll("\n","");
              var obj=JSON.parse(tm2);
              if( properties.verboseLog==1)
                console.log(obj);              
              var all=false;
              if(obj.response.start>=10){
                  setViewPrevious(true);
                  all=true;
              }
              else
                  setViewPrevious(false);
              if(obj.response.start>=(obj.response.numFound-10))
                  setViewNext(false);
              else{
                  setViewNext(true);
                  all=true;
              }
              if(all)
                  setViewButtons(true);
              else
                  setViewButtons(false);
              if( properties.verboseLog==1){ 
                console.log("PRIMO1",obj.response.docs[0]);              
                console.log("RRR4",response.data.highlighting);
              }
              var results = [];
              for (var i = 0; i < obj.response.docs.length; i++) {
                  var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]);
                  a=a.trim();               
                  a=a.replaceAll("],","");
                  a=a.replace(/\\n/g," ");
                  a=a.replace(/[\n\r]/g," ");
                  a=a.replace(/\n/g," ");
                  a=a.replaceAll("{","");
                  a=a.replaceAll("[","");
                  a=a.replaceAll("]","");
                  a=a.replaceAll("}","");
                  a=a.replaceAll(":\"",": ");
                  a=a.replaceAll("\"","");
                  if( properties.verboseLog==1)
                    console.log("WERW",a);
                  var dseduta= obj.response.docs[i].id;
                  dseduta=dseduta.substring(dseduta.indexOf("SedutaDel")+9);
                  dseduta=dseduta.substring(0,dseduta.indexOf("/"));
                  var aaa=obj.response.docs[i].id.substring(obj.response.docs[i].id.lastIndexOf("/")+1);
                  var file=aaa.substring(0,aaa.lastIndexOf("-page"));                           
                  var page=aaa.substring(aaa.lastIndexOf("-page")+5,aaa.lastIndexOf("."));
                
                  var title_a="Verbale della seduta del "+file.substring(9,11)+"/"+file.substring(7,9)+"/"+file.substring(3,7);

                  var eachResult = {
                    denominazione: obj.response.docs[i].id,                                               
                    dataSeduta: dseduta,
                    idRichiesta: obj.response.docs[i].IdRichiesta,
                    title: title_a,                           
                    ipotesi: "Pagina "+page,                                                                            
                    matching: a,
                  };
                      results.push(eachResult);                    
              }
              if( properties.verboseLog==1)
                console.log("POAT1",results);
              setResultData(results);
              setOccurrences(obj.response.numFound);
              setViewResults(true);              
              setLoading2(false);
            }

            var onError = function() {}
            const bodyasString = new URLSearchParams(bodyFormData).toString();
            window.search(bodyasString,onSuccess, onError);
        }
    }


const openPdf = (type, file, page) => {
    var aa=properties.productionUrl+"/fileManager/files/verbali/"
    aa=aa.concat(file);
    if (isMobile) {
        if( properties.verboseLog==1)
            console.log("OPDF","mobile");
        aa=aa.concat("#page").concat(page);
    }
    else{
        if( properties.verboseLog==1)
            console.log("OPDF","not mobile");
        aa=aa.concat("#page=").concat(page);
    }
    if (type === "verbale") {
        window.open(aa);
    }
};


const handleSearchWithParams = (p,q,s) => {
    //RIPULISCO LA SITUAZIONE DI EVENTUALI PRECEDENTI RICHIESTE
    setResultData([]);
    setViewResults(false);
    setViewButtons(false);
    setViewError(false);
    setLoading2(true);
    if( properties.verboseLog==1)
        console.log("handling search with params");
    
    var qsAnto="?p="+p;    
    qsAnto=qsAnto+"&s="+s;    
    qsAnto=qsAnto+"&q="+q;

    var var2=false;
    if(qsAnto!=getQueryString())
        setQueryStringWithoutPageReload(qsAnto);
    
    if( properties.verboseLog==1)    
        console.log("QSANTO",qsAnto, getQueryString(),var2);
    
    if( properties.verboseLog==1)
        console.log("handling search with params",p,q,s);

    var search3=q;
    if(q.includes(" ")){
        search3=q.replaceAll(" ", " +");
        search3="+".concat(search3);
    }
    if( properties.verboseLog==1){
        console.log("handling search2", search3);
        console.log("p vale",p);
    }
    setSearch(q);
    setPage(p);
    if( properties.verboseLog==1)        
        console.log("3",search3);

    let endpoint = properties.productionUrl+properties.productionUrlSuffix +"/core/rest/doSearch.php";     
    var bodyFormData=new FormData();

    bodyFormData.append('action', 'searchVerbali');    
    bodyFormData.append('query', b64EncodeUnicode(search3));    
    bodyFormData.append('start', p); 
    bodyFormData.append('sort', s);
    if (!window.cordova){
      axios({
          method: 'post',
          url: endpoint,
          data: bodyFormData,
          withCredentials: true, 
          headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
          if( properties.verboseLog==1)
            console.log("RRR",response);          
          var tm2=response.data;
          tm2=tm2.replaceAll("\n1","");
          tm2=tm2.replaceAll("\n","");          
          var obj=JSON.parse(tm2);
          if( properties.verboseLog==1){
            console.log("RRR3",obj);
            console.log("RRR3",response.data.response);;
            console.log("RRR4",obj.response);
            console.log("RRR5",obj.highlighting);
            console.log("#FOUND",obj.response.numFound);
            console.log("#START",obj.response.start);
          }
          var all=false;          
          if(obj.response.start>=10){
              setViewPrevious(true);
              all=true;
          }
          else
              setViewPrevious(false);
          if(obj.response.start>=(obj.response.numFound-10))
              setViewNext(false);
          else{
              setViewNext(true);
              all=true;
          }

          if(all)
              setViewButtons(true);
          else
              setViewButtons(false);
          if( properties.verboseLog==1){
            console.log("PRIMO1",obj.response.docs[0]);          
            console.log("RRR4",response.data.highlighting);
          }
          var results = [];
          for (var i = 0; i < obj.response.docs.length; i++) {
              var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]);
                  a=a.trim();                  
                  a=a.replaceAll("],","");
                  a=a.replace(/\\n/g," ");
                  a=a.replace(/[\n\r]/g," ");
                  a=a.replace(/\n/g," ");
                  a=a.replaceAll("{","");
                  a=a.replaceAll("[","");
                  a=a.replaceAll("]","");
                  a=a.replaceAll("}","");
                  a=a.replaceAll(":\"",": ");
                  a=a.replaceAll("\"","");           
                  a=a.replaceAll("<em>","<em style='color: #30720F;'>");   
                  if( properties.verboseLog==1)
                    console.log("WERW",a);
                  var dseduta= obj.response.docs[i].id;
                dseduta=dseduta.substring(dseduta.indexOf("SedutaDel")+9);
                dseduta=dseduta.substring(0,dseduta.indexOf("/"));
                if( properties.verboseLog==1)
                    console.log("32321",dseduta);
                var aaa=obj.response.docs[i].id.substring(obj.response.docs[i].id.lastIndexOf("/")+1);
                var file=aaa.substring(0,aaa.lastIndexOf("-page"));                
                var page=aaa.substring(aaa.lastIndexOf("-page")+5,aaa.lastIndexOf("."));                
                var title_a="Verbale della seduta del "+file.substring(9,11)+"/"+file.substring(7,9)+"/"+file.substring(3,7);
                
                var eachResult = {
                    denominazione: obj.response.docs[i].id,                                
                    dataSeduta: dseduta,
                    idRichiesta: obj.response.docs[i].IdRichiesta,                    
                    ipotesi: "Pagina "+page,                                        
                    matching: a,
                    title: title_a,
                };            
                results.push(eachResult);                  
          }
          if( properties.verboseLog==1)
            console.log("POAT4",results);
          setResultData(results);
          setOccurrences(obj.response.numFound);
          setViewResults(true);          
          setLoading2(false);
      })
      .catch(function (response) {
          //toast.error("Si è verificato un errore pubblicando la seduta!");
          if( properties.verboseLog==1)
            console.log("Esticazzi pensa", "ci sia stato un errore");
          setViewError(true);
          setLoading2(false);
      });
    }
    else {
         var onSuccess = function(response){
           if( properties.verboseLog==1)
                console.log("RRR",response);                     
           var tm2=response;
           tm2=tm2.replaceAll("\n1","");
           tm2=tm2.replaceAll("\n","");           
           var obj=JSON.parse(tm2);           
           if( properties.verboseLog==1){
                console.log("RRR3",obj);
                console.log("RRR3",response.data.response);;
                console.log("RRR4",obj.response);
                console.log("RRR5",obj.highlighting);
                console.log("#FOUND",obj.response.numFound);
                console.log("#START",obj.response.start);
           }
           var all=false;           
           if(obj.response.start>=10){
               setViewPrevious(true);
               all=true;
           }
           else
               setViewPrevious(false);
           if(obj.response.start>=(obj.response.numFound-10))
               setViewNext(false);
           else{
               setViewNext(true);
               all=true;
           }

           if(all)
               setViewButtons(true);
           else
               setViewButtons(false);
            if( properties.verboseLog==1){
                console.log("PRIMO1",obj.response.docs[0]);                      
                console.log("RRR4",response.data.highlighting);
            }
           var results = [];
           for (var i = 0; i < obj.response.docs.length; i++) {
               var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]);
               a=a.trim();                   
               a=a.replaceAll("],","");
               a=a.replace(/\\n/g," ");
               a=a.replace(/[\n\r]/g," ");
               a=a.replace(/\n/g," ");
               a=a.replaceAll("{","");
               a=a.replaceAll("[","");
               a=a.replaceAll("]","");
               a=a.replaceAll("}","");
               a=a.replaceAll(":\"",": ");
               a=a.replaceAll("\"","");                   
               var dseduta= obj.response.docs[i].id;
               dseduta=dseduta.substring(dseduta.indexOf("SedutaDel")+9);
               dseduta=dseduta.substring(0,dseduta.indexOf("/"));
               if( properties.verboseLog==1)
                console.log("32321",dseduta);
               var aaa=obj.response.docs[i].id.substring(obj.response.docs[i].id.lastIndexOf("/")+1);
               var file=aaa.substring(0,aaa.lastIndexOf("-page"));                  
               var page=aaa.substring(aaa.lastIndexOf("-page")+5,aaa.lastIndexOf("."));
               if( properties.verboseLog==1)
                console.log("frei",file);
               var title_a="Verbale della seduta del "+file.substring(9,11)+"/"+file.substring(7,9)+"/"+file.substring(3,7);
               if( properties.verboseLog==1)
                console.log("frei",title_a);
               var eachResult = {
                  denominazione: obj.response.docs[i].id,                                            
                  dataSeduta: dseduta,
                  idRichiesta: obj.response.docs[i].IdRichiesta,                      
                  ipotesi: "Pagina "+page,                                            
                  matching: a,
                  title: title_a,
               };
               results.push(eachResult);                   
           }
           if( properties.verboseLog==1)
                console.log("POAT7",results);
           setResultData(results);
           setOccurrences(obj.response.numFound);
           setViewResults(true);           
           setLoading2(false);
         }

         var onError = function() {}
         const bodyasString = new URLSearchParams(bodyFormData).toString();
         window.search(bodyasString,onSuccess, onError);
    }      
}

useEffect(() => {
        if( properties.verboseLog==1)
            console.log("INTO UE");
        if( !window.cordova){
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();            
            xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
            xhr.withCredentials = true;
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        if( properties.verboseLog==1)
                            console.log("OK",xhr);
                        } else {
                            console.error("Error 1", xhr);
                            localStorage.clear();                    
                            navigate("/login");  
                    }
            }
            };

            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();        
                navigate("/login");  
            };


            let urlEncodedData = null;
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        var pag1=getQueryStringValue("p");
        if(pag1==undefined)
            pag1=0;
        if(pag1!=undefined){            
            pag2=pag1;
        }
        setPage(pag2);

        var s1=getQueryStringValue("s");        
        if(s1==undefined)
            s1=1;        
        if(isNaN(s1)){            
            s1=1;
        }
        setSort(s1);

        var qs1=getQueryStringValue("q");
        if( properties.verboseLog==1){
            console.log("QS1 and S1",qs1, s1);
            console.log("sette",qs1);
        }
        if(qs1!=undefined){
            if(qs1.includes("%20")){
                setSearchValue(qs1.replaceAll("%20"," "));      
                setTmpValue(qs1.replaceAll("%20"," "));                         
                if(isNaN(s1)){
                    if( properties.verboseLog==1)
                        console.log("1- NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1.replaceAll("%20"," "),"1");
                }
                else{
                    if( properties.verboseLog==1)
                        console.log("1- !NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1.replaceAll("%20"," "),s1);
                }
            }
            else {                
                setSearchValue(qs1);
                setTmpValue(qs1);
                if(isNaN(s1)){
                    if( properties.verboseLog==1)
                        console.log("2- NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1,"1");
                }
                else{
                    if( properties.verboseLog==1)
                        console.log("2- !NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1,s1);
                }
            }            
        }
        else{
            if( properties.verboseLog==1)
                console.log("otto",qs1, undefined);
            clearAll();
        }

        const doPost2 = function() {
          if( properties.verboseLog==1)
            console.log("getting info");
          var xhr2 = new XMLHttpRequest();          
          xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/getInfo6.php',true);
          xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
          xhr2.withCredentials = true;
          xhr2.onload = function (e) {
            if (xhr2.readyState === 4) {
              if (xhr2.status === 200) {
                var responseText = xhr2.responseText;
                if( properties.verboseLog==1)
                    console.log("OK LOGIN 33");
                var obj=JSON.parse(responseText);
                var ruolo = obj.Ruolo;

                if(ruolo =="1" ||ruolo=="2"||ruolo=="10"||ruolo=="12"||ruolo=="21"){
                    setViewVerbali(true);
                }
                else
                    setViewVerbali(false);
              } else {
                console.error("Error 13", xhr2.statusText);                
              }
            }
          };

          xhr2.onerror = function (e) {
            console.error("Error 1121", xhr2.statusText);
          };

          let urlEncodedData = null;
          xhr2.send(urlEncodedData);
      }

        if (!window.cordova){
          doPost2();
        }
        else {
           var onSuccess = function(response){
             if( properties.verboseLog==1)
                console.log("OK LOGIN 33");
             var obj=JSON.parse(response);
             var ruolo = obj.Ruolo;

             if(ruolo =="1" ||ruolo=="2"||ruolo=="10"||ruolo=="12"||ruolo=="21"){
                 setViewVerbali(true);
             }
             else
                 setViewVerbali(false);
           }

           var onError = function() {}

           window.getInfo3(onSuccess, onError);
        }

        if (!window.cordova)
        {
          if(localStorage.getItem("ruolo")=="1" ||localStorage.getItem("ruolo")=="2"||localStorage.getItem("ruolo")=="10"||localStorage.getItem("ruolo")=="12"||localStorage.getItem("ruolo")=="21"){
              setViewVerbali(true);
          }
          else
              setViewVerbali(false);
        }
        else {
              setViewVerbali(false);
        }        
        setViewError(false);
        setViewResults(false);
        setViewButtons(false);
        setLoading(false);

    }, []);


function handleChange1(event, role) {
    if( properties.verboseLog==1)
        console.log("T1T2T3",event.target, role);
    var qst=getQueryStringValue("q");
    var sr1=getQueryStringValue("s");
    if( properties.verboseLog==1)
        console.log("QS",);
    if(role==="ve"){
        if(qst!=undefined){            
            navigate("/searchV"+"?p=0&s="+sr1+"&q="+qst);
        }
        else{            
            navigate("/searchV");
        }
    }
    if(role==="ai"){
        if(qst!=undefined){            
            navigate("/search"+"?p=0&s="+sr1+"&q="+qst);
        }
        else{            
            navigate("/search");
        }

    }
    if(role==="pr"){
        if(qst!=undefined){
            navigate("/searchP"+"?p=0&s="+sr1+"&q="+qst);    
        }
        else{            
            navigate("/searchP");
        }

    }

}

    const topicList = (item) => {                
        var aaa=item.denominazione.substring(item.denominazione.lastIndexOf("/")+1);
        if( properties.verboseLog==1)
            console.log("EHI",aaa);
        var file=aaa.substring(0,aaa.lastIndexOf("-page"));        
        file=file.concat(".pdf");
        var page=aaa.substring(aaa.lastIndexOf("-page")+5,aaa.lastIndexOf("."));
        openPdf("verbale",file,page);
        if( properties.verboseLog==1)
            console.log("EHI",file,page);
    };



    const customStyles = {
        control: (provided) => ({
          ...provided,
          border: 'none', 
          boxShadow: 'none', 
          backgroundColor: '#f5f5f5',
          textAlign:'right',
          alignItems:'right',
          //width: '200px',
          width: '100%',
        }),
        indicatorSeparator: () => ({
          display: 'none', 
        }),
        dropdownIndicator: () => ({
          display: 'none', 
        }),
        menu: (provided) => ({
          ...provided,
          width: '200px', 
          //width: '100%', 
          backgroundColor: '#f5f5f5',
          textAlign:'right',
          alignItems:'right',
        }),
        singleValue: (provided) => ({
            ...provided,        
            textAlign: 'right', 
          }),
      };
    
      
      const options = [
        { value: '0', label: 'Pertinenza', icon: <KeyboardArrowDownIcon fontSize="medium"/> },
        { value: '1', label: 'Pertinenza', icon: <KeyboardArrowUpIcon fontSize="medium"/> },
        { value: '2', label: 'Data', icon: <KeyboardArrowDownIcon fontSize="medium"/> },
        { value: '3', label: 'Data', icon: <KeyboardArrowUpIcon fontSize="medium"/> },
      ];
    
      //const defaultValue = options[0];
      const defaultValue = sort;
    
      
      function formatOptionLabel({ label, icon }) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', textAlign:'right' }}>
            <span>{label}</span>
            <span style={{ marginLeft: '5px', paddingTop: '5px' }}>{icon}</span>      
          </div>
        );
      }
      



    return (
        <div key={uuidv4()}>
        {loading ? (
            <div key={uuidv4()} style={{ width:"25%",   display: "flex",
            marginLeft: "auto", marginRight: "auto" }}>
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div>
        ):(
        <div className="content" >
       <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <TextField
                value={tmpValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Cerca"
                variant="outlined"
                autoFocus
                sx={{
                    width: '100%',
                    maxWidth: 600,
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '24px',
                        paddingRight: '10px',
                        paddingLeft: '16px',
                        backgroundColor: 'white',
                        boxShadow: 'none',  
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ddd',                            
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent', 
                        },
                        '&.Mui-focused': {
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
                           
                        },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ddd',
                    },
                    '& .MuiInputBase-input': {
                        padding: '10px 0',
                    },
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={searchValue ? handleClear : handleSearchClick}
                                aria-label={searchValue ? 'clear' : 'search'}
                            >
                                {searchValue ? <ClearIcon /> : <SearchIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>

  {viewVerbali ?(
      <div style={{alignItems: "left", paddingTop:"15px",paddingLeft:"10px", textAlign:"left", margin: '0 auto', maxWidth: 600}} >
          <FormControl >
                {/*<FormLabel id="demo-radio-buttons-group-label">Supporto di ricerca</FormLabel>*/}
                <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="verbali"
                        name="radio-buttons-group">
                    <FormControlLabel value="json" onChange={(event) => handleChange1(event, "ai")} control={<Radio size="small" style ={{color: "#30720f"}}/>} label=
                     {<Typography style={{fontSize: "14px"}}> Attività Istituzionale</Typography>} >
                                            </FormControlLabel>
                    <FormControlLabel value="premesse" onChange={(event) => handleChange1(event, "pr")} control={<Radio size="small" style ={{color: "#30720f"}}/>} label=
                     {<Typography style={{fontSize: "14px"}}> Documenti di supporto (Attività Istituzionale)</Typography>}>
                                            </FormControlLabel>
                    <FormControlLabel value="verbali" onChange={(event) => handleChange1(event, "ve")} control={<Radio  size="small" style ={{color: "#30720f"}}/>} label=
                    {<Typography style={{fontSize: "14px"}}> Verbali</Typography>}>
                    </FormControlLabel>
                </RadioGroup>
            </FormControl>
      </div>
    ):(
       <></>
    )
  }
 {loading2 ? (
    <div style={{marginRight: "20px",}}>
   <CircularProgress size={42} style={{color: '#30710F'}}  sx={{  position: 'absolute',top: 0, left: -6, zIndex: 1,}}/>
   </div>
 ):( <></> )}

  {viewError ?(
      <div style={{alignItems: "left", paddingTop:"5px",paddingLeft:"5px", textAlign:"left", margin: '0 auto', maxWidth: 600}} >
           <div className="content"  >
          <p> La ricerca di {search} non ha prodotto risultati</p>
          </div>
      </div>
    ):(
    <></>
    )
  }
  {viewResults ?(
      <div style={{alignItems: "left", paddingTop:"5px",paddingLeft:"5px", textAlign:"left", margin: '0 auto'}} >

          <div className="content" style={{paddingBottom:"0px", paddingLeft:"0px", paddingRight:"0px"}} >
          <div style={{display: "flex", paddingLeft:"0px", textAlign:"left", margin: '0 auto'}} >
          <p> Risultati relativi a {search} (Circa {occurrences} risultati)</p>
          {toBeRemoved ?(
          <div style={{ paddingTop:"6px", textAlign:"right", marginLeft: "auto", marginRight: "0"}} >
            <Select styles={customStyles} 
                id="select01"
                isSearchable={false} 
                blurInputOnSelect
                options={options}
                defaultValue ={options[getQueryStringValue("s")]}
                components={{
                    Option: OptionWithIcon,
                  }}            
                formatOptionLabel={formatOptionLabel}    
                onChange={(event) => handleSelect(event)}
            />
            </div>
          ):(<></>)}
        </div>
          {resultData.map((item, index) => (
                <div
                    className="common-list"
                    key={index}
                    style={{
                                cursor: "pointer",
                            }}
                    onClick={() => topicList(item)}
                >

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} key={uuidv4()} >
                <div style={{ paddingLeft: "30px", textAlign: "left", paddingRight:"20px"}} key={uuidv4()}>
                            <div className= "textBlackSedute" style={{ paddingRight: "10px",paddingLeft: "10px",paddingTop: "20px", paddingBottom:"10px", fontWeight: "bold"}} key={uuidv4()}>
                                <label key={uuidv4()} style={{cursor: "pointer"}}>
                                {item.title} -- {" "}
                                </label>
                                    <span className= "textBlackSedute">
                                        {item.ipotesi}
                                    </span>
                            </div>
                            <div className= "textBlackSedute" style={{ paddingRight: "10px",paddingLeft: "10px",paddingTop: "10px", paddingBottom:"20px"}} key={uuidv4()}>
                                <label key={uuidv4()} style={{cursor: "pointer"}}>
                                            Seduta del{" "}
                                            <span className= "textBlackSedute" dangerouslySetInnerHTML={{
                                                __html: item.dataSeduta +" -- "+ item.matching,}}/>
                                </label>
                                </div>
                </div>
                    </div>
                </div>
            ))}
        </div>
      </div>
    ):(
    <></>
    )
  }
{viewButtons ?(
    <div style={{
        display: "flex",
        alignItems: "center",
        width:"25%",
        marginLeft: "auto", marginRight: "auto",
        paddingTop:"0px", marginTop:"0px"
     }}>
       {viewPrevious ?(
        <div  onClick={updatePage}>
        <p style={{ cursor: "pointer", color: "#000000", paddingRight:"20px" }}>
           Precedenti
        </p>
        </div>
       ):(<></>)}

        <div  >
        <p  style={{ color: "#000000" }}>
        Risultati
        </p>
        </div>

       {viewNext ?(
        <div onClick={updatePageNext}>
        <p  style={{ cursor: "pointer", color: "#000000", paddingLeft:"20px" }}>
           Successivi
        </p>
        </div>
       ):(<></>)}
    </div>
    ):(
    <></>
    )
  }

    </div>

    )}</div>
    );
}
