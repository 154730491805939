import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";
import {properties} from "../../utils/properties";
import { useNavigate } from "react-router-dom";

const Root = styled('div')({
    background: "#f5f5f5",
    padding: "30px 5%",
    minHeight: "calc(100vh - 136px)",
  });
  
  const Heading = styled('h1')(({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    flexBasis: "100%",
    flexShrink: 0,
  }));
  
  const SecondaryHeading = styled('h2')(({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.text.secondary,
  }));


export default function ScoreBoard() {
    //path="/fascicoli/:idDossier/proposteintervento/schede/:id/scoreboard"
    let { idDossier,id } = useParams();    
    const [expanded, setExpanded] = React.useState(false);
    const [scoreBoard, setScoreBoard] = useState([]);    
    const navigate = useNavigate();
    const { pageRouter, setPageRouter } = useContext(RouterContext);    
    const meetingId = idDossier;

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 300 : 700],
        '& .MuiLinearProgress-bar': {
        borderRadius: 5,
        backgroundColor: '#5cb85c',
        },
    }));

    const updateUI = function(jsonData) {                 
        const keyValuScores = Object.entries(jsonData)[4][1];
        var subScores = [];
        for (var i = 0; i < keyValuScores[id].scoreboard.scores.length; i++) {
            var subScore = [];
            var parentScoreName =
                keyValuScores[id].scoreboard.scores[i]["items1Level"][0][
                    "label"
                ];
            var parentScoreMark =
                keyValuScores[id].scoreboard.scores[i]["items1Level"][0][
                    "number"
                ];
            var childScores = [];
            for (var j = 0;j < keyValuScores[id].scoreboard.scores[i]["items2Level"].length;j++) {
                var childScore = {
                    childScoreName:
                        keyValuScores[id].scoreboard.scores[i]["items2Level"][j]["label"],
                    childScoreMark:
                        keyValuScores[id].scoreboard.scores[i]["items2Level"][j]["number"],
                };
                childScores.push(childScore);
            }
            subScore = {
                parentScoreName: parentScoreName,
                parentScoreMark: parentScoreMark,
                child: childScores,
            };
            subScores.push(subScore);
        }
        setScoreBoard(subScores);
    }

     const doPostSeduta2 = function() {
        if( properties.verboseLog==1)
            console.log("doing sedute post");
        var xhr2 = new XMLHttpRequest();                
        
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSeduta.php',true);        
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.withCredentials = true;
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    if( properties.verboseLog==1)
                        console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                        console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        console.log("OK SCOREBOARD 204",xhr2);  
                        const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));
                        updateUI(MeetingInfo);
                    }              
                    else{
                        //status 201
                        console.log("OK SCOREBOARD 201",xhr2);  
                        try{
                            localStorage.setItem("meetingContent", obj.content);
                            localStorage.setItem("meeting", meetingId);
                            localStorage.setItem("meetingVersion", obj.date);                                                                                                                           
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        const MeetingInfo = JSON.parse(obj.content);
                        updateUI(MeetingInfo);
                    }
                } 
                else {
                    console.error("Error 1", xhr2.statusText);          
                    alert("Errore recuperando la seduta");  
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
    
        let urlEncodedData = "idf=" + meetingId;
        if (localStorage.meeting===meetingId){                        
            const currentData=localStorage.getItem("meetingVersion");        
            urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
        xhr2.send(urlEncodedData);
    }


    useEffect(() => {
        if(!window.cordova){            
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                        
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                    
                    if( properties.verboseLog==1)
                        console.log("OK",xhr);
                    updateSeduta(meetingId,doPostSeduta2,updateUI);
                } else {
                    console.error("Error 1", xhr);      
                    localStorage.clear();                                 
                    navigate("/login");  
                }
            }
        };
    
        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();    
          navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }

        if (window.cordova){
            updateSeduta(meetingId,doPostSeduta2,updateUI);
        }
        
    }, []);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Root>
            {scoreBoard.map((item, index) => (
                <Accordion
                    expanded={expanded === item.parentScoreName}
                    onChange={handleChange(item.parentScoreName)}
                    key={index}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Heading >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alingItems: "center",
                                }}
                            >
                                <p  style={{  fontWeight: "normal"}} className="text">{item.parentScoreName}</p>
                                <p style={{  fontWeight: "normal"}} className="text">{item.parentScoreMark}/10</p>
                            </div>
                            <BorderLinearProgress
                                variant="determinate"
                                value={item.parentScoreMark * 10}
                            />
                        </Heading>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography >
                            <p  style={{  fontWeight: "normal", paddingLeft:"50px", textAlign:"left"}} >di cui:</p>
                            {item.child.map((subitem, index) => (
                                <div key={index} style={{ paddingLeft:"50px"}}  >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alingItems: "center",                                            
                                        }}
                                    >
                                        <p className="text">{subitem.childScoreName}</p>
                                        <p className="text">{subitem.childScoreMark}/10</p>
                                    </div>
                                    <BorderLinearProgress                                        
                                        variant="determinate"
                                        value={subitem.childScoreMark * 10}
                                    />
                                </div>
                            ))}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Root>
    );
}
