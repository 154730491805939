import React, { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import axios from 'axios';
import "../../App.css";
import {properties} from "../../utils/properties";
import { useNavigate } from "react-router-dom";

export default function Init() {

  const navigate = useNavigate();
  const [loading, setLoading]= React.useState(true); 
  

  useEffect(() => {
    if(!window.cordova){            
    //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                        
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix+'/core/rest/ping.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                                        
                    if( properties.verboseLog==1)
                      console.log("OK",xhr);
                    //IF PREVIEWERS                    
                    var error=false;
                    var lastId=undefined;
                    var newUI=false;
                    var isPreviewer=false;
                    var isConsiglio=false;
                    if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null || localStorage.getItem("new_ui")=== "false"){        
                      newUI=false;
                    }
                    else{
                      newUI=true;
                    }
                    if(localStorage.getItem("ruolo")=='20'){
                      isConsiglio=true;                      
                    }

                    if(isConsiglio){
                      if( properties.verboseLog==1)
                        console.log("consiglio only");              
                      navigate("/consiglio",{ replace: true });  
                    }
                    else{                    
                      if( properties.verboseLog==1)
                        console.log("After");
                      if(localStorage.getItem("ruolo")=='15'|| localStorage.getItem("ruolo")=='12'||localStorage.getItem("ruolo")=='10'||
                        localStorage.getItem("ruolo")=='5'||localStorage.getItem("ruolo")=='4'||localStorage.getItem("ruolo")=='1'){
                        isPreviewer=true;      
                    }                    
                    if( properties.verboseLog==1)
                      console.log("Info:"+ newUI+ " "+isPreviewer);    
                    
                    if(isPreviewer){                      
                        if( properties.verboseLog==1)
                          console.log("Info2: Inside isPReviewer");    
                        axios.get(properties.productionUrl+properties.productionUrlSuffix+'/core/rest/getLast.php?edited', {withCredentials: true})
                        .then(res => {                      
                          if(res.data==undefined){                      
                            error=true;
                          }
                          if(res.data=="\n\n" || res.data==""){                        
                            error=true;
                          }
                          if(!error){
                            lastId = res.data;
                          }                    
                          if( properties.verboseLog==1)
                            console.log("Fascicolo",error, lastId, newUI);
                          if(lastId != undefined) {
                            //c'è un fascicolo in preview
                            if(newUI){                              
                              navigate("/fascicoli/"+lastId+"/summary",{ replace: true });  
                            }
                            else{                              
                                navigate("/fascicoli/"+lastId,{ replace: true });  
                            }
                          }
                          else{ 
                            if(newUI){                              
                                  navigate("/calendar",{ replace: true });  
                            }
                            else{                              
                                  navigate("/fascicoli",{ replace: true });  
                            }          
                          }                                                            
                      }).catch(function (res) {            
                        console.log("Errore 77",res);
                        if(newUI){                      
                              navigate("/calendar",{ replace: true });  
                        }
                        else{                          
                              navigate("/fascicoli",{ replace: true });  
                        }          
                      });
                    }
                    //FINE IF PREVIEWERS
                    else{
                        if(localStorage.getItem("new_ui")==="true"){                            
                                navigate("/calendar",{ replace: true });  
                        }
                        else{                            
                              navigate("/fascicoli",{ replace: true });  
                        }    
                    }
              }//ELSE CONSIGLIO                
            } else {
                console.error("Error 17", xhr); 
                localStorage.clear();                                   
                navigate("/login");
            }
        }
        };

        xhr.onerror = function (e) {
            console.error("Error 27", xhr);
            localStorage.clear();            
            navigate("/login");
        };
         
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
    //CHECK AUTHENTICATION
    }
    
    if (window.cordova) {
          var onSuccess = function(response)
          {
            if( properties.verboseLog==1)
              console.log("OK LOGIN 33");
            var obj=JSON.parse(response);
            var ruolo = obj.Ruolo;

            var error=false;
            var lastId=undefined;
            var newUI=false;
            var isPreviewer=false;
            var isConsiglio=false;
            if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null || localStorage.getItem("new_ui")=== "false"){        
              newUI=false;
            }
            else{
              newUI=true;
            }
            if(ruolo=='20'){
              isConsiglio=true;                      
            }

            if(isConsiglio){
              if( properties.verboseLog==1)
                console.log("consiglio only");              
              navigate("/consiglio",{ replace: true });  
            }
            else{      
                if(localStorage.getItem("new_ui")==="true"){                    
                        navigate("/calendar",{ replace: true });  
                }
                else{                    
                    navigate("/fascicoli",{ replace: true });  
                }    
            }
        }

          var onError = function() {}

          window.getInfo3(onSuccess, onError);
    }
              
    
},
   []);


  return (
    <div key={uuidv4()}> 
    {loading ? (
      <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
      marginLeft: "auto", marginRight: "auto" }}> 
       <ThreeDots color="#30720f" height='350' width='150'/>
       </div> 
      ):(
        <></>)
    }         
    </div>
  );
  
}