import React from 'react'

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const ClassificationMenu = (props) => {
  const { handleChange } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary="Classifica" />
      </ListItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          styles={{  maxWidth: "360px", minWidth: "150px"}}
        >
          <ListItem button onClick={() => {handleClose(); handleChange("blue")}}>
            <FiberManualRecordIcon  style={{color: "#C2EFFF"}} />
            <ListItemText primary="Verbali" />
          </ListItem>
          <ListItem button onClick={() => {handleClose(); handleChange("yellow")}}>
            <FiberManualRecordIcon style={{color: "#FFE118"}} />
            <ListItemText primary="Comunicazioni" />
          </ListItem>
          <ListItem button onClick={() => { handleClose(); handleChange('red')}}>
            <FiberManualRecordIcon style={{color: "#E80000"}} />
            <ListItemText primary="Attività Istituzionali" />
          </ListItem>
          <ListItem button onClick={() =>{handleClose(); handleChange("green")}}>
            <FiberManualRecordIcon style={{color: "#00CC33"}} />
            <ListItemText primary="Investimenti Patrimoniali" />
          </ListItem>
          <ListItem button onClick={() => {handleClose(); handleChange("orange")}}>
            <FiberManualRecordIcon style={{color: "#E98624"}} />
            <ListItemText primary="Personale" />
          </ListItem>
          <ListItem button onClick={() => {handleClose(); handleChange("purple")}}>
            <FiberManualRecordIcon style={{color: "#9370db"}} />
            <ListItemText primary="Attività di Controllo Periodico" />
          </ListItem>
          <ListItem button onClick={() => {handleClose(); handleChange("brown")}}>
            <FiberManualRecordIcon style={{color: "#8b4513"}} />
            <ListItemText primary="Varie" />
          </ListItem>  
          <ListItem button onClick={() => {handleClose(); handleChange("std")}}>
            <FiberManualRecordIcon style={{color: "#CCCCCC"}} />
            <ListItemText primary="Predefinito" />
          </ListItem>                  
          <ListItem button onClick={() => {handleClose(); handleChange("hidden")}}>
            <FiberManualRecordIcon style={{color: "#444444"}} />
            <ListItemText primary="Nascosto" />
          </ListItem>          
        </List>
      </Popover>
    </>
  )
}

export default ClassificationMenu
