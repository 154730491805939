import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";
import {properties} from "../../utils/properties";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { v4 as uuidv4 } from 'uuid';

export default function TopicDeepList() {
    let {idDossier, indexSector, indexSubSector} = useParams();    
    const [listData, setListData] = useState([]);   
    let id=idDossier;    
    let index;
    let index2;    
    let [attIst, setAttIst] = useState(0);
    let [versionOpt, setVersionOpt] = useState(7);    
    const { pageRouter, setPageRouter } = useContext(RouterContext);     
    const navigate = useNavigate();
    const meetingId = idDossier;

    const updateUI = function(jsonData) {       
        if( properties.verboseLog==1)
            console.log("OK33", jsonData);                     
        var keyJsonData;
        var entireSpecificList;
        let optimizerVersion=0;

        const temp1= Object.entries(jsonData); 

        temp1.forEach( entry => {
                let key= entry[0];
                let value= entry[1];
                if( properties.verboseLog==1){
                    console.log("OK332",key);
                    console.log("OK333",value);
                }
                if(key==='fileSectors') {                                        
                    keyJsonData=value;
                    if( properties.verboseLog==1)
                        console.log("qq-2",entry);
                }                
                if(key==='sectors') {                                        
                    entireSpecificList=value;
                    if( properties.verboseLog==1)
                        console.log("qq-2",entry);
                }   
                if (key==='version'){
                    if( properties.verboseLog==1)
                        console.log("Version", value);
                    optimizerVersion=value;                    
                    setVersionOpt(value);
                }             
        }); 
        if( properties.verboseLog==1)
            console.log("OK334",keyJsonData);
        
        const valueJsonData = Object.entries(keyJsonData);             
        if( properties.verboseLog==1)
            console.log("OK335", valueJsonData);
        valueJsonData.forEach( (entry, index1) => {
                let key= entry[0];            
                if(key===indexSector) {                                        
                    index=index1;
                    if( properties.verboseLog==1)
                        console.log("OK336",entry, index);
                }                
        }); 

        const valueSpecificList = Object.entries(entireSpecificList);        
        if( properties.verboseLog==1)
            console.log("OK337",valueSpecificList);
        const subSection= valueJsonData[index][1]["cardIds"];
        subSection.forEach( (entry, index1) => {            
            let key= entry.item_id;
            if( properties.verboseLog==1)
                console.log("OK338", index1, entry, key,indexSubSector);
            if(key===indexSubSector) {                                        
                    index2=index1;
                    if( properties.verboseLog==1)
                        console.log("OK339",entry, index);
                }                
        });         
        var item_card_id = valueJsonData[index][1]["cardIds"][index2].item_id;
        if( properties.verboseLog==1)
            console.log("OK340",item_card_id);                
        var item_container_id = [];
        if( properties.verboseLog==1)
            console.log("OK320",Object.entries(valueJsonData[index][1]["containers"]).length, Object.entries(valueJsonData[index][1]["containers"]));
        for (var i = 0; i < Object.entries(valueJsonData[index][1]["containers"]).length; i++) {
            var item = Object.entries(valueJsonData[index][1]["containers"])[i];
            var item_id = item[0];
            if( properties.verboseLog==1){
                console.log("319", item_id, item_card_id);
                console.log("ZZZZZZZZZZZZZZZZZZZZZZZ", item[1]["cardIds"].length, Object.entries(valueJsonData[index][1]["containers"]).length);
                console.log("YYYYYYYYYYYYYYYYYYYYYYY", i);
            }
            if (item_card_id === item_id) {
                if( properties.verboseLog==1)
                    console.log("341", item, i, );
                if(item[0]=== "../../proposteintervento/"){
                    setAttIst(1);
                    attIst=1;
                }
                else{                    
                    for ( var i2 = 0; i2< item[1]["cardIds"].length; i2++) {                    
                        item_container_id.push(item[1]["cardIds"][i2].item_id);
                        if( properties.verboseLog==1)
                            console.log("OK342", item_container_id[i2]) ;
                    }                    
               }
            }
        }
        if( properties.verboseLog==1)
            console.log("ATTIST",attIst);
        if(attIst===0){
            var arrayOk=[];                                  
            for ( var j = 0; j < item_container_id.length; j++) {  
                for ( var i = Object.entries(valueJsonData[index][1]["premesse"]).length-1; i>=0; i--) {
                var item1 = Object.entries(valueJsonData[index][1]["premesse"])[i];
                if( properties.verboseLog==1)
                    console.log("FREE10",item1, item);
                var item_premesse_id = item1[0];
                if( properties.verboseLog==1)
                    console.log("44",item_premesse_id, item_container_id);
                
                if (item_container_id[j] === item_premesse_id) {
                        if( properties.verboseLog==1)
                            console.log("46",item1[1]);
                        let temp_item = {
                            title: "",
                            url: "",
                            color: "",
                            };
                        if( properties.verboseLog==1)   
                            console.log("QWER",item);
                        temp_item.title = item1[1].label;
                        if( properties.verboseLog==1)
                            console.log("46_1",item1[1].label);
                        temp_item.url = item1[1].url;
                        if( properties.verboseLog==1)
                            console.log("46_2",item1[1].url);
                        temp_item.color = valueJsonData[index][1]["color"];
                        if( properties.verboseLog==1){
                            console.log("46_3",valueJsonData[index]);
                            console.log("QWERDIO",temp_item);
                        }
                        arrayOk.push(temp_item);
                    }
                }
            }
            if( properties.verboseLog==1)
                console.log("FREE-TOD",arrayOk);            
            setListData(arrayOk);            
        }
        else {
            //ATTIVITA ISTITUZIONALI
            if( properties.verboseLog==1){
                console.log("else");
                console.log("EERF-DOT",arrayOk);
            }
            var arrItem = [];
            //POSSO DIFFERENZIARE IN BASE ALLA VERSIONE <7 si tratta di vecchi settori 
                    //NUOVO
            if(optimizerVersion!=0){
                if( properties.verboseLog==1)
                    console.log("NUOVI OBIETTIVI");
                for (var i = 0; i < valueSpecificList.length; i++) {
                    if( properties.verboseLog==1)
                        console.log("valueSpecificList",valueSpecificList[i]);
                    var item = [];
                    if (valueSpecificList[i][1].hasOwnProperty("macro")) {
                        if( properties.verboseLog==1)
                            console.log("valueSpecificList if");
                    //Si tratta di Cultura, Persone e Pianeta
                    //if (valueSpecificList[i].macro === "macro") {
                        if ((valueSpecificList[i][1].macro === "macro")||(valueSpecificList[i][1].macro === "in_evidenza")||(valueSpecificList[i][1].macro === "direzione")||(valueSpecificList[i][1].macro === "ai")) {    
                            item = {
                                label: valueSpecificList[i][1].label,                    
                                numero_pratiche_totali:
                                valueSpecificList[i][1].numero_pratiche_totali, 
                                totale_complessivo:
                                valueSpecificList[i][1].totale_complessivo,
                                numero_pratiche: valueSpecificList[i][1].numero_pratiche,
                                no: i,
                                color: valueSpecificList[i][1].color,
                                macro: valueSpecificList[i][1].macro,
                                cardIds: valueSpecificList[i][1].cardIds,
                                containsMinigrant:
                                valueSpecificList[i][1].containsMinigrant,
                                ref: valueSpecificList[i][0],
                            };
                            if( properties.verboseLog==1)
                                console.log("item1",item); 
                            } else {                    
                                if( properties.verboseLog==1)
                                    console.log("valueSpecificList else");
                                item = {
                                    no: i,
                                    label: valueSpecificList[i][1].label,
                                    sub_cnt: valueSpecificList[i][1].cardIds.length,
                                    numero_pratiche: valueSpecificList[i][1].numero_pratiche,
                                    macro: valueSpecificList[i][1].macro,
                                    color: valueSpecificList[i][1].color,
                                    ref: valueSpecificList[i][0],
                                };
                            if( properties.verboseLog==1)
                                console.log("item2",item);
                            }
                            if( properties.verboseLog==1)
                                console.log("item",item);
                        arrItem.push(item);
                    }
                }

            }
            else{
                if( properties.verboseLog==1)
                    console.log("VECCHI SETTORI");
                for (var i = 0; i < valueSpecificList.length; i++) {
                    var item = [];                     
                    item = {                            
                            label: valueSpecificList[i][1].label,                    
                            numero_pratiche_totali:
                            valueSpecificList[i][1].numero_pratiche_totali, 
                            totale_complessivo:
                            valueSpecificList[i][1].totale_complessivo,
                            numero_pratiche: valueSpecificList[i][1].numero_pratiche,
                            no: i,
                            color: valueSpecificList[i][1].color,
                            //macro: valueSpecificList[i][1].macro,
                            cardIds: valueSpecificList[i][1].cardIds,
                            containsMinigrant:
                            valueSpecificList[i][1].containsMinigrant,
                            ref: valueSpecificList[i][0],
                        };
                        arrItem.push(item);
                    }                      
            }
            
            if( properties.verboseLog==1)
                console.log("array",arrItem);
            setListData(arrItem);            
    }
}



    const doPostSeduta2 = function() {
        if( properties.verboseLog==1)
            console.log("doing sedute post");
        var xhr2 = new XMLHttpRequest();                
        
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSedutaCGFinanza.php',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.withCredentials = true;        
        xhr2.onload = function (e) {      
        if (xhr2.readyState === 4) {
            if (xhr2.status === 200) {                              
                if( properties.verboseLog==1)
                    console.log("OK92",xhr2);
                var responseText = xhr2.responseText;                    
                var obj=JSON.parse(responseText);
                if( properties.verboseLog==1)
                    console.log("OK93",obj);          
                if(obj.status==="204" ){ 
                    console.log("OK TOPICDEEPLISTCGFINANZA 204",xhr2);    
                    const MeetingInfo = JSON.parse(localStorage.getItem("meetingContentCGFinanza"));
                    if( properties.verboseLog==1)
                        console.log("ook", MeetingInfo);
                    updateUI(MeetingInfo);
                }              
                else{
                    //status 201
                    console.log("OK TOPICDEEPLISTCGFINANZA 201",xhr2);    
                    try{
                        localStorage.setItem("meetingContentCGFinanza", obj.content);
                        localStorage.setItem("meetingCGFinanza", meetingId);
                        localStorage.setItem("meetingVersionCGFinanza", obj.date);                                                                                                                                                                                                                       
                    }
                    catch(error) {
                        console.log("LOCALSTORAGE ISSUE", error);
                    }   
                
                    const MeetingInfo = JSON.parse(obj.content);
                    updateUI(MeetingInfo);
                }
            } 
            else {
                console.error("Error 1", xhr2.statusText);          
                alert("Errore recuperando la seduta");  
            }
        }
    };

    xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = "idf=" + meetingId;
    if (localStorage.meetingCGFinanza===meetingId){            
        if( properties.verboseLog==1)
            console.log( "QUINDICI00");
        const currentData=localStorage.getItem("meetingVersionCGFinanza");        
        urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
    xhr2.send(urlEncodedData);
}



    useEffect(() => {
        if( properties.verboseLog==1)
            console.log("TDL",idDossier, indexSector, indexSubSector);
        if(indexSubSector==="ai")
            indexSubSector="../../proposteintervento/";
        if( !window.cordova){
            if( properties.verboseLog==1)
                console.log ("2");
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                
        
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                    
                    if( properties.verboseLog==1)
                        console.log("OK",xhr);        
                    updateSeduta(meetingId,doPostSeduta2,updateUI);            
                } else {
                    console.error("Error 1", xhr);   
                    localStorage.clear();                                     
                    navigate("/login");  
                }
            }
        };
    
        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();          
          navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
    
        if (window.cordova){
            updateSeduta("CGFinanza/" + meetingId,doPostSeduta2,updateUI);
        }
                
    }, []);

    const openPdf = (url) => {        
        window.open(url);
    };

    const subArgument = (index, macro, label, cardIds,item) => {
        if( properties.verboseLog==1){
            console.log("2200", item);
            console.log("2200", item.ref);
        }
        if(versionOpt!=0){
            if( properties.verboseLog==1)
                console.log("Versione non 0", versionOpt);
            if (macro === "macro") {                
               navigate("/cg_finanza/" + meetingId + "/proposteintervento/"+ item.ref);
            } else {                
                navigate("/cg_finanza/" + meetingId + "/proposteintervento/"+ item.ref);
            }
        }
        else{
            if( properties.verboseLog==1)
                console.log("Versione 0", "provo a saltare un pezzo");
            navigate("/cg_finanza/" + meetingId + "/proposteintervento/missioni/"+ item.ref);
        }
    };


    return (        
        <div className="content">
            { attIst===0  ? (             
            <div>
                  {listData.map((item, index) => (    
            <div key={uuidv4()} className="common-list" style={{ position: "relative", cursor: "pointer", }} onClick={() => openPdf(item.url)}>                
                <p className="textBlackSedute" 
                    style={{ paddingLeft: "30px" }}>
                    {item.title}
                </p>
                <div
                    style={{
                        color: "#879497",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            paddingRight: "25px",
                            color: "#879497",
                            display: "flex",
                        }}
                    >
                       
                    <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>
                    </div>
                    <div
                        style={{
                            marginLeft: "10px",
                            width: "10px",
                            height: "63px",
                            background: item.color,
                            borderBottomRightRadius: "8px",
                            borderTopRightRadius: "8px",
                        }}
                    ></div>
                </div>
            </div> ))}
            </div>
            ): (                
                <div>
                  {listData.map((item, index) => (
                <div
                    className="common-list"
                    style={{ position: "relative", cursor: "pointer", }}
                    key={index}
                    onClick={() =>
                        subArgument(
                            item.no,
                            item.macro,
                            item.label,
                            item.cardIds,
                            item
                        )
                    }
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",                            
                            alignItems: "flex-start",
                            paddingLeft: "30px",
                        }}
                    >
                        <p className= "textBlackSedute"
                            style={{
                                marginLeft: "10px",                
                            }}
                        >
                            {item.label}
                        </p>                         
                        {item.numero_pratiche_totali ? (
                            <p
                                className= "textGreySedute"
                                style={{ 
                                    margin: "0px 0px 20px 10px",
                                }}
                            >
                                Numero Pratiche:{" "}
                                <span className= "textBlackSedute">
                                    {item.numero_pratiche_totali}
                                </span>
                            </p>
                        ) : (
                            <></>
                        )}
                        {item.totale_complessivo ? (
                            <p className= "textGreySedute"
                                style={{ 
                                    marginLeft: "10px",
                                    marginBottom: "20px",
                                    marginTop: "0px",
                                }}
                            >
                                Totale Complessivo:{" "}
                                <span className= "textBlackSedute">
                                    {item.totale_complessivo}
                                </span>
                            </p>
                        ) : (
                            <></>
                        )}
                        {item.containsMinigrant ? (
                            <p className= "textGreySedute"
                                style={{
                                    margin: "0px 0px 20px 10px",
                                }}
                            >
                                {item.containsMinigrant}
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div
                        style={{
                            color: "#879497",
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            height: "100%",
                            right: "0px",
                        }}
                    >
                        <div
                            style={{
                                paddingRight: "25px",
                                color: "#879497",
                                display: "flex",
                            }}
                        >
                           <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>
                            
                        </div>
                        <div
                            style={{
                                marginLeft: "10px",
                                width: "10px",
                                height: "100%",
                                background: item.color,
                                borderBottomRightRadius: "8px",
                                borderTopRightRadius: "8px",
                            }}
                        ></div>
                    </div>
                </div>
            ))}
                </div>

                )}
        </div>
    );
}
