import React, { useState, useMemo, useEffect, useContext } from "react";
import {  useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import * as Icons from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { FormControlLabel, Checkbox,TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Table as MaUTable, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from '@mui/material';
import { useTable } from 'react-table'
import Select from 'react-select';
import {ThreeDots} from 'react-loader-spinner';
import {properties} from "../../utils/properties";
import ContentEditable from 'react-contenteditable';
import { useNavigate } from "react-router-dom";

export default function SedutaRaw() {    
    let { idDossier } = useParams();
    var idCard;
    const [data, setData] = useState([]);    
    const [schedeList, setSchedeList] = useState([]);
    const [somethingChange, setSomethingChange]= useState(false);   
    const [selectedItem, setSelectedItem]= useState("");   

    const navigate = useNavigate();
    const { pageRouter, setPageRouter } = useContext(RouterContext);    
    const meetingId = idDossier ;
    const [loading, setLoading]= React.useState(true);    //THREE DOTS SPINNER
    
    const obiettiviValues =[  
        {value: "01", label:"PERSONE"},  
        {value: "02", label:"CULTURA"},  
        {value: "03", label:"PIANETA"},  
        {value: "04", label:"DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE"},  
        {value: "05", label:"DIREZIONE INNOVAZIONE DI IMPATTO"},
        {value: "06", label:"ALTRO"}
    ];
   
    const EditableCell = ({
      value: initialValue,
      row: { index },
      column: { id },
      updateMyData, 
    }) => {
      const [value, setValue] = React.useState(initialValue)
      const onChange = e => {
        setValue(e.target.value)
      }
      const onBlur = () => {
        updateMyData(index, id, value)
      }

      const onKeyDown= event => {
        handleKeyPress(event)
      }

      React.useEffect(() => {
        setValue(initialValue)
      }, [initialValue])

      return <input value={value} onChange={onChange} onBlur={onBlur} onKeyDown={onKeyDown}/>
    }

    function handleKeyPress(event){
      if(event.keyCode === 13){
      event.target.blur();       
      }
    }

    const defaultColumn = {
      Cell: EditableCell,
    }

    const disableNewlines = event => {
      const keyCode = event.keyCode || event.which

      if (keyCode === 13) {
        event.returnValue = false
        if (event.preventDefault) event.preventDefault()
      }
    }

  const pasteAsPlainText = event => {
    event.preventDefault()
    const text = event.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text)
  }

  const highlightAll = () => {
    setTimeout(() => {
      document.execCommand('selectAll', false, null)
    }, 0)
  }


  function Table({ columns, data, updateMyData }) {  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,        
    } = useTable(
      {
        columns,
        data,
        defaultColumn,  
        updateMyData,
      }
    )

  return (
    
    <MaUTable {...getTableProps()} aria-label="simple table" sx={{marginBottom:"15px"}}  >
      <TableHead>
        {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()} key={uuidv4()} >
            {headerGroup.headers.map(column => (
              <TableCell {...column.getHeaderProps()} key={uuidv4()}
              sx={{
                color: '#879497',
                fontSize: '16px',
                fontFamily: 'Roboto, sans-serif',
                textAlign: 'left',
                padding: '0.5rem',
                borderBottom: 'none'
              }}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody  >
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps()} key={uuidv4()}>
              {row.cells.map(cell => {               
                return (
                  <TableCell {...cell.getCellProps()} key={uuidv4()} 
                  sx={{
                    color: '#879497',
                    fontSize: '16px',
                    padding: '0.5rem',
                    textAlign: 'left',
                    borderBottom: 'none',
                    borderTop: '1px solid lightgrey',               
                    '& input': {
                      width: '100%',
                      fontSize: '16px',
                      padding: 0,
                      margin: 0,
                      border: 'none',
                      outline: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                    },
                  }}
                 >
                    {cell.render('Cell')}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </MaUTable>
    
  )
}

const updateMyData = (rowIndex, columnId, value) => {
  if( properties.verboseLog==1){
    console.log("Update requested");
    console.log("UMD",rowIndex, columnId, value);    
  }
  if(idCard!=undefined){
    if( properties.verboseLog==1)
      console.log (data.find(op => op.id===idCard));  
    var tmpTable=data.find(op => op.id===idCard).budget;        
    tmpTable.map((row, index) =>{
      if (index === rowIndex) {
        if( properties.verboseLog==1)
          console.log(tmpTable[rowIndex+1]);
        if(columnId==='b1'){
          if(tmpTable[rowIndex+1][1]!==value){
            tmpTable[rowIndex+1][1]=value;
            if( properties.verboseLog==1)
              console.log("b1", idCard, tmpTable[rowIndex+1][0], tmpTable[rowIndex+1][1],value,rowIndex+1);            
            doPost11(idCard, index+1, tmpTable[rowIndex+1][0], tmpTable[rowIndex+1][1] ,tmpTable[rowIndex+1][2]);
            }
        }
        if(columnId==='b2'){
          if(tmpTable[rowIndex+1][2]!==value){
            tmpTable[rowIndex+1][2]=value;
            if( properties.verboseLog==1)
              console.log("b2", idCard,tmpTable[rowIndex+1][0], tmpTable[rowIndex+1][2],value, rowIndex+1);            
            doPost11(idCard, index+1, tmpTable[rowIndex+1][0], tmpTable[rowIndex+1][1] ,tmpTable[rowIndex+1][2]);
          }          
        }
      }
    })  
  }
}

  const columns = React.useMemo(
    () => [
      {
        Header: 'Riallocazione Budget',
        columns: [
          {
            Header: 'Missione',
            accessor: 'name',
            Cell: function Cell(cell){
              return <span style={{color:'black'}} key={uuidv4()}>{cell.value}</span>
            }
          },
          {
            Header: 'Anno in Corso',
            accessor: 'b1',
          },
          {
            Header: 'Anni Precedenti',
            accessor: 'b2',
          },
        ],
      },
     
    ],
    []
  )


    const manyItems = [...new Array(900)].map((_, i) => ({
    value: i,
    label: `${i}`,    
  }));

    const missioniValues =[ 
        {value: "62", label:"Abitare"},
        {value: "63", label:"Lavoro"},
        {value: "64", label:"Educazione"},
        {value: "65", label:"Comunita'"},
        {value: "66", label:"Inclusione"},
        {value: "67", label:"PERSONE"},
        {value: "68", label:"Risorse Libere Persone"},
        {value: "70", label:"Attrattivita'"},
        {value: "71", label:"Competenze"},
        {value: "72", label:"Custodia"},
        {value: "73", label:"Partecipazione"},
        {value: "74", label:"CULTURA"},
        {value: "75", label:"Risorse Libere Cultura"},
        {value: "80", label:"Ricerca"},
        {value: "81", label:"Innovazione"},
        {value: "82", label:"Benessere"},
        {value: "83", label:"Internazionalizzazione"},
        {value: "84", label:"Ambiente"},
        {value: "85", label:"PIANETA"},
        {value: "86", label:"Risorse Libere Pianeta"},
        {value: "90", label:"DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE"},
        {value: "95", label:"DIREZIONE INNOVAZIONE DI IMPATTO"},
        //{value: "98", label:"IN EVIDENZA"},
        {value: "98", label:"INDICE E SITUAZIONE FONDI"},
        //{value: "100", label:"PROGETTI SPECIALI / INTERSETTORIALI E PRATICHE AGGIUNTIVE"},    
        {value: "100", label:"CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI"},    
        {value: "1", label:"Ricerca e Istruzione Superiore"},
        {value: "5", label:"Patrimonio Artistico"},
        {value: "6", label:"Arte, Attivita' e Beni Culturali"},
        {value: "7", label:"Attivita' Culturali"},
        {value: "9", label:"Sanita'"},
        {value: "10", label:"Filantropia e Territorio"},
        {value: "11", label:"Politiche Sociali"},
        {value: "12", label:"Innovazione Culturale"},
        {value: "19", label:"Volontariato"},
        {value: "21", label:"Protocollo Intesa A.C.R.I."},
        {value: "25", label:"Lasciti e Donazioni"},
        {value: "35", label:"Programma Torino e le Alpi"},
        {value: "40", label:"Programma Polo del '900"},
        {value: "45", label:"Programma ZeroSei"},
        {value: "50", label:"Programma Housing"},
        {value: "55", label:"Sperimentazione e Attuazione Piano Strategico"},
        {value: "57", label:"Innovazione di Impatto"},
        {value: "59", label:"Programma Interational Affairs"}       
    ];
    
    const convertSectorNameFromBudgetToSectorName= function(nomeSettore) {
	    if (nomeSettore.startsWith("Abitare"))
	    	return 62;
	    if (nomeSettore.startsWith("Occupazione"))
	    	return 63;
	    if (nomeSettore.startsWith("Educazione"))
	    	return 64;
	    if (nomeSettore.startsWith("Comunit"))
	    	return 65;
	    if (nomeSettore.startsWith("Territori"))
	    	return 66;	    
	    if (nomeSettore.startsWith("Persona"))
	    	return 67;
	    if (nomeSettore.startsWith("Persone"))
	    	return 67;			
	    if (nomeSettore.startsWith("Attrattivit"))	    	
	    	return 70;
	    if (nomeSettore.startsWith("Competenze"))
	    	return 71;
	    if (nomeSettore.startsWith("Custodia"))
	    	return 72;
	    if (nomeSettore.startsWith("Partecipazione"))
	    	return 73;	    
	    if (nomeSettore.startsWith("Patrimonio"))
	    	return 74;	    	    	
	    if (nomeSettore.startsWith("Cultura"))
	    	return 74;	    	    	
	    if (nomeSettore.startsWith("Ricerca")&&(nomeSettore.includes("Formazione")))
	    	return 80;
	    if (nomeSettore.startsWith("Innovazione"))
	    	return 81;
	    if (nomeSettore.startsWith("Stili"))
	    	return 82;
	    if (nomeSettore.startsWith("Opportunit"))
	    	return 83;
	    if (nomeSettore.startsWith("Sostenibilit"))
	    	return 84;
	    if (nomeSettore.startsWith("Prosperit"))
	    	return 85;
	    if (nomeSettore.startsWith("Pianeta"))
	    	return 85;		    	
	    if (nomeSettore.startsWith("Direzione Pianificazione"))
	    	return 90;	    			    	
	    if (nomeSettore.startsWith("Direzione Innovazione"))
	    	return 95;	    
	    return 100;	     
	}





    const fromSettoreCodiceToColor2 = function(item) {
      if( properties.verboseLog==1)
        console.log("2121",item);
      if(item.Richiesta.ChallengeCodice==6 ||item.Richiesta.ChallengeCodice=='06')
          return ("#000000");  

      var codice=item.Richiesta.SettoreCodice;
      var totali=item.Richiesta.TotaliSettori;
      var sectorsPratica=[];
      for(var ix=1; ix< totali.length;ix++){
        if(totali[ix][1]!="0,00" ||totali[ix][2]!="0,00"){
          if( properties.verboseLog==1)
            console.log(item.Richiesta,totali[ix][0],convertSectorNameFromBudgetToSectorName(totali[ix][0]) ); 
          sectorsPratica.push(convertSectorNameFromBudgetToSectorName(totali[ix][0]));
        }
      }     
      if(sectorsPratica.length==1){        
        codice=sectorsPratica[0];
        if( properties.verboseLog==1)
          console.log("If", item.Richiesta.IdRichiesta, sectorsPratica, codice, item);      
      }
      else{  
        if( properties.verboseLog==1)
          console.log("Else", item.Richiesta.IdRichiesta, sectorsPratica, codice);      
        for(var ix2=0; ix2 < sectorsPratica.length; ix2++){ 
            if( properties.verboseLog==1)  
              console.log("Else", sectorsPratica[ix2], codice);
            if(sectorsPratica[ix2]>=80 && sectorsPratica[ix2]<90)  {//PIANETA
              if(codice<80 || codice>=90)  
                codice=100;              
            }
            if(sectorsPratica[ix2]>=70 && sectorsPratica[ix2]<80)  {//CULTURA
              if(codice<70 || codice>=80)  
                codice=100;              
            }
            if(sectorsPratica[ix2]>=60 && sectorsPratica[ix2]<70)  {//PERSONE
              if(codice<60 || codice>=70)  
                codice=100;              
            }            
            if(sectorsPratica[ix2]==100 && codice != 100)  {//              
              codice=100;              
            }  
            if(sectorsPratica[ix2]==90 && codice != 90)  {
              codice=100;              
            }  
            if(sectorsPratica[ix2]==95 && codice != 95)  {
              codice=100;              
            }  
        }
        if( properties.verboseLog==1)
          console.log("Else fine", item.Richiesta.IdRichiesta, codice);      
      }
      if(item.Richiesta.ForcePositioning){
        codice=item.Richiesta.SettoreCodice;
      }      
        
      if (codice>=80 && codice<90)  {//PIANETA
          return ("#0084d5");
      }
      else{
          if (codice>=70 && codice<80)  {//Cultura
              return ("#ce0f69");                
          }
          else{
              if (codice>=60 && codice<70)  {//Persone
                  return ("#F0B323");
              }
              else {
                  if (codice==100)  {//Box Neutro
                      return ("#DDDDDD");
                  }
                  else{
                      if (codice>=90 && codice <=95) //Direzioni
                          return ("#FFD480");   
                      else{
                          if (codice==98) {//In evidenza
                           return ("#046A38");
                          }
                          else //non dovrebbe mai capitare
                              return ("#000000");   
                      }
                  }
              }            
          }
      }
  }


    const doPost11 = function(scheda, indice, campo1, campo2, campo3) {
        
      var xhr = new XMLHttpRequest();                          
      xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/updateTableField.php',true);
      xhr.withCredentials = true;
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      xhr.onload = function (e) {      
          if (xhr.readyState === 4) {
              if (xhr.status === 200) {   
                  if( properties.verboseLog==1)                           
                    console.log("OK92",xhr);
                  var responseText = xhr.responseText;                    
                  var obj=JSON.parse(responseText);
                  if( properties.verboseLog==1)
                    console.log("OK93",obj);                      
                  const temp1= Object.entries(obj);
      
                  temp1.forEach( entry => {
                      let key= entry[0];
                      let value= entry[1];

                      if(key==='Schede') {                                 
                          const sorted=entry[1].slice().sort((a, b) =>parseInt(a.Richiesta.Ordine)- parseInt(b.Richiesta.Ordine));
                          setSchedeList(sorted);                            
                      }                
                  }); 
          
              } 
              else {                
                alert("Errore recuperando la seduta raw richiesta 1");                
                navigate("/init",{ replace: true });  
              }
          }
      };

  
      xhr.onerror = function (e) {
          console.error("Error 2", xhr.statusText);
      };
        
      let urlEncodedData = "idf=" + idDossier + "&ids=" + scheda+ "&index=" + indice+ "&field0=" + campo1+ "&field1=" + campo2+ "&field2=" + campo3; 
      if( properties.verboseLog==1)
        console.log("WERE",urlEncodedData);
      xhr.send(urlEncodedData);
  }


    const doPost = function(scheda, campo, valore) {
        if( properties.verboseLog==1)
          console.log("DP",scheda,campo,valore);
        var xhr = new XMLHttpRequest();        
        
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/updateFields.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.onload = function (e) {      
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                              
                    if( properties.verboseLog==1)
                      console.log("OK72",xhr);
                    var responseText = xhr.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                      console.log("OK73",obj);                      
                    const temp1= Object.entries(obj);
        
                    temp1.forEach( entry => {
                        let key= entry[0];
                        let value= entry[1];

                        if(key==='Schede') {                                 
                            const sorted=entry[1].slice().sort((a, b) =>parseInt(a.Richiesta.Ordine)- parseInt(b.Richiesta.Ordine));
                            setSchedeList(sorted);                            
                        }                
                    }); 
            
                } 
                else {                
                  alert("Errore recuperando la seduta raw richiesta 2");                
                  navigate("/init",{ replace: true });  
                }
            }
        };

    
        xhr.onerror = function (e) {
            console.error("Error 2", xhr.statusText);
        };
          
        let urlEncodedData = "idf=" + idDossier + "&ids=" + scheda+ "&field=" + campo+ "&value=" + valore; 
        if( properties.verboseLog==1)
          console.log("DP-2",urlEncodedData);
        xhr.send(urlEncodedData);
    }
        
    function convertIdToObiettivo(idi) {
        if( properties.verboseLog==1)
          console.log("22222",idi);        
        if ((idi != undefined) && (idi != '') &&(idi != ' ')){
            if( properties.verboseLog==1)
              console.log("22223",idi);
            if(idi<9 && !idi.startsWith("0"))
              idi="0"+idi;
            if( properties.verboseLog==1)
              console.log("22224",idi);                  
            var returned= obiettiviValues.find(o => o.value === idi); 
            if(returned != undefined)
              return returned.label;        
            else
              return "Non Valorizzato";
       
        }
    }
    
    function convertIdToMissione(idi) {
        if( properties.verboseLog==1)
          console.log("11111",idi);
        if ((idi != undefined) && (idi != '') &&(idi != ' ')){
          if( properties.verboseLog==1)
            console.log("11112",idi);
          if(idi.startsWith("0"))
            idi=idi.substring(1);
          if( properties.verboseLog==1)
            console.log("11113",idi);                      
          var returned= missioniValues.find(o => o.value === idi); 
          if(returned != undefined)
            return returned.label;        
          else
            return "Non Valorizzato";        
        }
    }

    const showAttributes = (event,item) => {
      if( properties.verboseLog==1)
        console.log("ShowAttributes",item, selectedItem);   
      if(selectedItem!==item.Richiesta.IdRichiesta){
        if( properties.verboseLog==1)
          console.log("ShowAttributes3",item.Richiesta.IdRichiesta);         
        setSelectedItem(item.Richiesta.IdRichiesta);
      }
      else{
        if( properties.verboseLog==1)
          console.log("ShowAttributes4");         
        setSelectedItem("");            
      }   
      if( properties.verboseLog==1)
        console.log("ShowAttributes2",item, selectedItem);     
    }   

    const doPostSeduta2 = function() {
        if( properties.verboseLog==1)
          console.log("doing sedute post");
        var xhr2 = new XMLHttpRequest();                    
        xhr2.open('GET', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/getSedutaReceived.php?idf='+meetingId,true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
          if (xhr2.readyState === 4) {
            if (xhr2.status === 200) {
                if( properties.verboseLog==1)
                  console.log("OK92",xhr2);
                var responseText = xhr2.responseText;                    
                var obj=JSON.parse(responseText);
                if( properties.verboseLog==1){
                  console.log("OK93",obj);          
                  console.log("Step1", new Date().getTime());    
                }
                const temp1= Object.entries(obj);        
                temp1.forEach( entry => {
                    let key= entry[0];
                    let value= entry[1];
                    if(key==='Schede') {
                        if( properties.verboseLog==1)                                                            
                          console.log("Step1", entry[1],new Date().getTime());
                        const sorted=entry[1].slice().sort((a, b) =>parseInt(a.Richiesta.Ordine)- parseInt(b.Richiesta.Ordine)); 
                        if( properties.verboseLog==1)
                          console.log(sorted,"sorted");      
                        var temp = [...sorted];
                        temp.splice(5, temp.length-5);             
                        setSchedeList(temp);                          
                        setTimeout( function() { 
                          if( properties.verboseLog==1)
                            console.log('later',sorted.length); 
                          setSchedeList(sorted); } , 300);                 
                        if( properties.verboseLog==1){
                          console.log("Step2",sorted, new Date().getTime());
                          console.log("Step2",new Date().getTime());
                        }
                        var temp3 = [];                        
                        sorted.forEach( entry2 => {
                          if( properties.verboseLog==1)
                            console.log("----", entry2.Richiesta.IdRichiesta);                                                            
                          var tempItem2=[];
                          tempItem2= {
                              id: entry2.Richiesta.IdRichiesta,
                              budget: entry2.Richiesta.TotaliSettori,
                              toBeShown: false,
                          }                                
                          temp3.push(tempItem2);
                          });
                        if( properties.verboseLog==1)                          
                          console.log("Step3",new Date().getTime());                        
                                              
                        setData(temp3);
                        if( properties.verboseLog==1)                        
                          console.log("Step4",temp3, new Date().getTime());
                        
                        setLoading(false);
                    }                    
                });                 
            } 
            else {
              //console.error("Error 1", xhr.statusText);          
              alert("Errore recuperando la seduta raw richiesta 3");              
              navigate("/init",{ replace: true });  
            }
          }
        };

        xhr2.onerror = function (e) {
          console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null;    
        xhr2.send(urlEncodedData);
}


function convert2(data){
  var items=[];
  for (var i = 1; i < data.length; i += 1) {
    var item1= {
        name: data[i][0],
        b1: data[i][1],
        b2: data[i][2],
    }
    items.push(item1)    
  }  
  return items;
}

useEffect(() => {
    if( properties.verboseLog==1)
      console.log("init",obiettiviValues["01"]);                              
    doPostSeduta2();         
}, []);


function updateSetShowTable( id) {
  if( properties.verboseLog==1)
    console.log(id);      
  var item=data.find(element =>element.id===id );
  if( properties.verboseLog==1)
    console.log(item);
  item.toBeShown= !item.toBeShown;        
  var tmpTable=data;  
  Object.assign(tmpTable[tmpTable.findIndex(el => el.id === id)], item);    
  setData(tmpTable);
  setSomethingChange(!somethingChange);       
}

function handleChange21(event, id) {
    if( properties.verboseLog==1){
      console.log("HC21-1",event.value);
      console.log("HC21-2",event);
      console.log("HC21-3",id);
    }    
    if (event){
      if( properties.verboseLog==1)
        console.log("INSIDE",id, event);
      doPost(id, "Ordine", event.value);   
    }     
}

function handleEditableBlur(event, item) {         
    if( properties.verboseLog==1)
      console.log("BLUR",event.target.outerText, item);                                    
    doPost(item.Richiesta.IdRichiesta, "Localizzazione", event.target.outerText); 
    if( properties.verboseLog==1)
      console.log("TEST",item.Richiesta.IdRichiesta, "Localizzazione", event.target.outerText,item);  
}

function handleKeyPress(event){
  if(event.keyCode === 13){
  event.target.blur();       
  }
}

function getData(id12) {      
    idCard=id12;  
    var c= convert2(data.find(op => op.id===id12).budget);
    if( properties.verboseLog==1)
      console.log("rete3",c);    
    return(c);
  }

 function handleChange1(event, id) {
     if (event && event.value){
      if( properties.verboseLog==1){
        console.log(event);        
        console.log(id);
      }
      doPost(id, "SettoreCodice", event.value);    
    }
  }


  function handleChange3(event, id) {
    if( properties.verboseLog==1){
      console.log(event.target.checked);
      console.log(event.target);
      console.log(id);
    }
    doPost(id, "Forzare", event.target.checked);  
  }

  const customStyles = {
    menu: (css) => ({
        ...css,
        width: 400,               
      }),
      control: (css) => ({ ...css, display: "inline-flex ",cursor:"text" }),
    
  }

return (
  <div key={uuidv4()}> 
        {loading ? (
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 
        ):(

<div className="content">
    
    {schedeList.map((item, index) => (
      <div className="scheda-item" style={{ borderRightColor: fromSettoreCodiceToColor2(item) }} key={item.Richiesta.IdRichiesta}>
        <div className="common-list" key={uuidv4()}
            style={{ position: "relative", alignItems: "unset", display: "unset", position: "unset", }} >  

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} key={uuidv4()} >
                <div style={{ paddingLeft: "30px", textAlign: "left", paddingRight:"20px"}} key={uuidv4()}>
                        <div className= "textGreySedute" style={{ paddingLeft: "20px",paddingTop: "20px", paddingBottom:"10px"}} key={uuidv4()}>  
                                <label>
                                      Richiesta:{" "}
                                      <span className= "textBlackSedute">{item.Richiesta.IdRichiesta}</span>
                                </label>
                        </div>                                                                                   
                        <div className= "textGreySedute" style={{ paddingLeft: "20px",paddingTop: "10px", paddingBottom:"10px",paddingRight:"20px"}} key={uuidv4()}>  
                                <label>  Descrizione:{" "}
                                      <span className= "textBlackSedute">{item.Richiesta.Moduli[0].Campi.RagioneSociale.Valore} </span>
                                </label>
                        </div>                                      
                        <div className= "textGreySedute" style={{ paddingLeft: "20px",paddingTop: "10px", paddingBottom:"10px",paddingRight:"20px"}} key={uuidv4()}>  
                                <label> Totale:{" "}
                                      <span className= "textBlackSedute">{item.Richiesta.IpotesiIntervento} </span>
                                </label>
                        </div>                                 
                        <div className= "textGreySedute" style={{ paddingLeft: "20px",paddingTop: "10px", paddingBottom:"0px",paddingRight:"20px"}} key={uuidv4()}>  
                                <label> Obiettivo:{" "}
                                      <span className= "textBlackSedute"> {convertIdToObiettivo(item.Richiesta.ChallengeCodice)}</span>
                                </label> 
                        </div>

                        {selectedItem===item.Richiesta.IdRichiesta ? (
                            <div key={uuidv4()}  style={{ display: "flex", alignItems: "flex-start", paddingLeft: "20px",paddingTop: "10px", paddingBottom:"0px",paddingRight:"20px" }} >
                              <label className= "textGreySedute" style={{ marginTop:"10px", paddingTop:"0px",paddingRight:"5px"}}>     Missione: {" "}
                              </label>
                              <Select  styles={customStyles} optionHeight={31}
                                      defaultValue={{
                                          label: convertIdToMissione(item.Richiesta.SettoreCodice),
                                          value: item.Richiesta.SettoreCodice
                                      }}
                                      onChange={(event) => handleChange1(event, item.Richiesta.IdRichiesta)}
                                      options={missioniValues}                                                                                           
                                      theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 0,
                                              colors: {
                                                ...theme.colors,
                                                primary25: '#a2ee7d',
                                                primary: 'black',
                                              },
                                })} />                                     
                                <FormControlLabel style={{paddingLeft:"20px", color:"black"}}
                                    control={<Checkbox style ={{color: "#30720f"}} checked={(item.Richiesta.ForcePositioning=== 'true') ?? false} onChange={(event) => handleChange3(event, item.Richiesta.IdRichiesta)} />} label="Forza riposizionamento all'interno della missione selezionata" />
                            </div>
                            
                            ):( 
                              <div className= "textGreySedute" style={{ paddingLeft: "20px",paddingTop: "20px", paddingBottom:"0px",paddingRight:"20px"}} key={uuidv4()}>  
                                <label> Missione:{" "}
                                        <span className= "textBlackSedute">                 
                                              {convertIdToMissione(item.Richiesta.SettoreCodice)}
                                        </span>
                                </label> 
                              </div>   )} 

                              {selectedItem===item.Richiesta.IdRichiesta ? (
                                  <div key={uuidv4()} style={{ display: "flex", alignItems: "flex-start", paddingLeft: "20px",paddingTop: "10px", paddingBottom:"0px",paddingRight:"20px" }} >
                                    <label className= "textGreySedute" style={{ marginTop:"10px", paddingTop:"0px",paddingRight:"5px"}}>     Ordine: {" "}
                                    </label>     
                                    <Select grouped={false}
                                            minimumInputSearch={0}
                                            optionHeight={31}
                                            defaultValue={{
                                              label: item.Richiesta.Ordine,
                                              value: item.Richiesta.Ordine
                                            }}
                                            styles={customStyles}                                                                        
                                            onChange={(event) => handleChange21(event, item.Richiesta.IdRichiesta)}
					                                  options={manyItems}                                                                            
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: '#a2ee7d',
                                                  primary: 'black',
                                                },
                                              })}/>
                                    </div> 
                                  ):( 
                                  <div key={uuidv4()} className= "textGreySedute" style={{ paddingLeft: "20px",paddingTop: "20px", paddingBottom:"0px",paddingRight:"20px"}} >  
                                    <label>  Ordine:{" "}
                                      <span className= "textBlackSedute"> {item.Richiesta.Ordine} </span>
                                    </label> 
                                  </div>   )}

                              {selectedItem===item.Richiesta.IdRichiesta ? (
                                  <div key={uuidv4()} style={{ display: "flex", alignItems: "flex-start", paddingLeft: "20px",paddingTop: "10px", paddingBottom:"10px",paddingRight:"20px" }} >
                                    <label className= "textGreySedute" style={{ marginTop:"10px", paddingTop:"0px",paddingRight:"5px"}}>     Localizzazione: {" "}
                                    </label>     
                                    <ContentEditable                                                                
                                        html={item.Richiesta.Localizzazione}
                                        className="content-editable"
                                        onKeyPress={disableNewlines}
                                        onPaste={pasteAsPlainText}
                                        onFocus={highlightAll}
                                        onBlur={(event) => handleEditableBlur(event, item)}                                        
                                        onKeyDown={(event) => handleKeyPress(event)}
                                        style={{ marginLeft: "10px", padding:"5px", paddingTop:"10px",
                                        borderColor: "hsl(0, 0%, 80%)", borderRadius: "0px", borderStyle:"solid", borderWidth:"1px"}}
                                    />  
                                  </div> 
                                  ):( 
                                  <div className= "textGreySedute" style={{ paddingLeft: "20px",paddingTop: "20px", paddingBottom:"20px",paddingRight:"20px"}} key={uuidv4()}>  
                                    <label>  
                                      Localizzazione:{" "}
                                      <span className= "textBlackSedute">                 
                                        {item.Richiesta.Localizzazione}
                                      </span>
                                    </label> 
                                  </div>   )}

                              {selectedItem===item.Richiesta.IdRichiesta ? (
                                   <div key={uuidv4()} >                    
                                   {item.Richiesta.TotaliSettori.map((itemlist, index11) =>  (
                                    <div key={uuidv4()}>
                                                            
                                      {index11 > 0  && (itemlist[1] !== "0,00" ||itemlist[2]!== "0,00") ? (
                                      <div key={`${item.Richiesta.IdRichiesta}-${index11}`}
                                        style={{
                                         textAlign: "left",
                                         //paddingLeft: "50px",
                                         color: "#879497",
                                         paddingLeft: "20px",                                         
                                      }} >
                                        <p className= "textBlackTable"
                                         style={{
                                             display: "inline-block",
                                             width: "30%",
                                         }}
                                        > {itemlist[0]}</p>
                                        <p className= "textBlackTable "
                                          style={{ display: "inline-block",width: "30%" }}>{itemlist[1]}</p>
                                        <p  className= "textBlackTable "
                                          style={{ display: "inline-block", width: "30%" }}>{itemlist[2]}</p>
                                      </div>
                                    ) : index11 === 0  ? (
                                      <div key={uuidv4()}
                                        style={{
                                         textAlign: "left",
                                         //paddingLeft: "50px",
                                         color: "#879497",
                                         paddingLeft: "20px",
                                         //fontWeight: "bold",
                                         //background: "#EFEFF6",
                                        }}>
                                          <p className= "textGreyTable"
                                              style={{
                                                  display: "inline-block",
                                                  width: "30%",
                                              }}>Missione/Settore </p>
                                          <p className= "textGreyTable "
                                              style={{ display: "inline-block",width: "30%" }}>Importo Anno In Corso</p>
                                          <p className= "textGreyTable "
                                              style={{ display: "inline-block", width: "30%" }}>Importo Anni Precedenti</p>
                                          <EditIcon style={{
                                                         display: "inline-block", 
                                                         width: "10%",
                                                         cursor:"pointer"
                                                         //color: "#30720f",
                                                         //top: "-32px !important",
                                                         //background:
                                                         //    "unset !important",                                                        
                                                     }} onClick={() => updateSetShowTable(item.Richiesta.IdRichiesta)}/>                                    
                                        </div>                             
                                     ): (
                                        <></>
                                     )}
                                       </div> 
                                   )        
                         )}
 
                        {!somethingChange ? (
                                    <p></p>): (<p></p>)}

                                {data.find( element => element.id===item.Richiesta.IdRichiesta) ==undefined ? (<></>) : data.find( element => element.id===item.Richiesta.IdRichiesta).toBeShown ? (
                                    <div key={uuidv4()}>                                     
                                        <Table key={uuidv4()} columns={columns} data={getData(item.Richiesta.IdRichiesta)} updateMyData={updateMyData}  />
                                    </div>
                                ): <></>}
                                 </div> 
                                ):( 
                                  <></>
                                  )}
                                                             
                </div>

                <div key={uuidv4()}  style={{ paddingLeft: "0px", paddingRight: "20px", color: "#879497", }} >      
                        <Tooltip title="Espandi" placement="bottom">
                          <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#ffffff",}} onClick={(event) => {showAttributes(event,item); }}>
                              {React.createElement(Icons['MoreHoriz'])}
                          </IconButton>
                        </Tooltip>                    
                </div>
            </div>
        </div>
    </div>
  
 ))}                    
        </div>
)}
</div>
    );
}
