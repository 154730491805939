import {properties} from "../../utils/properties";


export  const doPostSeduta2 = function(updateUI, meetingId) {
    const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));
    if (MeetingInfo)
      updateUI(MeetingInfo);
    var xhr2 = new XMLHttpRequest();  
    xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSeduta.php',true); 
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr2.onload = function (e) {
      if (xhr2.readyState === 4) {
          if (xhr2.status === 200) {
              if( properties.verboseLog==1)
                console.log("OK92",xhr2);
              var responseText = xhr2.responseText;
              var obj=JSON.parse(responseText);
              if( properties.verboseLog==1)
                console.log("OK93",obj);
              if(obj.status==="204" ){
                  const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));
                  updateUI(MeetingInfo);
              }
              else{
                  //status 201
                  if( properties.verboseLog==1)
                    console.log("OK95",obj.date);
                  try{
                    localStorage.setItem("meetingContent", obj.content);
                    localStorage.setItem("meeting", meetingId);
                    localStorage.setItem("meetingVersion", obj.date);                                                                                                                                                                                                                                          
                  }
                  catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                  }   
                  const MeetingInfo = JSON.parse(obj.content);
                  updateUI(MeetingInfo);
              }
          } //200
          else {
              if( properties.verboseLog==1)
              console.log("Error 1", xhr2.statusText);
              alert("Errore recuperando la seduta");
          }
    }//4
  };

  xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
  };

  let urlEncodedData = "idf=" + meetingId;
  if (localStorage.meeting===meetingId){
      if( properties.verboseLog==1)
          console.log( "QUINDICI00");
        const currentData=localStorage.getItem("meetingVersion");
        urlEncodedData = urlEncodedData+ "&date=" + currentData;
  }
  xhr2.send(urlEncodedData);
}

export const updateSeduta = function(meetingId,doPostSeduta2,updateUI) {
  if (window.cordova){
     var onSuccess = function(data) {
       window.sedute = {}
       window.sedute[meetingId] = JSON.parse(data);
       updateUI(window.sedute[meetingId]);
     }

     var onError = function() {
        alert("Impossibile scaricare la seduta, si prega di riprovare");
     }

     if (!window.sedute[meetingId]) {
        window.getSeduta(meetingId, onSuccess, onError);
     }
     else {
       updateUI(window.sedute[meetingId]);
     }
  }
  else{
     doPostSeduta2();
  }
}

export const updateSedute = function(organo,doPostSedute2,updateUI) {
  if (window.cordova){
     var onSuccess = function(data) {
       var sedute = JSON.parse(data);
       updateUI(sedute);
     }
     var onError = function() {
        //alert("Impossibile scaricare l'elenco delle sedute, si prega di riprovare");
     }
     window.getDossiers(organo,onSuccess, onError);
  }
  else{
     doPostSedute2();
  }
}