import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import RouterContext from "../../context/RouterContext";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import {properties} from "../../utils/properties";
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import * as Icons from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import axios from 'axios';
import '../../assets/bootstrap-custom.css';
import logo512 from "../../assets/logo512.png";
import { v4 as uuidv4 } from 'uuid';

const ColoredSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      color: "#808080", 
      '&.Mui-checked': {
        color: "#30720f", 
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#30720f", 
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: "#bdbdbd", 
    },
  }));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    flexDirection: "row",
    '& .MuiAccordionSummary-expandIcon': {
      background: "#FFFFFF !important",       
    }
  }));


const StyledPaper = styled(Paper)(({ theme }) => ({
        position: "absolute",
        width: 400,
        height: "100%",
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: "none",
}));
            
const StyledButton = styled(Button)(({ theme }) => ({
        border: "none",
        cursor: "pointer",
        margin: "0.3125rem 1px",
        padding: "12px 30px",
        position: "relative",
        fontSize: "12px",
        minWidth: "auto",
        minHeight: "auto",
        textAlign: "center",
        transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        fontWeight: "400",
        lineHeight: "1.42857143",
        whiteSpace: "nowrap",
        willChange: "box-shadow, transform",
        touchAction: "manipulation",
        borderRadius: "3px",
        letterSpacing: "0",
        textTransform: "uppercase",
        verticalAlign: "middle",
        color: "white",
        textDecoration: "none",
      }));
            
      const CancelButton = styled(StyledButton)({
        backgroundColor: "#e6e6e6",
        color: "#000000",
      });
      
      const SuccessButton = styled(StyledButton)({
        backgroundColor: "#4caf50",
        color: "#000000",
});



export default function Header() {    
    const navigate = useNavigate(); 
    const location = useLocation();
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    const [open, setOpen] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    const [modalData, setData] = useState();
    const [alert, setAlert] = useState(null);
    const [menuCommandsComitato, setMenuCommandsComitato] = useState();
    const [menuCommandsConsiglio, setMenuCommandsConsiglio] = useState();
    const [menuCommandsGeneral, setMenuCommandsGeneral] = useState();
    const [menuCommandsCollegio, setMenuCommandsCollegio] = useState();
    //COMMISSIONI 17/08/2023
    const [menuCommandsCommissioni, setmenuCommandsCommissioni] = useState();
    //17/08/2023    

    const handleConfirm99 = (value) => {
        if( properties.verboseLog==1)
            console.log('Input value:', value);
        var bodyFormData=new FormData();
        bodyFormData.append('idf', value);
        setAlert(false);
            
        let endpoint = properties.productionUrl+properties.productionUrlSuffix+"/core/rest/regenerateIdf.php";         
                
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            withCredentials: true, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
              if( properties.verboseLog==1)
                console.log(res.data)
              if (res.data.status === 'ok') {
                toast.success("OPERAZIONE ESEGUITA CON SUCCESSO");                
              } else {
                toast.success("SI E' VERIFICATO UN ERRORE ESEGUENDO L'OPERAZIONE RICHIESTA")
              }              
              setTimeout(() => {navigate("/init")}, 500)
              setAlert(null)
              
            })
            .catch(err => {
              console.log("Errore 4", err);
              setAlert(null);              
            })
      };


      const confirmRegenerateIdf = () => {       
        if( properties.verboseLog==1)
            console.log("confirmRegenerateIdf"); 
        setAlert(
          <SweetAlert
            input            
            required
            showCancel           
            confirmBtnText="Conferma"
            cancelBtnText="Annulla"
            validationMsg="Inserisci l'identificativo della seduta per la quale abilitare la gestione degli allegati (e.g. 504)"
            style={{ display: "block", marginTop: "auto", marginBottom: "auto", color: 'black', backgroundColor:'white'}}
            title="Pre-Consolidamento"
            placeHolder="Inserisci l'identificativo della seduta"
            onConfirm={(response) => handleConfirm99(response)}
            onCancel={() => { setAlert(null) }}         
            confirmBtnCssClass="custom-btn custom-btn-success" 
            cancelBtnCssClass="custom-btn custom-btn-cancel"
            customClass="custom-input"
          > Inserisci l'identificativo della seduta che vuoi riaprire
          </SweetAlert>
        );
      }


    if (window.cordova) {
       var onSuccess = function (canGoBackResult)
       {       
          window.canGoBackResult = JSON.parse(canGoBackResult);
       }

       var onFailure = function ()
       {

       }
       window.canGoBack(onSuccess,onFailure);

       var onSuccess = function(response)
       {
         console.log("OK LOGIN 33");
         var obj=JSON.parse(response);
         var ruolo = obj.Ruolo;

         window.c_ruolo = obj.Ruolo;
         window.c_name = obj.Name;
         window.c_genere = obj.Genere;

         window.c_commandsCommissioni = obj.CommandsCommissioni.filter(function(value, index, arr){
              return value.label != "Gestore delle Sedute" && value.label != "Tutte le Sedute" && value.label != "Preview Scheda" && value.label != "Preview Fascicolo" && !value.label.includes("Gestore delle Sedute") && !value.label.includes("Gestore dei Verbali");
         });

         window.c_commandsGeneral = obj.CommandsGeneral.filter(function(value, index, arr){
              return value.label != "Gestore delle Sedute" && value.label != "Tutte le Sedute" && value.label != "Preview Scheda" && value.label != "Preview Fascicolo" && !value.label.includes("Gestore delle Sedute")&& !value.label.includes("Gestore dei Verbali");
         });

         window.c_commandsComitato = obj.CommandsComitato.filter(function(value, index, arr){
              return value.label != "Gestore delle Sedute" && value.label != "Tutte le Sedute" && value.label != "Preview Scheda" && !value.label.includes("Gestore delle Sedute")&& !value.label.includes("Gestore dei Verbali")&& !value.label.includes("Gestisci Indice");
         });

         window.c_commandsConsiglio = obj.CommandsConsiglio.filter(function(value, index, arr){
              return value.label != "Gestore delle Sedute" && value.label != "Tutte le Sedute" && value.label != "Preview Scheda" && value.label != "Preview Fascicolo" && !value.label.includes("Gestore delle Sedute")&& !value.label.includes("Gestore dei Verbali");
         });
         window.c_commandsCollegio = obj.CommandsCollegio.filter(function(value, index, arr){
            return value.label != "Gestore delle Sedute" && value.label != "Tutte le Sedute" && value.label != "Preview Scheda" && value.label != "Preview Fascicolo" && !value.label.includes("Gestore delle Sedute")&& !value.label.includes("Gestore dei Verbali");
       });

       }

       var onError = function() {}

       window.getInfo3(onSuccess, onError);
    }

    if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null)
        localStorage.setItem("new_ui", false);

    const [checked, setChecked] =useState(stringToBoolean(localStorage.getItem("new_ui")));
    let temp="";
    let color123="#30720f";

    if( properties.verboseLog==1)
        console.log("HEADER - CALLED",location.pathname);
    
    if(location.pathname.indexOf("/fascicoli/")!=-1){
        temp = pageRouter.router.substring(pageRouter.router.indexOf("/fascicoli/")+11,pageRouter.router.indexOf("/fascicoli/")+19);
        if( properties.verboseLog==1)
            console.log("TEST",temp, temp.length);
        if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
            temp= "Comitato di Gestione - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
        else
            temp="Comitato di Gestione";
    }
    else if(location.pathname.indexOf("/consiglio/")!=-1){
        temp = pageRouter.router.substring(pageRouter.router.indexOf("/consiglio/")+11,pageRouter.router.indexOf("/consiglio/")+19);
        if( properties.verboseLog==1)
            console.log("TEST2",temp, temp.length);
        color123="#BF360C";
        if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
            temp= "Consiglio Generale - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
        else
            temp="Consiglio Generale";
    }
    else if(location.pathname.indexOf("/collegio/")!=-1){
        temp = pageRouter.router.substring(pageRouter.router.indexOf("/collegio/")+11,pageRouter.router.indexOf("/collegio")+19);
        if( properties.verboseLog==1)
            console.log("TEST3",temp, temp.length);
        color123="#666999";
        if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
            temp= "Collegio Sindacale - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
        else
            temp="Collegio Sindacale";
    }
    //SEARCH - 25/11/2024
    else if(location.pathname.indexOf("/searchConsiglio")!=-1){
        temp = "Consiglio Generale: Cerca";        
        color123="#BF360C";        
    }
    else if(location.pathname.indexOf("/searchCollegio")!=-1){
        temp = "Collegio Sindacale: Cerca";        
        color123="#666999";        
    }
    else if(location.pathname.indexOf("/search")!=-1){
        temp = "Comitato di Gestione: Cerca";        
        color123="#30720f";        
    }
    else if(location.pathname.indexOf("/indice")!=-1){
        temp = "Comitato di Gestione: Indice Attività Istituzionale";        
        color123="#30720f";        
    }
    else if(location.pathname.indexOf("/login")!=-1){
        temp = "Fascicolo Digitale";        
        color123="#000000";        
    }
    else if(location.pathname.indexOf("/changePassword")!=-1){
        temp = "Fascicolo Digitale";        
        color123="#000000";        
    }    
    else if(location.pathname.indexOf("/updatingAmbiti")!=-1){
        temp = "Gestione Ambiti";        
        color123="#30720f";        
    }
    else if(location.pathname.indexOf("/updating")!=-1){
        temp = "Gestione Allegati";        
        color123="#30720f";        
    }
   
    else if(location.pathname.indexOf("/raw")!=-1){
        temp = "Gestione Pratiche";        
        color123="#30720f";        
    }
    //COMMISSIONI 17/08/2023
    else if(location.pathname.indexOf("/cg_pianeta/")!=-1){
        temp = pageRouter.router.substring(pageRouter.router.indexOf("/cg_pianeta/")+11,pageRouter.router.indexOf("/cg_pianeta/")+19);
        if( properties.verboseLog==1)
            console.log("TEST4",temp, temp.length);
        color123="#000000";
        if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
            temp= "Commissione Pianeta - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
        else
            temp="Commissione Pianeta";
    }
    else if(location.pathname==="/calendar_cg_pianeta"){
        temp="Commissione Pianeta";
        color123="#000000";
    }
    else if(location.pathname.indexOf("/folders101")!=-1){
        temp="Commissione Pianeta";
        color123="#000000";
    }
    else if(location.pathname.indexOf("/files101")!=-1){
        temp="Commissione Pianeta";
        color123="#000000";
        }
    else if(location.pathname.indexOf("/repo")!=-1){
        temp="Repository";
        color123="#30720f";
    }
    else if(location.pathname.indexOf("/explore")!=-1){
        temp="Repository";
        color123="#30720f";
}
    //SIMILMENTE PER LE ALTRE
    else if(location.pathname.indexOf("/cg_cultura/")!=-1){
        temp = pageRouter.router.substring(pageRouter.router.indexOf("/cg_cultura/")+11,pageRouter.router.indexOf("/cg_cultura/")+19);
        if( properties.verboseLog==1)
            console.log("TEST7",temp, temp.length);
        color123="#000000";
        if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
            temp= "Commissione Cultura - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
        else
            temp="Commissione Cultura";
    }
    else if(location.pathname==="/calendar_cg_cultura"){
        temp="Commissione Cultura";
        color123="#000000";
    }
    else if(location.pathname.indexOf("/folders102")!=-1){
        temp="Commissione Cultura";
        color123="#000000";
    }
    else if(location.pathname.indexOf("/files102")!=-1){
        temp="Commissione Cultura";
        color123="#000000";
        }

    else if(location.pathname.indexOf("/cg_persone/")!=-1){
            temp = pageRouter.router.substring(pageRouter.router.indexOf("/cg_persone/")+11,pageRouter.router.indexOf("/cg_persone/")+19);
            if( properties.verboseLog==1)
                console.log("TEST8",temp, temp.length);
            color123="#000000";
            if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
                temp= "Commissione Persone - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
            else
                temp="Commissione Persone";
        }
        else if(location.pathname==="/calendar_cg_persone"){
            temp="Commissione Persone";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/folders103")!=-1){
            temp="Commissione Persone";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/files103")!=-1){
            temp="Commissione Persone";
            color123="#000000";
        }

    else if(location.pathname.indexOf("/cg_finanza/")!=-1){
            temp = pageRouter.router.substring(pageRouter.router.indexOf("/cg_finanza/")+11,pageRouter.router.indexOf("/cg_finanza/")+19);
            if( properties.verboseLog==1)
                console.log("TEST9",temp, temp.length);
            color123="#000000";
            if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
                temp= "Commissione Finanza - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
            else
                temp="Commissione Finanza";
        }
        else if(location.pathname==="/calendar_cg_finanza"){
            temp="Commissione Finanza";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/folders104")!=-1){
            temp="Commissione Finanza";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/files104")!=-1){
            temp="Commissione Finanza";
            color123="#000000";
        }

    else if(location.pathname.indexOf("/cg_statuto/")!=-1){
            temp = pageRouter.router.substring(pageRouter.router.indexOf("/cg_statuto/")+11,pageRouter.router.indexOf("/cg_statuto/")+19);
            if( properties.verboseLog==1)
                console.log("TEST10",temp, temp.length);
            color123="#000000";
            if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
                temp= "Commissione Statuto - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
            else
                temp="Commissione Statuto";
        }
        else if(location.pathname==="/calendar_cg_statuto"){
            temp="Commissione Statuto";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/folders105")!=-1){
            temp="Commissione Statuto";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/files105")!=-1){
            temp="Commissione Statuto";
            color123="#000000";
        }
    //17/08/2023    
    else if(location.pathname.indexOf("/schede/")!=-1){
       //temp= "- scheda "+;
       //temp ="- Scheda "+ pageRouter.router.substring(pageRouter.router.indexOf("/schede/")+8);
       if( properties.verboseLog==1)
            console.log("QQQ",pageRouter.router.indexOf("/schede/"));
       var initString=pageRouter.router.substring(8);
       if( properties.verboseLog==1)
            console.log("QQ1",initString);
       var separator=initString.indexOf("/");
       if( properties.verboseLog==1){
            console.log("QQ2",separator);
            console.log("QQ3",initString.substring(0,separator));
       }
       var tbd="";
       if (initString.length >0)
            tbd =initString;
       if (separator !==-1) {
            tbd= initString.substring(0,separator);
       }
       temp ="Comitato di Gestione - Scheda "+ tbd;

       //pageRouter.router.lastIndex("/");
    }
    else if(location.pathname.indexOf("/verbaliConsiglio")!=-1 && location.pathname.indexOf("preview/verbaliConsiglio")==-1){
        temp="Consiglio Generale - Verbali Approvati";
        color123="#BF360C";
    }
    else if(location.pathname.indexOf("/verbaliCollegio")!=-1 && location.pathname.indexOf("preview/verbaliCollegio")==-1){
        temp="Collegio Sindacale - Verbali Approvati";
        color123="#666999";
    }
    else if(location.pathname.indexOf("/verbali")!=-1 && location.pathname.indexOf("preview/verbali")==-1)
        temp="Comitato di Gestione - Verbali Approvati";
    else if(location.pathname.indexOf("/presenze2")!=-1){
        temp="Consiglio Generale - Presenze";
        color123="#BF360C";
    }
    else if(location.pathname.indexOf("/presenze3")!=-1){
        temp="Collegio Sindacale - Presenze";
        color123="#666999";
    }
    else if(location.pathname.indexOf("/presenze")!=-1)
        temp="Comitato di Gestione - Presenze";
    else if(location.pathname.indexOf("/folders3")!=-1){
        temp="Collegio Sindacale";
        color123="#666999";
    }
    else if(location.pathname.indexOf("/folders2")!=-1){
        temp="Consiglio Generale";
        color123="#BF360C";
    }
    else if(location.pathname.indexOf("/folders")!=-1)
        temp="Comitato di Gestione";
    else if(location.pathname.indexOf("/files3")!=-1){
        temp="Collegio Sindacale";
        color123="#666999";
        }
    else if(location.pathname.indexOf("/files2")!=-1){
        temp="Consiglio Generale";
        color123="#BF360C";
    }
    else if(location.pathname.indexOf("/files")!=-1)
        temp="Comitato di Gestione";
    else if(location.pathname==="/consiglio"){
        temp="Consiglio Generale";
        color123="#BF360C";
    }
    else if(location.pathname==="/collegio"){
        temp="Collegio Sindacale";
        color123="#666999";
    }
    else if(location.pathname==="/cg_pianeta"){
        temp="Commissione Pianeta";
        color123="#000000";
    }
    else if(location.pathname==="/cg_cultura"){
        temp="Commissione Cultura";
        color123="#000000";
    }
    else if(location.pathname==="/cg_persone"){
        temp="Commissione Persone";
        color123="#000000";
    }
    else if(location.pathname==="/cg_finanza"){
        temp="Commissione Finanza";
        color123="#000000";
    }
    else if(location.pathname==="/cg_statuto"){
        temp="Commissione Statuto";
        color123="#000000";
    }
    else if(location.pathname==="/fascicoli")
        temp="Comitato di Gestione";
    else if(location.pathname==="/calendarConsiglio"){
        temp="Consiglio Generale";
        color123="#BF360C";
    }
    else if(location.pathname==="/calendarCollegio"){
        temp="Collegio Sindacale";
        color123="#666999";
    }
    else if(location.pathname==="/calendar")
        temp="Comitato di Gestione";
    else if(location.pathname==="/calendarAll")
        temp="Tutti gli Organi";
    else if(location.pathname==="/sedute")
        temp="Tutti gli Organi";
    else{
        temp="Fascicolo Digitale";
        color123="#000000";
    }
    
    if( properties.verboseLog==1)
        console.log("QQQ",temp);

    function stringToBoolean(string){
        switch(string.toLowerCase().trim()){
            case "true":
            case "yes":
            case "1":
            return true;

            case "false":
            case "no":
            case "0":
            case null:
            return false;

            default:
            return Boolean(string);
        }
}



    const prevPage = () => {
        if( properties.verboseLog==1)        
            console.log("LOCATION",location, document.referrer,document);        
        navigate(-1);                
    };

    const meetingArgument = () => {
        if( properties.verboseLog==1)
            console.log("QUI ORA");

        if (window.cordova){
            window.home();
        }
        else{        
            navigate("/init");
        }
    };

    function getModalStyle() {
        const top = 10;
        const left = 100;
        return {
            top: `${top}%`,
            left: `${left}%`,         
            transform: `translate(-${left}%, -${top}%)`
        };
    }

    const handleOpen = () => {
    if(localStorage.getItem("name")=== null && !window.cordova)
    {
        if( properties.verboseLog==1)
            console.log("TBI",localStorage.getItem("name"))
        doLogOut();
    }
    else{
        if( properties.verboseLog==1)
            console.log("wer",localStorage.getItem("name"),localStorage.getItem("genere"));

        var aa={
            Info: "Benvenut"
        };

    if(localStorage.getItem("name")!== null){
        if( properties.verboseLog==1)
            console.log("INFO",localStorage.getItem("genere"),localStorage.getItem("name"));
        if(localStorage.getItem("genere")== null ||localStorage.getItem("genere")=="1")
            aa.Info=aa.Info + "o "+ localStorage.getItem("name");
        else
            aa.Info=aa.Info + "a "+ localStorage.getItem("name");
        setData(aa);
        if( properties.verboseLog==1)
            console.log("P", aa);
    }
    else {
      if (window.c_genere == null || window.c_genere == "1")
        aa.Info=aa.Info + "o " + window.c_name;
      else
        aa.Info=aa.Info + "o " + window.c_name;
      setData(aa);
    }
    //COMMISSIONI 17/08/2023
    if(localStorage.getItem("commandsCommissioni")!== null){
        setmenuCommandsCommissioni(JSON.parse(localStorage.getItem("commandsCommissioni")));
    }
    else if (!window.cordova){
        setmenuCommandsCommissioni(menuCommandsFake);
    }
    else {
        setmenuCommandsCommissioni(window.c_commandsCommissioni);
    }
    //17/08/2023
    if(localStorage.getItem("commandsConsiglio")!== null){
        setMenuCommandsConsiglio(JSON.parse(localStorage.getItem("commandsConsiglio")));
    }
    else if (!window.cordova){
        setMenuCommandsConsiglio(menuCommandsFake);
    }
    else {
        setMenuCommandsConsiglio(window.c_commandsConsiglio);
    }
    if(localStorage.getItem("commandsCollegio")!== null){
        setMenuCommandsCollegio(JSON.parse(localStorage.getItem("commandsCollegio")));
    }
    else if (!window.cordova){
        setMenuCommandsCollegio(menuCommandsFake);
    }
    else {
        setMenuCommandsCollegio(window.c_commandsCollegio);
    }

    if(localStorage.getItem("commandsComitato")!== null){
        if( properties.verboseLog==1){
            console.log("OK17", localStorage.getItem("commands"));
            console.log("OK17",JSON.parse(localStorage.getItem("commands")));
        }
        setMenuCommandsComitato(JSON.parse(localStorage.getItem("commandsComitato")));
    }
    else if (!window.cordova){
        if( properties.verboseLog==1)
            console.log("OK18", menuCommandsFake);
        setMenuCommandsComitato(menuCommandsFake);
    }
    else {
        setMenuCommandsComitato(window.c_commandsComitato);
    }
    if(localStorage.getItem("commandsGeneral")!== null){
        if( properties.verboseLog==1){
            console.log("OK17", localStorage.getItem("commands"));
            console.log("OK17",JSON.parse(localStorage.getItem("commands")));
        }
        setMenuCommandsGeneral(JSON.parse(localStorage.getItem("commandsGeneral")));
    }
    else if (!window.cordova){
        if( properties.verboseLog==1)
            console.log("OK18", menuCommandsFake);
        setMenuCommandsGeneral(menuCommandsFake);
    }
    else {
        setMenuCommandsGeneral(window.c_commandsGeneral);
    }
    setOpen(true);
    }
    };

    const doAction = (item) => {
        if( properties.verboseLog==1){
            console.log("DA", item);
            console.log("DA2", item.order);
            console.log("DA3",window.location.href);
            console.log("DA3",window.location);
        }
        var dd="";
        if(window.location.pathname.startsWith("/fascicoli/")){
            if( properties.verboseLog==1)
                console.log(window.location.pathname);
            dd=window.location.pathname.substring(11);
            if(dd.includes("/")){
                    dd=dd.substring(0,dd.indexOf("/"));
                    if( properties.verboseLog==1)
                        console.log("DA4",window.location.pathname,dd);
                }
            else{
                if( properties.verboseLog==1)
                    console.log("DA5",window.location.pathname,dd);
            }
        }

        if((item.label==="Log out")||(item.label==="Logout"))
            doLogOut();
        else{
            if(item.label==="Chiudi pannello")
                handleClose();
            else{
                if(item.label==="Modifica Password"){
                    if (window.cordova){
                        window.modifyPassword();
                    }
                    else {                    
                        navigate("/changePassword");
                    }
                    handleClose();
                }
                else{
                    if(item.label==="Preview Scheda"){                        
                        navigate("/editor");
                        handleClose();                        
                    }
                    else{
                        if(item.label==="Gestisci Pratiche"){                            
                            navigate("/raw/last");
                            handleClose();                            
                        }
                        else{
                            if(item.label==="Gestisci Ambiti"){                                
                                navigate("/updatingAmbiti/last");
                                handleClose();                                
                            }
                            else{
                                if(item.label==="Gestisci Allegati"){                                    
                                    navigate("/updating/last");
                                    handleClose();                                    
                                }
                                else{
                                    if(item.label==="Rigenera Seduta"){                                        
                                        navigate("/rigenera/last");
                                        handleClose();                                        
                                    }
                                    else{
                                        if(item.label==="Preview Fascicolo"){                                            
                                            navigate("/fascicoli/last");
                                            handleClose();                                            
                                        }
                                        else{
                                            if(item.label==="Sedute Consolidate"){                                                
                                                if(item.action.includes("fascicoli")){                                                    
                                                    if(localStorage.getItem("new_ui")==="true")
                                                        navigate("/calendar");
                                                    else
                                                        navigate("/fascicoli");
                                                }
                                                else if(item.action.includes("consiglio")){                                                  
                                                  if(localStorage.getItem("new_ui")==="true")
                                                    navigate("/calendarConsiglio");
                                                  else
                                                    navigate("/consiglio");
                                                }
                                                else if(item.action.includes("collegio")){                                                    
                                                    if(localStorage.getItem("new_ui")==="true")
                                                    navigate("/calendarCollegio");
                                                  else
                                                    navigate("/collegio");
                                                  }
                                                handleClose();
                                            }
                                            else{
                                                if(item.label==="Gestore delle Sedute"){
                                                    if(item.order >=50){ //Collegio                                                        
                                                        navigate("/folders3");
                                                    }
                                                    else if(item.order >=30){ //Consiglio                                                    
                                                        navigate("/folders2");
                                                    }
                                                    else{                                                        
                                                        navigate("/folders");
                                                    }
                                                    handleClose();                                                    
                                                }
                                                else{
                                                    if(item.label==="Gestore dei File"){                                                        
                                                        navigate("/files");
                                                        handleClose();                                                        
                                                    }
                                                    else{
                                                        if(item.label==="Scarica Allegati"){                                                          
                                                            navigate("/scaricaAllegati/last");
                                                            handleClose();                                                            
                                                        }
                                                        else{
                                                            if(item.label==="Blocca/Sblocca"||item.label==="Blocca"||item.label==="Sblocca"){                                                                    
                                                                navigate("/blocca");
                                                                handleClose();                                                                
                                                            }
                                                            else{
                                                                if(item.label==="Consolida"){                                                                
                                                                    navigate("/consolida");
                                                                    handleClose();                                                                    
                                                                }
                                                                else{
                                                                    if(item.label==="Gestore delle Presenze"){
                                                                        if(item.order >=50){ //Collegio
                                                                            if(dd!=""){                                                                            
                                                                                navigate("/presenze3/"+dd);
                                                                            }
                                                                            else{                                                                            
                                                                                navigate("/presenze3/current");
                                                                            }
                                                                        }
                                                                        else if(item.order >=30){ //Consiglio
                                                                            if(dd!=""){                                                                                                                                                        
                                                                                navigate("/presenze2/"+dd);
                                                                            }
                                                                            else{                                                                            
                                                                                navigate("/presenze2/current");
                                                                            }
                                                                        }
                                                                        else{
                                                                            if(dd!=""){                                                                                        
                                                                                navigate("/presenze/"+dd);
                                                                            }
                                                                            else{                                                                            
                                                                                navigate("/presenze/current");
                                                                            }
                                                                        }
                                                                        handleClose();
                                                                    }
                                                                    else{
                                                                        if(item.label==="Cerca"){
                                                                            if( properties.verboseLog==1)
                                                                                console.log("Searching...",item.order);
                                                                            if(item.order >=50){ //Collegio                                                                                        
                                                                                navigate("/searchCollegio");
                                                                            }
                                                                            else if(item.order >=30){ //Consiglio
                                                                                navigate("/searchConsiglio");
                                                                            }
                                                                            else
                                                                                navigate("/search");
                                                                            handleClose();
                                                                        }
                                                                        else{
                                                                            if(item.label==="Gestore dei Verbali"){                                                                            
                                                                                if(item.order >=50){ //Collegio                                                                                        
                                                                                    navigate("/verbaliCollegio");
                                                                                }
                                                                                else if(item.order >=30){ //Consiglio                                                                                        
                                                                                    navigate("/verbaliConsiglio");
                                                                                }
                                                                                else
                                                                                    navigate("/verbali");                                                                                                                                                                        
                                                                                handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Tutte le Sedute"){                                                                                
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendarAll");    
                                                                                    else
                                                                                        navigate("/sedute");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Torna a Pre-Consolidamento"){
                                                                                    confirmRegenerateIdf();                                                                                    
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Pianeta: Sedute Consolidate"){                                                                                    
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendar_cg_pianeta");    
                                                                                    else
                                                                                        navigate("/cg_pianeta");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Pianeta: Gestore delle Sedute"){                                                                                    
                                                                                    navigate("/folders101");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Cultura: Sedute Consolidate"){                                                                                    
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendar_cg_cultura");    
                                                                                    else
                                                                                        navigate("/cg_cultura");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Cultura: Gestore delle Sedute"){                                                                                    
                                                                                    navigate("/folders102");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Persone: Sedute Consolidate"){                                                                                    
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendar_cg_persone");    
                                                                                    else
                                                                                        navigate("/cg_persone");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Persone: Gestore delle Sedute"){                                                                                    
                                                                                    navigate("/folders103");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Finanza: Sedute Consolidate"){                                                                                    
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendar_cg_finanza");    
                                                                                    else
                                                                                        navigate("/cg_finanza");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Finanza: Gestore delle Sedute"){                                                                                    
                                                                                    navigate("/folders104");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Statuto: Sedute Consolidate"){                                                                                    
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendar_cg_statuto");    
                                                                                    else
                                                                                        navigate("/cg_statuto");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Statuto: Gestore delle Sedute"){                                                                                    
                                                                                    navigate("/folders105");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Gestore Repository"){
                                                                                    navigate("/repo");
                                                                                    handleClose();
                                                                                }
                                                                                else{
                                                                                    if(item.label==="Repository"){
                                                                                        navigate("/explore");
                                                                                        handleClose();
                                                                                    }
                                                                                    else{
                                                                                        if(item.label==="Gestisci Indice"){                                                                                            
                                                                                            navigate("/indice");
                                                                                            handleClose();                                                                                            
                                                                                        }
                                                                                        else{
                                                                                            alert("Funzionalità ancora da integrare");
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    }
    }
    }
    }
    }
    }
                        }
                    }
                }
            }
        }

    };


    const handleClose = () => {
        setOpen(false);
        if( properties.verboseLog==1){
            console.log("CON",menuCommandsConsiglio);
            console.log("COM",menuCommandsComitato);
            console.log("COM",menuCommandsCollegio);
            console.log("COM",menuCommandsCommissioni);
        }
    };

    const doLogOut = () => {
        console.log("Logout called");
        localStorage.clear();
        setChecked(false);
        var xhr = new XMLHttpRequest();        
        
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix+'/core/rest/logout.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        xhr.onload = function (e) {
            if( properties.verboseLog==1)
                console.log("PD",e,xhr.readyState,xhr.status);
            if (xhr.readyState === 4) {
            
             if (xhr.status === 200) {
                if (window.cordova){
                  window.pushLogin();
                }
                else {            
                  navigate("/login");
                }             
             handleClose();
             } else {
                console.error("DOLOGOUT - Error 17", xhr.statusText);                
                if (window.cordova){
                  window.pushLogin();
                }
                else {       
                  navigate("/login");
                }
             }
          }
        };

        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
        };

        let urlEncodedData = null;
        xhr.send(urlEncodedData);
    }


    const menuCommandsFake = [
    {
      order: 0,
      label: "Sedute Consolidate",
      action:"postIdsf.php",
      icon: 'Notifications'
    },
    {
      order: 1,
      label: "Preview Fascicolo",
      action:"postIdsf.php",
      icon: 'Edit'
    },
    {
      order: 2,
      label: "Gestore delle sedute",
      action:"postIdsf.php",
      icon: 'Notifications'
    },
    {
        order: 4,
        label: "Modifica Password",
        action:"postIdsf.php",
        icon: 'Notifications'
      },
    {
      order: 5,
      label: "Gestore delle Presenze",
      action:"ciao.php",
      icon: 'ListAlt'
    },
    {
      order: 6,
      label: "Chiudi pannello",
      action:"postIdsf.php",
      icon: 'Close'
    },
    {
      order: 7,
      label: "Log out",
      action:"logout.php",
      icon: 'Notifications'
    }
    ];

    const accordionSummaryClick = () => {
        if( properties.verboseLog==1)
            console.log("Accordion Summary Click" );        
    }

    const CustomModal = () => {
    return modalData ? (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}        
      >
        <StyledPaper style={modalStyle} >
          <Typography variant="h6" id="modal-title" style={{
                    color: "#29610d",
                    //color: "#ff0000",
                    fontSize: "23px",
                    fontWeight: "bold",
                }}>
            {modalData.Info}
          </Typography>

          <br />

          <Typography variant="subtitle1" id="simple-modal-description">
            In caso di segnalazioni, contattare l'amministratore via mail (antonio@eleganzadigitale.com) o al seguente numero di telefono (338-6381491). Versione: {properties.version}
          </Typography>
          <br />
          <div className="contentModal2" >

          {menuCommandsGeneral[0].order===0 ?(
              <div
              className="common-list"
              //key={menuCommandsGeneral[0]}
              key={uuidv4()}
              style={{
                          cursor: "pointer",
                          //backgroundColor: "0x00ff00",
                          //color: "0x0000ff",
                      }}          
          onClick={ () =>doAction(menuCommandsGeneral[0])}
          >
               <MenuItem key={uuidv4()}>
        <ListItemIcon >
          {React.createElement(Icons[menuCommandsGeneral[0].icon])}
        </ListItemIcon>
        <ListItemText inset primary={menuCommandsGeneral[0].label} />
          </MenuItem>
          </div>
            ):(
                <></>
            )}

          {menuCommandsComitato.length != 0 ? (
          <Accordion key={uuidv4()} defaultExpanded={true} elevation={3} style={{  background: "#FFFFFF", boxShadow: "unset",  borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}} >
            <AccordionSummary   expandIcon={<ExpandMoreIcon  style={{ background: "#FFFFFF" }}/>} aria-controls="panel1a-content" >
                <Typography component={'div'} style={{ paddingLeft: "0px", margin: "0px", width: "100%", fontWeight: "bold"}} onClick={() => accordionSummaryClick()}> Comitato di Gestione
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px", width:"100%" }}  onClick={(event) => event.stopPropagation()}>

            {menuCommandsComitato.map((item, index) => (
                <div
                    className="common-list"
                    key={uuidv4()}
                    style={{ cursor: "pointer"}}
                    onClick={ () =>doAction(item)}>
                    <MenuItem key={uuidv4()}>
                        <ListItemIcon >
                            {React.createElement(Icons[item.icon])}
                        </ListItemIcon>
                        <ListItemText inset primary={item.label} />
                    </MenuItem>
                </div>
            ))}
            </AccordionDetails>
            </Accordion>
            ):(<></>)}
          {menuCommandsConsiglio.length!=0 ? (
          <Accordion key={uuidv4()} defaultExpanded={true} elevation={3} style={{  background: "#FFFFFF", boxShadow: "unset",  borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}} >
            <AccordionSummary   expandIcon={<ExpandMoreIcon  style={{ background: "#FFFFFF" }}/>} aria-controls="panel1a-content" >
                <Typography component={'div'} style={{ paddingLeft: "0px", margin: "0px", width: "100%", fontWeight: "bold"}} onClick={() => accordionSummaryClick()}> Consiglio Generale
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px", width:"100%" }}  onClick={(event) => event.stopPropagation()}>

            {menuCommandsConsiglio.map((item, index) => (
                <div
                    className="common-list"
                    key={uuidv4()}
                    style={{ cursor: "pointer"}}
                    onClick={ () =>doAction(item)}>
                    <MenuItem key={uuidv4()}>
                        <ListItemIcon >
                            {React.createElement(Icons[item.icon])}
                        </ListItemIcon>
                        <ListItemText inset primary={item.label} />
                    </MenuItem>
                </div>
            ))}
            </AccordionDetails>
            </Accordion>
            ):(<></>)}

            {menuCommandsCommissioni.length!=0 ? (
            <Accordion key={uuidv4()} defaultExpanded={true} elevation={3} style={{  background: "#FFFFFF", boxShadow: "unset",  borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}} >
            <AccordionSummary   expandIcon={<ExpandMoreIcon  style={{ background: "#FFFFFF" }}/>} aria-controls="panel1a-content" >
                <Typography component={'div'} style={{ paddingLeft: "0px", margin: "0px", width: "100%", fontWeight: "bold"}} onClick={() => accordionSummaryClick()}> Commissioni Consiglio Generale
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px", width:"100%" }}  onClick={(event) => event.stopPropagation()}>

            {menuCommandsCommissioni.map((item, index) => (
                <div
                    className="common-list"
                    key={uuidv4()}
                    style={{ cursor: "pointer"}}
                    onClick={ () =>doAction(item)}>
                    <MenuItem key={uuidv4()}>
                        <ListItemIcon >
                            {React.createElement(Icons[item.icon])}
                        </ListItemIcon>
                        <ListItemText inset primary={item.label} />
                    </MenuItem>
                </div>
            ))}
            </AccordionDetails>
            </Accordion>
            ):(<></>)}

            {menuCommandsCollegio.length!=0 ? (
          <Accordion key={uuidv4()} defaultExpanded={true} elevation={3} style={{  background: "#FFFFFF", boxShadow: "unset",  borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}} >
            <AccordionSummary   expandIcon={<ExpandMoreIcon  style={{ background: "#FFFFFF" }}/>} aria-controls="panel1a-content" >
                <Typography component={'div'} style={{ paddingLeft: "0px", margin: "0px", width: "100%", fontWeight: "bold"}} onClick={() => accordionSummaryClick()}> Collegio Sindacale
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px", width:"100%" }}  onClick={(event) => event.stopPropagation()}>

            {menuCommandsCollegio.map((item, index) => (
                <div
                    className="common-list"
                    key={uuidv4()}
                    style={{ cursor: "pointer"}}
                    onClick={ () =>doAction(item)}>
                    <MenuItem  key={uuidv4()}>
                        <ListItemIcon >
                            {React.createElement(Icons[item.icon])}
                        </ListItemIcon>
                        <ListItemText inset primary={item.label} />
                    </MenuItem>
                </div>
            ))}
            </AccordionDetails>
            </Accordion>
            ):(<></>)}

            {menuCommandsGeneral.map((item, index) => (

                <div
                    className="common-list"
                    key={uuidv4()}
                    style={{
                                cursor: "pointer",
                                //backgroundColor: "0x00ff00",
                                //color: "0x0000ff",
                            }}
          
                onClick={ () =>doAction(item)}
                >
                {item.order !=0 ? (
                    <MenuItem  key={uuidv4()}>
                    <ListItemIcon >
                        {React.createElement(Icons[item.icon])}
                    </ListItemIcon>
                    <ListItemText inset primary={item.label} />
                    </MenuItem>
                ):(
                    <></>
                )
                }              
                </div>
            ))}
        </div>         
        </StyledPaper>
      </Modal>
    ) : null;
    };

    


    const handleChange = (event) => {
        if( properties.verboseLog==1)
            console.log("QUINTANA",location.pathname);
        if(localStorage.getItem('new_ui')==null)
            setChecked(false);
        else{
            if( properties.verboseLog==1)
                console.log ("Switch pressed",localStorage.getItem("new_ui"));
            localStorage.setItem("new_ui", event.target.checked);
            if( properties.verboseLog==1)
                console.log ("Switch pressed",localStorage.getItem("new_ui"),checked);
                        
            if (event.target.checked){
                if(location.pathname=="/sedute"){
                    if( properties.verboseLog==1)
                        console.log("2",location.pathname);                    
                    navigate("/calendarAll");
                }
                //COMMISSIONI 17/08/2023
                if(location.pathname=="/cg_pianeta"){
                    if( properties.verboseLog==1)
                        console.log("2",location.pathname);                    
                    navigate("/calendar_cg_pianeta");
                }
                if(location.pathname=="/cg_cultura"){
                    if( properties.verboseLog==1)
                        console.log("2",location.pathname);                    
                    navigate("/calendar_cg_cultura");
                }
                if(location.pathname=="/cg_persone"){
                    if( properties.verboseLog==1)
                        console.log("2",location.pathname);                    
                    navigate("/calendar_cg_persone");
                }
                if(location.pathname=="/cg_finanza"){
                    if( properties.verboseLog==1)
                        console.log("2",location.pathname);                    
                    navigate("/calendar_cg_finanza");
                }
                if(location.pathname=="/cg_statuto"){
                    if( properties.verboseLog==1)
                        console.log("2",location.pathname);                    
                    navigate("/calendar_cg_statuto");
                }
                //17/08/2023
                if(location.pathname=="/consiglio"){
                    if( properties.verboseLog==1)
                        console.log("2",location.pathname);                    
                    navigate("/calendarConsiglio");
                }
                if(location.pathname=="/collegio"){                    
                    if( properties.verboseLog==1)
                        console.log("2",location.pathname);
                    navigate("/calendarCollegio");
                }
                if(location.pathname=="/fascicoli"){
                    if( properties.verboseLog==1)
                        console.log("2",location.pathname);                    
                    navigate("/calendar");
                }
                if(location.pathname.includes("/fascicoli/")){
                    var tmpPath=location.pathname.substring(location.pathname.indexOf("/fascicoli/")+11);
                    if(tmpPath.endsWith("/"))
                        tmpPath=tmpPath.substring(0,tmpPath.indexOf("/"));
                    if( properties.verboseLog==1)                        
                        console.log("5",tmpPath);
                    
                    if(!isNaN(tmpPath)){
                        var newPath="/fascicoli/"+tmpPath+"/summary";
                        if( properties.verboseLog==1)                        
                            console.log("CP2",newPath);                        
                        navigate(newPath);
                    }
                }

            }
            else {
                if(location.pathname=="/calendarAll"){
                    if( properties.verboseLog==1)                        
                        console.log("3",location.pathname);                    
                    navigate("/sedute");
                }
                if(location.pathname=="/calendar"){
                    if( properties.verboseLog==1)          
                        console.log("3",location.pathname);                    
                    navigate("/fascicoli");
                }
                //COMMISSIONI 17/08/2023
                if(location.pathname=="/calendar_cg_pianeta"){
                    if( properties.verboseLog==1)          
                        console.log("2",location.pathname);                    
                    navigate("/cg_pianeta");
                }                
                if(location.pathname=="/calendar_cg_cultura"){
                    if( properties.verboseLog==1)          
                        console.log("2",location.pathname);                    
                    navigate("/cg_cultura");
                }
                if(location.pathname=="/calendar_cg_persone"){
                    if( properties.verboseLog==1)          
                        console.log("2",location.pathname);                    
                    navigate("/cg_persone");
                }
                if(location.pathname=="/calendar_cg_finanza"){
                    if( properties.verboseLog==1)          
                        console.log("2",location.pathname);                    
                    navigate("/cg_finanza");
                }
                if(location.pathname=="/calendar_cg_statuto"){
                    if( properties.verboseLog==1)          
                        console.log("2",location.pathname);                    
                    navigate("/cg_statuto");
                }
                //17/08/2023
                if(location.pathname=="/calendarConsiglio"){
                    if( properties.verboseLog==1)          
                        console.log("3",location.pathname);                    
                    navigate("/consiglio");
                }
                if(location.pathname=="/calendarCollegio"){
                    if( properties.verboseLog==1)          
                        console.log("3",location.pathname);                    
                    navigate("/collegio");
                }
                if(location.pathname.endsWith("/summary")||location.pathname.endsWith("/summary/")){
                    if( properties.verboseLog==1)          
                        console.log("4",location.pathname);
                    var newPath=location.pathname.substring(0, location.pathname.indexOf("/summary"));
                    if( properties.verboseLog==1)          
                        console.log("CP",newPath);                    
                    navigate(newPath);
                }
            }
            setChecked(stringToBoolean(localStorage.getItem("new_ui")));
        }
    };

    return (
        <div
            className="header"
            key={uuidv4()}
            style={{
                //width: "73%",
                //width: "100%",
                paddingLeft: "5%",
                paddingRight: "5%",
                margin: "auto",
                display: "flex",
                //position: "sticky",
                //position: "-webkit-sticky",
                //position: "-moz-sticky",
                //position: "-ms-sticky",
                //position: "-o-sticky",
                position: "sticky",
                zIndex: 100,
                top: 0,
                background: "white",
                justifyContent: "space-between",
            }}
        >
            {2<1 ? (
                <div
                key={uuidv4()}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        visibility: "hidden",

                    }}
                    onClick={prevPage}
                >
                    <div>
                        <i
                            className="fa fa-long-arrow-left"
                            aria-hidden="true"
                            style={{ paddingRight: "10px",  }}
                        ></i>
                    </div>
                    <p className="prev">Indietro</p>
                </div>
            ) : (
                <div
                key={uuidv4()}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={prevPage}
                >
                    <div>
                        <i
                            className="fa fa-long-arrow-left"
                            aria-hidden="true"
                            style={{ paddingRight: "10px", color: "#000000" }}
                        ></i>
                    </div>

                    <p className="prev" style={{ cursor: "pointer", color: "#000000" }}>
                        Indietro
                    </p>
                </div>
            )}
            { color123=='#30720f' ? (                
                <div 
                key={uuidv4()}
                style={{
                    //color: "#ff5a60",
                    color: "#30720f",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "23px",
                    //fontWeight: "bold",
                }}
            >
                <img style={{ maxWidth: "40px", height: "auto", paddingRight:"5px"}} src={logo512} alt="Company Logo" className="logo" />
                {temp}
            </div>
            ):[
                color123=='#666999' ? (                    
                    <div key={uuidv4()}
                    style={{
                        //color: "#ff5a60",
                        color: "#666999",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "23px",
                        //fontWeight: "bold",
                    }}
                    >
                    <img style={{ maxWidth: "40px", height: "auto", paddingRight:"5px"}} src={logo512} alt="Company Logo" className="logo" />
                    {temp}
                </div>
                ):[
                    color123=='#000000' ? (                        
                        <div key={uuidv4()}
                        style={{
                            //color: "#ff5a60",
                            color: "#000000",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "23px",
                        }}>
                        <img style={{ maxWidth: "40px", height: "auto", paddingRight:"5px"}} src={logo512} alt="Company Logo" className="logo" />
                        {temp}
                        </div>
                ):(                    
                    <div key={uuidv4()}
                    style={{
                        //color: "#ff5a60",
                        color: "#BF360C",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "23px",
                    }}>
                    <img style={{ maxWidth: "40px", height: "auto", paddingRight:"5px"}} src={logo512} alt="Company Logo" className="logo" />
                    {temp}
                    </div>
            )]]
            }

            <div style={{ display: "flex", alignItems: "center", borderBottom: "2.5px solid #30720f", }}>
            <FormControlLabel control={<ColoredSwitch id={uuidv4()} sx={{ m: 1 }} checked={checked} onChange={handleChange}/>}
                label="Nuova UX" style={{color: "black", fontFamily: 'Roboto, sans-serif'}}
            />
                <div style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 0px",
                        cursor: "pointer",
                        //borderBottom: "2px solid" + pageRouter.homebordercolor,
                    }} onClick={meetingArgument} >             
                    <div>
                        <i
                            className="fa fa-home"
                            aria-hidden="true"                    
                            style={{ color: "#30720f" }}
                        ></i>
                    </div>

                    <p
                        style={{
                            paddingLeft: "10px",
                            //fontWeight: "bold",   
                            color: "#000000",                         
                            //color: pageRouter.hometitlecolor,
                        }}
                    >
                        Homepage
                    </p>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 0px",
                        cursor: "pointer",
                        //borderBottom: "2px solid" + pageRouter.settingbordercolor,
                        //borderBottom: "2px solid #30720f",
                    }}
                    onClick={() => {
                        handleOpen();}}
                >
                    <div>
                        <i
                            style={{
                                paddingLeft: "30px",
                                paddingRight: "10px",
                                //color: pageRouter.settingcolor,
                                color: "#30720f",
                            }}
                            className="fa fa-cog"
                            aria-hidden="true"
                        ></i>
                    </div>
                    <p
                        style={{
                            //fontWeight: "bold",
                            //color: pageRouter.settingtitlecolor,
                            color: "#000000",
                        }}
                    >
                        Menu
                    </p>
                </div>
            </div>
            <CustomModal />
            {alert}
        </div>
    );
}
