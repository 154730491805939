import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";
import {properties} from "../../utils/properties";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function TopicSubList() {

    const { pageRouter, setPageRouter } = useContext(RouterContext);
    const [topicSubList, setTopicSubList] = useState([]);               
    const navigate = useNavigate();
    let { idDossier, indexSector } = useParams();    
    const meetingId = idDossier;
    const id=indexSector;    

    const updateUI = function(jsonData) {                                 
        let keyTopicSubList;
        let entireSpecificList;        
        let optimizerVersion=0;
        if( properties.verboseLog==1)
            console.log("UI",jsonData)
        const temp1= Object.entries(jsonData);        
        temp1.forEach( entry => {
                let key= entry[0];
                let value= entry[1];
                if(key==='fileSectors') {                                        
                    keyTopicSubList=value;                    
                }
                if (key==='sectors'){
                    entireSpecificList=value;                    
                }
                if (key==='version'){
                    optimizerVersion=value;                    
                }
        }); 
        
        const valueSpecificList = Object.values(entireSpecificList);                    
        var subList = keyTopicSubList[id]["cardIds"];        
        var items = [];
        if( properties.verboseLog==1)
            console.log ("sublist",subList);        
        for (var i = 0; i < subList.length; i++) {
            var item = [];                
            if (subList[i].type === "container" ) {                              
                    //SI TRATTA DI UN PUNTO DELL'ORDINE DEL GIORNO                     
                    var arrContainer = Object.entries(keyTopicSubList[id].containers);                    
                    let lab;
                    let wha;
                    for (var j = 0; j < arrContainer.length; j++) {                      
                        if (subList[i].item_id===arrContainer[j][0]){                            
                            lab=arrContainer[j][1].label;
                            wha=arrContainer[j][0];
                        }

                    }
                    item = {                                        
                        label: lab,
                        value:0,
                        url: "",
                        color: keyTopicSubList[indexSector].color,                        
                        type: "container",                        
                        what: wha,
                    };                           
            } else { 
                // DOCUMENTI NO CONTAINER -> PREMESSE
                item = {
                    label: keyTopicSubList[id]["premesse"][subList[i].item_id].label,                        
                    type: "premessa",
                    url: keyTopicSubList[id]["premesse"][subList[i].item_id].url,                    
                    color: keyTopicSubList[id]["color"],
                };
            }            
            items.push(item);
        }
        if( properties.verboseLog==1)
            console.log("UI2",items);
        setTopicSubList(items);
    }

    const doPostSeduta2 = function() {    
        var xhr2 = new XMLHttpRequest();                        
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSedutaConsiglio.php',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.withCredentials = true;
        xhr2.onload = function (e) {      
        if (xhr2.readyState === 4) {
            if (xhr2.status === 200) {                              
                if( properties.verboseLog==1)
                    console.log("OK92",xhr2);
                var responseText = xhr2.responseText;                    
                var obj=JSON.parse(responseText);
                if( properties.verboseLog==1)
                    console.log("OK93",obj);          
                if(obj.status==="204" ){ 
                    console.log("OK TOPICSUBLISTCONSIGLIO 204",xhr2);    
                    const MeetingInfo = JSON.parse(localStorage.getItem("meetingContentConsiglio"));                   
                    if( properties.verboseLog==1)
                        console.log("OK DOPOSTSEDUTE2 204",MeetingInfo);    
                    updateUI(MeetingInfo);
                }              
                else{
                    //status 201
                    console.log("OK TOPICSUBLISTCONSIGLIO 201",xhr2);                        
                    try{
                        localStorage.setItem("meetingContentConsiglio", obj.content);
                        localStorage.setItem("meetingConsiglio", meetingId);
                        localStorage.setItem("meetingVersionConsiglio", obj.date);                                                                  
                    }
                    catch(error) {
                        console.log("LOCALSTORAGE ISSUE", error);
                    }
                    const MeetingInfo = JSON.parse(obj.content);
                    if( properties.verboseLog==1)
                        console.log("OK DOPOSTSEDUTE2 201",MeetingInfo);
                    updateUI(MeetingInfo);
                }
            } 
            else {
                console.error("Error 1", xhr2.statusText);          
                alert("Errore recuperando la seduta");  
            }
        }
        };

    xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = "idf=" + meetingId;
    if (localStorage.meetingConsiglio===meetingId){                    
        const currentData=localStorage.getItem("meetingVersionConsiglio");       
        urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
    }    
    xhr2.send(urlEncodedData);
}

    useEffect(() => {
        if( !window.cordova){
            if( properties.verboseLog==1)
                console.log ("2");
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                        
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                    
                    if( properties.verboseLog==1)
                        console.log("OK",xhr);
                    updateSeduta(meetingId,doPostSeduta2,updateUI);
                } else {
                    console.error("Error 1", xhr);    
                    localStorage.clear();                                    
                    navigate("/login");  
                }
            }
        };
    
        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();          
          navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }

        if (window.cordova){
            updateSeduta("Consiglio/" + meetingId,doPostSeduta2,updateUI);
        }          
    }, []);

    const openNextPage = (type, url, what, index,item) => {        
        if (type === "container") {                    
            if(what ==="ai"){                                
                navigate("/consiglio/" + meetingId + "/"+ indexSector +"/"+ what);  
            }
            else{                            
                navigate("/consiglio/" + meetingId + "/"+ indexSector +"/"+ what);                 
            }
        } else {            
            window.open(url);
        }

    };
    return (
        <div className="content">
            {topicSubList.map((item, index) => (
                <div
                    className="common-list"
                    style={{ position: "relative", cursor: "pointer" }}
                    key={index}                    
                    onClick={() => openNextPage(item.type, item.url, item.what, index,item)}
                >

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            paddingLeft: "30px",
                        }}
                    >
                        <p
                            className= "textBlackSedute"
                            style={{                                
                                marginTop: "21px",
                                marginBottom: "21px",
                                marginLeft: "10px",
                            }}
                        >
                            {item.label}
                        </p>
                        {item.type === "container" && item.value!==0 ? (
                            <p
                                className= "textGreySedute"
                                style={{
                                    marginTop: "0px",
                                    paddingLeft: "10px",                                    
                                }}
                            >
                                Numero Pratiche:{" "}
                                        <span className= "textBlackSedute">
                                            {item.value}
                                        </span>
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div
                        style={{
                            color: "#879497",
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            height: "100%",
                            right: "0px",
                        }}
                    >
                        <div
                            style={{
                                paddingRight: "25px",
                                color: "#879497",
                                display: "flex",
                            }}
                        >
                            
                        <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>
                        </div>
                        <div
                            style={{
                                marginLeft: "10px",
                                width: "10px",
                                height: "100%",
                                background: item.color,
                                borderBottomRightRadius: "8px",
                                borderTopRightRadius: "8px",
                            }}
                        ></div>
                    </div>
                </div>
            ))}
        </div>
    );
}
