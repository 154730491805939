import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";
import {properties} from "../../utils/properties";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function SpecificSubTopic() {    
    let { idDossier, id} = useParams();
    if( properties.verboseLog==1)
        console.log("spec sub topic",id);
    //path="/fascicoli/:idDossier/proposteintervento/:id/:idMissione"
    const [subMeetingTopic, setSubMeetingTopic] = useState([]);
    const { pageRouter, setPageRouter } = useContext(RouterContext);    
    const navigate = useNavigate();    
    const meetingId = idDossier;    

    const updateUI = function(jsonData) {                    
        const keySubArgument = Object.entries(jsonData)[5][1];
        const valueSubArgument = Object.entries(keySubArgument);        
        var index16;
        valueSubArgument.forEach( (entry, index1) => {
                let key= entry[0];                
                if(key===id) {                                        
                    index16=index1;
                    if( properties.verboseLog==1)
                        console.log("index",entry, index16);
                }                                                
        });         
        const subTopics = valueSubArgument[index16][1].cardIds;
        var arrSubTopics = [];
        var arrSubTopic = [];
        for (var i = 0; i < subTopics.length; i++) {
            var subTopicType = subTopics[i].type;
            var subTopicId = subTopics[i].item_id;
            if (subTopicType === "premessa") {
                if( properties.verboseLog==1){
                    console.log("101",valueSubArgument[index16][1]);
                    console.log("label", valueSubArgument[index16][1].premesse[subTopicId]["label"]);
                    console.log("url", valueSubArgument[index16][1].premesse[subTopicId]["url"]);
                    console.log("color", valueSubArgument[index16][1].color);
                }
                arrSubTopic = {
                    label: valueSubArgument[index16][1].premesse[subTopicId]["label"],
                    url: valueSubArgument[index16][1].premesse[subTopicId]["url"],
                    color: valueSubArgument[index16][1].color,
                    type: "premessa",
                };
                arrSubTopics.push(arrSubTopic);
            } else if (subTopicType === "scheda") {
                const cardData = Object.entries(jsonData)[4][1][subTopicId]["index"]["fields"];
                var argomento = "";
                var sotto = "";
                var init = "";
                var po="";
                var local = "";
                var rs="";
                var price = "";
                var settore="";
                var tableItems = [];
                if( properties.verboseLog==1)
                    console.log("cc",cardData);
                for (var j = 0; j < cardData.length; j++) {
                    if (cardData[j]["label"] === "Obiettivo") {
                        argomento = cardData[j]["value"];
                    }
                    if (cardData[j]["label"] === "Settore") {
                        settore = cardData[j]["value"];
                    }
                    if (cardData[j]["label"] === "Missione") {
                        sotto = cardData[j]["value"];
                    }
                    if ((cardData[j]["label"] === "Ragione Sociale")||(cardData[j]["label"] === "Ragione sociale")) {                    
                        rs = cardData[j]["value"].trim();
                    }
                    if (cardData[j]["label"] === "Denominazione iniziativa") {
                        init = cardData[j]["value"];
                    }
                    if (cardData[j]["label"] === "Progetto Operativo") {
                        po = cardData[j]["value"];
                    }
                    if (cardData[j]["label"] === "Localizzazione") {
                        local = cardData[j]["value"];
                    }
                    if (cardData[j]["label"] === "Importo proposto") {
                        price = cardData[j]["value"];
                    }
                    if (cardData[j]["label"] === "così ripartito") {
                        if( properties.verboseLog==1)
                            console.log("table",cardData[j]["table"]);
                        for (var t = 1; t < cardData[j]["table"].length; t++) {
                            var tableItem = [];
                            tableItem = {
                                title: cardData[j]["table"][t][3],
                                content: cardData[j]["table"][t][4],
                                content2: cardData[j]["table"][t][5],
                            };
                            if( properties.verboseLog==1){
                                console.log("title",cardData[j]["table"][t][3]);
                                console.log("cont",cardData[j]["table"][t][4]);
                                console.log("cont2",cardData[j]["table"][t][5]);
                            }
                            tableItems.push(tableItem);
                        }
                    }
                }
                var schedaItem = {
                    argomento: argomento,
                    sotto: sotto,
                    init: init,
                    po:po,
                    rs:rs,
                    local: local,
                    price: price,
                    color: valueSubArgument[index16][1].color,
                    table: tableItems,
                    id: subTopicId,
                    type: "scheda",
                    settore: settore,
                };
                arrSubTopics.push(schedaItem);
            } else { //AMBITO???

                arrSubTopic = {
                    label: valueSubArgument[index16][1].containers[subTopicId]["label"],
                    value: valueSubArgument[index16][1].containers[subTopicId]["value"],
                    price:
                        valueSubArgument[index16][1].containers[subTopicId][
                            "totale_complessivo"
                        ],
                    cnt:
                        valueSubArgument[index16][1].containers[subTopicId][
                            "numero_schede"
                        ],
                    color: valueSubArgument[index16][1].color,
                    type: "container",
                    item_id: subTopicId,
                };
                if( properties.verboseLog==1)
                    console.log("rere",arrSubTopic);
                arrSubTopics.push(arrSubTopic);
            }
        }
        setSubMeetingTopic(arrSubTopics);      
    }



    const doPostSeduta2 = function() {
        if( properties.verboseLog==1)
            console.log("doing sedute post");
    var xhr2 = new XMLHttpRequest();                        
    xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/checkSeduta.php',true);
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.withCredentials = true;
    xhr2.onload = function (e) {      
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {                              
            if( properties.verboseLog==1)
                console.log("OK92",xhr2);
            var responseText = xhr2.responseText;                    
            var obj=JSON.parse(responseText);
            if( properties.verboseLog==1)
                console.log("OK93",obj);          
            if(obj.status==="204" ){ 
                console.log("OK SPECIFICSUBTOPIC 204",xhr2);  
                const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));                        
                updateUI(MeetingInfo);
            }              
            else{
                //status 201
                console.log("OK SPECIFICSUBTOPIC 201",xhr2);  
                try{
                    localStorage.setItem("meetingContent", obj.content);
                    localStorage.setItem("meeting", meetingId);
                    localStorage.setItem("meetingVersion", obj.date);                          
                }
                catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                }  
                
                const MeetingInfo = JSON.parse(obj.content);
                updateUI(MeetingInfo);
            }
        } 
        else {
          console.error("Error 1", xhr2.statusText);          
          alert("Errore recuperando la seduta");  
        }
      }
    };

    xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = "idf=" + meetingId;
    if (localStorage.meeting===meetingId){                    
        const currentData=localStorage.getItem("meetingVersion");        
        urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
    xhr2.send(urlEncodedData);
    }

    useEffect(() => {
        if( !window.cordova){            
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                
        
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
        xhr.withCredentials = true;        
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                    
                    if( properties.verboseLog==1)
                        console.log("OK",xhr);
                    updateSeduta(meetingId,doPostSeduta2,updateUI);
                } else {
                    console.error("Error 1", xhr);     
                    localStorage.clear();                                   
                    navigate("/login");  
                }
            }
        };
    
        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();          
          navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }

        if (window.cordova){
            updateSeduta(meetingId,doPostSeduta2,updateUI);
        }
     
    }, []);

    const openPdf = (type, url, item_id) => {
        if (type === "premessa") {
            window.open(url);
        }
        if (type === "container") {        
            navigate("/fascicoli/" + meetingId+"/proposteintervento/missioni/"+ id+"/"+ type+ "/" + item_id);  
        }
    };

    const openScheda = (id) => {      
        navigate("/fascicoli/" + meetingId+"/proposteintervento/schede/"+ id);
    };
    return (
        <div className="content">
            {subMeetingTopic.map((item, index) =>
                item.type === "premessa" ? (
                    <div
                        className="common-list"
                        style={{ position: "relative", cursor: "pointer", }}
                        key={index}
                        onClick={() => openPdf(item.type, item.url)}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                paddingLeft: "30px",
                            }}
                        >
                            <p className= "textBlackSedute"
                                style={{
                                    paddingLeft: "20px",
                                    //fontSize: "18px",
                                    //fontWeight: "bold",
                                }}
                            >
                                {item.label}
                            </p>
                        </div>
                        <div
                            style={{
                                color: "#879497",
                                display: "flex",
                                alignItems: "center",
                                position: "absolute",
                                height: "100%",
                                right: "0px",
                            }}
                        >
                            <div
                                style={{
                                    paddingRight: "25px",
                                    color: "#879497",
                                    display: "flex",
                                }}
                            >
                                
                            <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>                                
                            </div>
                            <div
                                style={{
                                    marginLeft: "10px",
                                    width: "10px",
                                    height: "100%",
                                    background: item.color,
                                    borderBottomRightRadius: "8px",
                                    borderTopRightRadius: "8px",
                                }}
                            ></div>
                        </div>
                    </div>
                ) : item.type === "scheda" ? (
                    <div
                        className="scheda-item"
                        style={{ borderRightColor: item.color, cursor: "pointer", }}
                        key={index}
                        onClick={() => openScheda(item.id)}
                    >
                        <div
                            className="common-list"
                            key={index}
                            style={{
                                position: "relative",
                                alignItems: "unset",
                                display: "unset",
                                position: "unset",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div>
                                    <div
                                        style={{
                                            paddingLeft: "30px",
                                            textAlign: "left",
                                        }}
                                    >

                                    {item.argomento ? (
                                            <p className= "textGreySedute "
                                                style={{                                                    
                                                    paddingLeft: "20px",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                Obiettivo:{" "}
                                                <span className= "textBlackSedute "
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                                    {item.argomento}
                                                </span>
                                            </p>
                                    ): (<></> )}

                                    {item.settore ? (
                                            <p className= "textGreySedute "
                                                style={{
                                                    //fontWeight: "bold",
                                                    //color: "#879497",
                                                    paddingLeft: "20px",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                Settore:{" "}
                                                <span className= "textBlackSedute "                                                    
                                                >
                                                    {item.settore}
                                                </span>
                                            </p>
                                            ): (<></> )}
                                            
                                    {item.sotto ? (
                                            <p className= "textGreySedute "
                                                style={{
                                                    //fontWeight: "bold",
                                                    //color: "#879497",
                                                    paddingLeft: "20px",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                Missione:{" "}
                                                <span className= "textBlackSedute "
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                                    {item.sotto}
                                                </span>
                                            </p>
                                    ): (<></>)}                                      
                                    </div>
                                    <div
                                        style={{
                                            textAlign: "left",
                                            paddingRight: "100px",
                                            paddingLeft: "50px",
                                            marginTop: "16px",
                                            marginBottom: "16px",
                                        }}
                                    >
                                      <div style={{
                                                display: "inline",                                                                                     
                                            }}>
                                                {item.init ? (
                                                    <p className= "textInLine textGreySedute" 
                                                    style={{
                                                      //  fontWeight: "bold",
                                                      //  color: "#879497",
                                                        marginBottom: "0px",
                                                      //  display: "inline",                                                
                                                       

                                                    }}
                                                    >
                                                        Denominazione Iniziativa:&nbsp;
                                                    </p>
                                                ) : (
                                                    <p className= "textInLine textGreySedute"
                                                    style={{
                                                        //fontWeight: "bold",
                                                        //fontSize: "18px",
                                                        //color: "#879497",
                                                        marginBottom: "0px",
                                                        //display: "inline",
                                                       
                                                    }}
                                                    >
                                                        Progetto Operativo:&nbsp;
                                                    </p>
                                                )}
                                                {item.init ? (
                                                    <p className= "textInLine textBlackSedute"
                                                        style={{
                                                            //fontWeight: "bold",
                                                            //color: "black",
                                                            marginBottom: "0px",
                                                            //display: "inline",
                                                       
                                                        }}
                                                    >
                                                        {item.init}
                                                    </p>
                                                ) : (
                                                    <p className= "textInLine textBlackSedute"
                                                        style={{
                                                            //fontWeight: "bold",
                                                            //color: "black",
                                                            marginBottom: "0px",
                                                            //display: "inline",
                                                          
                                                        }}
                                                    >
                                                        {item.po}
                                                    </p>
                                                    )}
                                            </div>

                                           { 
                                            item.rs ? (

                            <p className= "textGreySedute"
                                 //style={{
                                 //       fontWeight: "bold",
                                 //       color: "#879497",
                                 //       textAlign: "left",
                                 //}}
                            >
                                 Ragione Sociale:{" "}
                                            <span className= "textBlackSedute"
                                                //style={{
                                                //    fontWeight: "bold",
                                                //    color: "black",
                                                //}}
                                            >
                                                {item.rs}
                                            </span>
                            </p>
                        ) : (
                            <></>
                        )}                                
                                        <p className= "textGreySedute"
                                            //style={{
                                            //    fontWeight: "bold",
                                            //    color: "#879497",
                                            //    textAlign: "left",
                                            //}}
                                        >
                                            Localizzazione:{" "}
                                            <span className= "textBlackSedute"
                                                //style={{
                                                //    fontWeight: "bold",
                                                //    color: "black",
                                                //}}
                                            >
                                                {item.local}
                                            </span>
                                        </p>
                                        <p className= "textGreySedute"
                                            //style={{
                                            //    fontWeight: "bold",
                                            //    color: "#879497",
                                            //    textAlign: "left",
                                            //}}
                                        >
                                            Importo proposto:{" "}
                                            <span className= "textBlackSedute"
                                               // style={{
                                               //     fontWeight: "bold",
                                               //     color: "black",
                                               // }}
                                            >
                                                {item.price}
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        //color: "#879497",
                                        display: "flex",
                                        alignItems: "center",
                                        height: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingRight: "25px",
                                            //color: "#879497",
                                            display: "flex",
                                        }}
                                    >
                                        
                                        <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>                                
                                    </div>
                                </div>
                            </div>
                        </div>

                        {item.table.length ? (
                            <p className= "textGreyTable "
                                style={{
                                    marginTop: "0px",
                                    //fontWeight: "bold",
                                    //color: "#879497",
                                    //textAlign: "left",
                                    paddingLeft: "50px",
                                }}
                            >
                                Cosi Ripartito:
                            </p>
                        ) : (
                            <></>
                        )}
                        {item.table.map((itemlist, index) =>
                            index % 2 === 0 ? (
                                <div
                                    style={{
                                        textAlign: "left",
                                        paddingLeft: "50px",
                                        //color: "#879497",
                                        //fontWeight: "bold",
                                        background: "#EFEFF6",
                                    }}
                                >
                                    <p className= "textGreyTable "
                                        style={{
                                            display: "inline-block",
                                            width: "20%",
                                        }}
                                    >
                                        {itemlist.title}
                                    </p>
                                    <p className= "textGreyTable " style={{ display: "inline-block",width: "25%" }}>
                                        {itemlist.content}
                                    </p>
                                    <p className= "textGreyTable " style={{ display: "inline-block", width: "25%" }}>
                                        {itemlist.content2}
                                    </p>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        textAlign: "left",
                                        paddingLeft: "50px",
                                        //color: "#879497",
                                        //fontWeight: "bold",
                                    }}
                                >
                                    <p className= "textGreyTable "
                                        style={{
                                            display: "inline-block",
                                            width: "20%",
                                        }}
                                    >
                                        {itemlist.title}
                                    </p>
                                      <p className= "textGreyTable " style={{ display: "inline-block",width: "25%" }}>
                                        {itemlist.content}
                                    </p>
                                    <p className= "textGreyTable " style={{ display: "inline-block", width: "25%" }}>
                                        {itemlist.content2}
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                ) : (
                    <div
                        className="common-list"
                        style={{ position: "relative", cursor: "pointer", }}
                        key={index}
                        onClick={() =>
                            openPdf(item.type, item.url, item.item_id)
                        }
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                paddingLeft: "30px",
                                paddingBottom: "16px",
                            }}
                        >
                            <p className= "textBlackSedute"
                                style={{
                                    paddingLeft: "20px",
                                    //fontSize: "18px",
                                    //fontWeight: "bold",
                                }}
                            >
                                {item.label} {item.value}
                            </p>
                           
                            {item.cnt ? (
                                <p className= "textGreySedute "
                                    style={{
                                        marginTop: "0px",
                                        marginBottom: "0px",
                                        //color: "#879497",
                                        paddingLeft: "20px",
                                    }}
                                >
                                    Numero Pratiche:{" "}
                                    <span className= "textBlackSedute "
                                        //style={{
                                        //    fontWeight: "bold",
                                        //    color: "black",
                                        //}}
                                    >
                                        {item.cnt}
                                    </span>
                                </p>
                            ) : (
                                <></>
                            )}
                            {item.price ? (
                                <p className= "textGreySedute "
                                    style={{
                                        marginLeft: "0px",
                                        //color: "#879497",
                                        paddingLeft: "20px",
                                        marginBottom: "0px",
                                    }}
                                >
                                    Totale Complessivo:{" "}
                                    <span className= "textBlackSedute "
                                        //style={{
                                        //    fontWeight: "bold",
                                        //    color: "black",
                                        //}}
                                    >
                                        {item.price}
                                    </span>
                                </p>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div
                            style={{
                                color: "#879497",
                                display: "flex",
                                alignItems: "center",
                                position: "absolute",
                                height: "100%",
                                right: "0px",
                            }}
                        >
                            <div
                                style={{
                                    paddingRight: "25px",
                                    color: "#879497",
                                    display: "flex",
                                }}
                            >
                                
                                <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>                                
                            </div>
                            <div
                                style={{
                                    marginLeft: "10px",
                                    width: "10px",
                                    height: "100%",
                                    background: item.color,
                                    borderBottomRightRadius: "8px",
                                    borderTopRightRadius: "8px",
                                }}
                            ></div>
                        </div>
                    </div>
                )
            )}
        </div>
    );
}
