import {React,  useRef} from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import {properties} from "../../utils/properties";
import multiMonthPlugin from '@fullcalendar/multimonth'
import FullCalendar from '@fullcalendar/react'
import itLocale from '@fullcalendar/core/locales/it'
import interactionPlugin from "@fullcalendar/interaction" 
import 'tippy.js/dist/tippy.css';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import {ThreeDots} from 'react-loader-spinner';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import SweetAlert from "react-bootstrap-sweetalert";
import '../../assets/bootstrap-custom.css';

export default function CalendarIndice() {
  const navigate = useNavigate();
  var myRef= useRef()
  
  const [meetingData, setMeetingData] = useState([]);
  const [loading, setLoading]= useState(true);
  
  const [secondLevelButtons, setSecondLevelButtons]= useState(false);    
  const [deleteMode, setDeleteMode]= useState(false);    
  const [headerText, setHeaderText]= useState("Gestione Indice: Seleziona la seduta");    
  const [addMode, setAddMode]= useState(false);    
  const [labelColor, setLabelColor] = useState('#000000');
  const [alert, setAlert] = useState(null);

  const isInThePast = function(data) {     
    var ret=false;
    const d = new Date(data);
    const now = new Date();
    if(d<now)
        ret=true;
    return ret;
  }
  

  const updateUI = function(jsonData) {   
    if( properties.verboseLog==1)   
        console.log("UPDATEUI-CV");
    var entireMeeting = Object.entries(jsonData);                    
    var orderMeeting = [];    
      
    for (var i = entireMeeting.length - 1; i >= 0; i--) {                  
        var theColor="#FFFFFF";
        if(isInThePast(entireMeeting[i][1]["date"]))
            theColor="#000000";
        else{            
            if(entireMeeting[i][1]["status"]!="0")
                theColor="#FF0000";
            else
                theColor=entireMeeting[i][1]["color"];
        }
        var eachMeeting = {
            //date: entireMeeting[i][0],
            date: entireMeeting[i][1]["date"],
             
            color: theColor,
            backgroundColor: theColor,
            borderColor: theColor,                        
        };   
        orderMeeting.push(eachMeeting);   
    }            
    setMeetingData(orderMeeting);
}


const doPost11 = function(dateToBeAdded) {
        
    var xhr = new XMLHttpRequest();        
    //xhr.open('POST', properties.productionUrl+'/rest/addIndiceSedute',true);
    //xhr.open('POST', properties.productionUrl+'/rest/gestioneIndici',true);
    xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndici.php',true);
    xhr.withCredentials = true;
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    xhr.onload = function (e) {      
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {   
                if( properties.verboseLog==1)                           
                    console.log("OK92",xhr);
                var responseText = xhr.responseText;                    
                var obj=JSON.parse(responseText);
                if( properties.verboseLog==1)
                    console.log("OK93",obj,responseText);                      
                //const temp1= Object.entries(obj); 
                try{
                    localStorage.setItem("listIndici", obj.content);
                    localStorage.setItem("listIndiciVersion", obj.date);                                      
                }
                catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                }                          
                const jsonListIndici = JSON.parse(obj.content);
                updateUI(jsonListIndici);    
                setLoading(false);                 
            } 
            else {                
              alert("Errore recuperando la seduta raw richiesta 1");        
              setLoading(false);
              navigate("/indice/init",{ replace: true });  
            }
        }
    };

    xhr.onerror = function (e) {
        console.error("Error 2", xhr.statusText);
    };
      
    let urlEncodedData = "date=" + dateToBeAdded+"&action=addIndiceToSedute"; 
    if( properties.verboseLog==1)
        console.log("WERE",urlEncodedData);
    xhr.send(urlEncodedData);
}

const doPost12 = function(dateToBeRemoved) {
        
    var xhr = new XMLHttpRequest();        
    //xhr.open('POST', properties.productionUrl+'/rest/removeIndiceSedute',true);
    //xhr.open('POST', properties.productionUrl+'/rest/gestioneIndici',true);
    xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndici.php',true);
    xhr.withCredentials = true;
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    xhr.onload = function (e) {      
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {     
                if( properties.verboseLog==1)                         
                    console.log("OK96",xhr);
                var responseText = xhr.responseText;                    
                var obj=JSON.parse(responseText);
                if( properties.verboseLog==1)
                    console.log("OK97",obj,responseText);                      
                //const temp1= Object.entries(obj); 
                try{
                    localStorage.setItem("listIndici", obj.content);
                    localStorage.setItem("listIndiciVersion", obj.date);                                      
                }
                catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                }                          
                const jsonListIndici = JSON.parse(obj.content);
                updateUI(jsonListIndici);       
                setLoading(false);              
            } 
            else {                
              alert("Errore recuperando la seduta raw richiesta 1");        
              setLoading(false);
              //navigate("/init",{ replace: true });  
              navigate("/indice/init",{ replace: true });  

            }
        }
    };

    xhr.onerror = function (e) {
        console.error("Error 2", xhr.statusText);
    };
      
    let urlEncodedData = "date=" + dateToBeRemoved+"&action=removeIndiceFromSedute"; 
    if( properties.verboseLog==1)
        console.log("WERE",urlEncodedData);
    xhr.send(urlEncodedData);
}



  const doPostSedute2 = function() {
    if( properties.verboseLog==1)
        console.log("doing sedute post CV");
    var xhr2 = new XMLHttpRequest();                
    //xhr2.open('POST', properties.productionUrl+'/rest/checkIndiceSedute',true);
    //xhr2.open('POST', properties.productionUrl+'/rest/gestioneIndici',true);
    xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndici.php',true);
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.withCredentials = true;
    xhr2.onload = function (e) {      
        if (xhr2.readyState === 4) {
            if (xhr2.status === 200) {                              
                //console.log("OK92",xhr2);
                var responseText = xhr2.responseText;                    
                var obj=JSON.parse(responseText);
                if( properties.verboseLog==1)
                    console.log("OK93",obj, obj.status);          
                if(obj.status==="204" ){                             
                    
                    console.log("OK DOPOSTSEDUTE2 204",xhr2);                        
                    const jsonListIndici = JSON.parse(localStorage.getItem("listIndici"));
                    updateUI(jsonListIndici);    
                    setLoading(false);                
                }              
                else{
                    //status 201
                    
                    console.log("OK DOPOSTSEDUTE2 201",xhr2);  
                    
                    try{
                        localStorage.setItem("listIndici", obj.content);
                        localStorage.setItem("listIndiciVersion", obj.date);                                      
                    }
                    catch(error) {
                        console.log("LOCALSTORAGE ISSUE", error);
                    }                          
                    const jsonListIndici = JSON.parse(obj.content);
                    updateUI(jsonListIndici);   
                    setLoading(false);                     
                }
            } 
            else {                    
                console.log("Errore 3: Recuperando la lista sedute o non presenti sedute CdG per il ruolo dell'utente");                      
            }
        }
    };

    xhr2.onerror = function (e) {
        console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = null; 
    if (localStorage.listIndici!=='' &&localStorage.listIndici!==undefined){            
        //console.log( "QUINDI00");
        const currentData=localStorage.getItem("listIndiciVersion");        
        urlEncodedData = "date=" + currentData+"&action=checkIndiciSedute"; ;                    
    }   
    else
        urlEncodedData = "action=checkIndiciSedute";
    if( properties.verboseLog==1)
        console.log("CALVIEW",urlEncodedData);
    xhr2.send(urlEncodedData);
}





  const onFabClick= (id, e ) => { 
    if( properties.verboseLog==1)       
        console.log("OFC",e, id);     
    if(id=='selezionaSeduta'){
        setHeaderText("Gestione Indice: Seleziona seduta");     
        setSecondLevelButtons(false);
        setDeleteMode(false);
        setAddMode(false);        
        setLabelColor("#000000");
    }
    if(id=='aggiungiSeduta')
    {        
        setHeaderText("Gestione Indice: Aggiungi sedute");
        setSecondLevelButtons(false);
        setDeleteMode(false);
        setAddMode(true);        
        setLabelColor("#30720f");        
    }
    if(id=='rimuoviSeduta')
    {        
        setHeaderText("Gestione Indice: Rimuovi sedute");        
        setSecondLevelButtons(false);
        setAddMode(false);
        setDeleteMode(true);                
        setLabelColor("#b30000");
    }
  };

  
  const handleDateClick = (e) => {    
    if( properties.verboseLog==1)
        console.log("called topic list CV",e);   
    //alert('Clicked on: ' + e.dateStr);
    //alert('Coordinates: ' + e.jsEvent.pageX + ',' + e.jsEvent.pageY);
    //alert('Current view: ' + e.jsEvent.toElement);
    //console.log("DD",e.jsEvent);
    //console.log("DD2",e.dayEl);
    if( properties.verboseLog==1)
        console.log("events 0",meetingData);   
    var tmp21=e.date.toLocaleDateString('it-IT');  
    if(addMode){
            var tmp=e.dateStr;
            if( properties.verboseLog==1)
                console.log("ADD",tmp, isInThePast(tmp));
            const found = meetingData.some(e => e.date === tmp);
            if( properties.verboseLog==1)
                console.log("ADD",found);
            //console.log(events.indexOf[tmp]);            
            //console.log("CHECK",tmp, e.dayEl);
            if(!found){
                    //DA
                    setAlert(
                        <SweetAlert
                        warning
                        closeOnClickOutside ={true}
                        showCancel ={true}
                        showConfirm={true}
                        style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
                        
                        title={"Sei sicura di voler creare la seduta del "+tmp21}
                        onConfirm={() => {
                            setAlert(null);
                            setLoading(true);
                            doPost11(tmp);
                            setAddMode(false);
                            setHeaderText("Gestione Indice: Seleziona seduta");                                                                             
                            setLabelColor("#000000");
    
                        }}           
                        onCancel={() => {
                            setAlert(null);
                            //setDeleteMode(false);
                        }}
                        confirmBtnText={"Si"}    
                        focusCancelBtn= {true}                    
                        cancelBtnText={"No"}                       
                        confirmBtnCssClass="custom-btn custom-btn-success" 
                        cancelBtnCssClass="custom-btn custom-btn-cancel"
                        
                        
                        
                      >
                      </SweetAlert>
                      );
    
                    
                    //A               
            }
            else{
                console.log("none 3");
            }
            
        }
        else if(deleteMode){
            var tmp=e.dateStr;
            var tmp21=e.date.toLocaleDateString('it-IT');
            if( properties.verboseLog==1)
                console.log("DELETE",e.date.toLocaleDateString('it-IT'), tmp, isInThePast(tmp));       
            
            const found = meetingData.some(e => e.date === tmp);
            if( properties.verboseLog==1)
                console.log("DELETE",found);            
            if(found){
                setAlert(
                    <SweetAlert
                    warning
                    closeOnClickOutside ={true}
                    showCancel ={true}
                    showConfirm={true}
                    style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
                    
                    title={"Sei sicura di voler cancellare la seduta del "+tmp21}
                    onConfirm={() => {
                        setAlert(null);
                        setLoading(true);
                        doPost12(tmp);
                        setDeleteMode(false);
                        setHeaderText("Gestione Indice: Seleziona seduta");                                                                             
                        setLabelColor("#000000");

                    }}           
                    onCancel={() => {
                        setAlert(null);
                        //setDeleteMode(false);
                    }}
                    confirmBtnText={"Si"}    
                    focusCancelBtn= {true}                    
                    cancelBtnText={"No"}                       
                    confirmBtnCssClass="custom-btn custom-btn-success" 
                    cancelBtnCssClass="custom-btn custom-btn-cancel"
                    
                    
                    
                  >
                  </SweetAlert>
                  );                
            }
            else 
                console.log("none");
        }
        else{
            var tmp=e.dateStr;
            if( properties.verboseLog==1)
                console.log("SELECT",tmp, isInThePast(tmp));            
            const found = meetingData.some(e => e.date === tmp);
            if( properties.verboseLog==1)
                console.log("SELECT",found);
            if(found){
                if( properties.verboseLog==1)
                    console.log("selected", e.dayEl, e.jsEvent);    
                navigate("/indice/indici/"+tmp);       
            }
            else
                console.log("none2");
        }
        if( properties.verboseLog==1)
            console.log("events",meetingData);       
        //e.dayEl.style.backgroundColor = 'red';
        //e.dayEl.style.backgroundColor = '';
        //console.log("EDEE",e.dayEl.attributes,e.dayEl.attributes["style"],e.dayEl.attributes["style"].textContent, e.dayEl.attributes["style"].value);
        //e.dayEl.attributes["style"].textContent="background-Color: white;";
        //e.dayEl.attributes["style"].value="background-color: white;";
        //e.dayEl.attributes["style"].nodeValue="background-color: white;";
        //if(e.dayEl.attributes["bgcolor"]!=undefined){
        //    e.dayEl.attributes.removeNamedItem("bgcolor");
        //    e.dayEl.attributes.removeNamedItem("5");
        //}
        //e.dayEl.bgColor="";
        //console.log("DEDD",e.dayEl.attributes, e.dayEl.attributes["style"],e.dayEl.attributes["style"].textContent, e.dayEl.attributes["style"].value);
        //e.dayEl.style.textContent.style={{backgroundColor="white";
        //e.dayEl.style.textContent.value.backgroundColor="white";
//"background-color: white;"


        //e.dayEl.style.color='white';
        
        /*var content = '';                
                
        var a13="'/calendar";
        var a14= "'/";                   
        content='<div  style="background: white; padding: 2px; display: block;">'+
           '<div style="margin: 10px 0px">'+
                '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(48, 114, 15); height:20px; width:20px; max-width:20px;">'+
                '</div>'+
                '<input type="button" style="display: inline; cursor: pointer; text-align:left; width:90%; background: white; border: 0px; font-size: 15px"  onclick="location.href='+a13+'" value="Comitato di Gestione"/>'+
           '</div>'+
           '<div style="margin: 10px 0px">'+
                '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(191, 54, 12); height:20px; width:20px; max-width:20px;">'+
                '</div>'+                            
                '<input type="button" style="display: inline; cursor: pointer; width:90%; text-align:left; background: white; border: 0px; font-size: 15px" onclick="location.href='+a14+'" value="Consiglio Generale"/>'+
            '</div>'+
        '</div>'                           
        if (tooltip != null) {
          tooltip.destroy();
          tooltip = null;
        }                
        tooltip = tippy(e.jsEvent.toElement, {
            placement: 'right',
            theme: 'personal',
            trigger: 'manual',
            content: content,
            animateFill: false,
            interactive: true,
            animation: 'shift-away',
            allowHTML: true,                        
            arrow: true
        });
        tooltip.show();       
*/

    };

/*
    const handleDateClick = (arg) => {
        alert(arg.dateStr)
      }
  */  
 
    useEffect(() => {     
        if( properties.verboseLog==1)
            console.log("USE-EFFECT CV");            
       
        
                           
        //if(process.env.NODE_ENV !=='development' && !window.cordova){        
        if(!window.cordova){        
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            //xhr.open('POST', properties.productionUrl+'/rest/ping99',true);            
            xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
            xhr.withCredentials = true;            
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {   
                        if( properties.verboseLog==1)                      
                            console.log("OK",xhr);   
                        doPostSedute2();
                } else {
                    console.error("Error 1", xhr);   
                    localStorage.clear();
                    setLoading(false);                             
                    navigate("/indice/login");  
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();                
                navigate("/indice/login");  
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
            
    }, []);


   


 
  return (
    <div  style={{padding: "30px 5%", minHeight: "calc(100vh - 136px)"}}>
    {loading ? (
        <div style={{ width:"25%",   display: "flex",
        marginLeft: "auto", marginRight: "auto" }}>
         <ThreeDots color="#30720f" height='350' width='150'/>
         </div>
        ):(
            <div >
        
            {secondLevelButtons && localStorage.getItem("ruolo")==5? (
                        <div>
                        <Tooltip title="Seleziona Seduta" placement="left">
                            <Fab aria-label="add2"                 
                                //variant="contained" 
                                size='medium'
                                onClick={(event) => {onFabClick("selezionaSeduta",event) }}                        
                                style={{
                                    margin: 0,
                                    background: "#A2EE7D",
                                    top: 'auto',                     
                                    right: 20,
                                    bottom: 200,                      
                                    left: 'auto',
                                    position: 'fixed'}} >                    
                                    <AllInclusiveIcon />                        
                            </Fab>       
                        </Tooltip> 
                            <Tooltip title="Aggiungi Seduta" placement="left">
                            <Fab aria-label="add2"                 
                                //variant="contained" 
                                size='medium'
                                onClick={(event) => {onFabClick("aggiungiSeduta",event) }}
                                
                                style={{
                                    margin: 0,
                                    background: "#A2EE7D",
                                    top: 'auto',                     
                                    right: 20,
                                    bottom: 90,                      
                                    left: 'auto',
                                    position: 'fixed'}} >                    
                                    <AddIcon />                        
                            </Fab>       
                        </Tooltip>
                        <Tooltip title="Rimuovi Seduta" placement="left">
                            <Fab aria-label="add3"                                   
                                size='medium'
                                onClick={(event) => {onFabClick("rimuoviSeduta",event) }}                  
                                style={{
                                    margin: 0,
                                    background: "#A2EE7D",
                                    top: 'auto',                     
                                    right: 20,
                                    bottom: 145,
                                    //color: '0xFF0000',
                                    left: 'auto',
                                    position: 'fixed',}}>                    
                                    <RemoveIcon />
                            </Fab>
                        </Tooltip>       
                    </div> ):(  <div> </div>)}
            {localStorage.getItem("ruolo")==5? (        
                    <Tooltip title="Modifica" placement="left">
                    <Fab aria-label="add"                 
                        //variant="contained" 
                        size="large" 
                        onClick={(event) => {setSecondLevelButtons(!secondLevelButtons) }}
                        style={{
                            //margin: 0,
                            background: "#30720f",
                            //background: "#CCCCCC",
                            //top: 'auto',
                            //left: 10,
                            right: 20,
                            bottom: 25,
                            color: '0xFFFFFF',
                            //left: 'auto',
                            position: 'fixed',}}>                  
                            <MoreHorizIcon />                    
                    </Fab>  
                </Tooltip>):(<></>)}
                
            <FullCalendar      
                ref={myRef}
                plugins={[ multiMonthPlugin,interactionPlugin ]}
                initialView="multiMonthYear"
                locale={itLocale}
            
                height="auto"            
                multiMonthMaxColumns= {4}            
                eventDisplay={'background'}
                contentHeight="auto"     
                aspectRatio={2.5} 
                       
                headerToolbar={{
                    //left: 'prev,next today',
                    right: 'prev,next today',
                    center: 'title',
                    left: '',
                }}
                                        
            dateClick={handleDateClick}
            //events={events}    
            events={meetingData}    
            />
                  
        </div>
        )}
        <div>
        {alert}        
        </div>
        </div>
  );
  
  }  
