import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Typography, List, ListItem, ListItemIcon,
  ListItemText, TextField, Collapse, Menu, MenuItem
} from '@mui/material';

import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PublishIcon from '@mui/icons-material/Publish';
import RefreshIcon from '@mui/icons-material/Refresh';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import SweetAlert from "react-bootstrap-sweetalert";
import axios from 'axios';
import { AiFillFilePpt } from "react-icons/ai";
import { AiOutlineFilePdf } from "react-icons/ai";
import { AiOutlineFileImage } from "react-icons/ai";
import { AiOutlineFile } from "react-icons/ai";
import { AiOutlineFileWord } from "react-icons/ai";
import { AiOutlineFileExcel } from "react-icons/ai";
import { AiFillFolderOpen } from "react-icons/ai";
import { AiFillFolder } from "react-icons/ai";
import {properties} from "../../utils/properties";
import {ThreeDots} from 'react-loader-spinner';

const FileExplorer = () => {
  const [fileTree, setFileTree] = useState({ folders: {}, files: [] });
  //const [newFolderName, setNewFolderName] = useState('');
  const [currentDirectory, setCurrentDirectory] = useState('/');
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openFolders, setOpenFolders] = useState({});
  const [draggedItem, setDraggedItem] = useState(null);  
  const [isEditing, setIsEditing] = useState(null); 
  const [editedName, setEditedName] = useState(''); 
  const [loading, setLoading]= useState(true);
  const [alert, setAlert] = useState(null);
  
  const navigate = useNavigate();

  const showAlert = (message) => {
    setAlert(
      <SweetAlert
      warning
      closeOnClickOutside ={true}      
      showConfirm={false}
      style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}      
      title={message}
      onConfirm={() => setAlert(null)}           
      onCancel={() => setAlert(null)}                 
    >
    </SweetAlert>
    );
  };


  useEffect(() => {
    if( properties.verboseLog==1)
      console.log("TEST");
    if(!window.cordova){            
      //CHECK AUTHENTICATION
          var xhr = new XMLHttpRequest();                          
          xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
          xhr.withCredentials = true;
          xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  
          xhr.onload = function (e) {
              if (xhr.readyState === 4) {
                  if (xhr.status === 200) {                                      
                    
              } else {
                  console.error("Error 1", xhr);     
                  localStorage.clear();                                 
                  if( properties.verboseLog==1)
                    console.log("QWERTY 1",localStorage.getItem("name"));                  
                  navigate("/login");    
              }
          }
          };
  
          xhr.onerror = function (e) {
              console.error("Error 2", xhr);                                              
              localStorage.clear();       
              if( properties.verboseLog==1)
                console.log("QWERTY 2",localStorage.getItem("name"));              
              navigate("/login");    
          };
           
          let urlEncodedData = null; 
          xhr.send(urlEncodedData);
      //CHECK AUTHENTICATION
      }
    fetchFiles();  
   
  }, [currentDirectory]);
  
  const fetchFiles = async () => {
    if( properties.verboseLog==1)
      console.log("FECTCH FILES");
    const formData = new FormData();              
    formData.append('action', "list");
    if (!window.cordova){
        try {            
          let endpoint = properties.productionUrl+properties.productionUrlSuffix +"/core/rest/fmFunctions.php"; 
          const response = await axios.post(endpoint,formData, { withCredentials: true });

          const data = response.data;          

          if (data && typeof data === 'object') {
            if( properties.verboseLog==1)
              console.log("DATA",data);
            setFileTree(data);
            const initialOpenFolders = {};
            
            const setInitialFolders = (node) => {
              Object.entries(node.folders).forEach(([folder,value]) => {
                if( properties.verboseLog==1)
                  console.log("II",folder,value, value.fullPath);
                initialOpenFolders[value.fullPath] = true; 
                setInitialFolders(node.folders[folder]); 
              });
            };
            setInitialFolders(data);
            setOpenFolders(initialOpenFolders);
            setLoading(false);
          } else {
            console.error("Invalid data format");
          }
        } catch (error) {
          console.error("Error fetching files", error);
        }
    }
    else{
      if( properties.verboseLog==1)
        console.log("CORDOVA");
      const bodyasString = new URLSearchParams(formData).toString();
      if( properties.verboseLog==1)
        console.log("SETTINGS",bodyasString);

      var onSuccess = function(response){      
        if( properties.verboseLog==1)
          console.log("on success",response);
        var obj=JSON.parse(response);
        
        if( properties.verboseLog==1)
          console.log("on success2",obj);       
        
        const data = obj;   
        if( properties.verboseLog==1)
          console.log("on success3",data);
        if (data && typeof data === 'object') {
          if( properties.verboseLog==1)
            console.log("on success3","into");
          
          setFileTree(data);
          const initialOpenFolders = {};
          
          const setInitialFolders = (node) => {
            Object.entries(node.folders).forEach(([folder,value]) => {
              if( properties.verboseLog==1)
                console.log("II",folder,value, value.fullPath);
              initialOpenFolders[value.fullPath] = true; 
              setInitialFolders(node.folders[folder]); 
            });
          };
          setInitialFolders(data);
          setOpenFolders(initialOpenFolders);
          setLoading(false);  
      }
      else{
          console.error("Invalid data format");      
      }
    }
      var onError = function() {
          console.log("on error");
          setLoading(false);  
      }      
      
      window.getRepo(bodyasString,onSuccess, onError);
    }
  
    
  };


  const handleContextMenu = (event, item) => {
    event.preventDefault();
    setSelectedItem(item);
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
    setSelectedItem(null);
  };

  const handleDeleteFile = async () => {
    if (!selectedItem.name) return;
    if( properties.verboseLog==1)
      console.log("HDFO",selectedItem.name, selectedItem);
    setLoading(true);
    const formData = new FormData();
        
    formData.append('dir', selectedItem.fullPath); 
    formData.append('id', selectedItem.id); 
    formData.append('action', "fileDelete"); 

    try {        
        const endpoint = `${properties.productionUrl}${properties.productionUrlSuffix}/core/rest/fmFunctions.php`;
        await axios.post(endpoint, formData, {withCredentials: true}        
      );
      fetchFiles(); 
      handleCloseContextMenu(); 
      setLoading(false);
    } catch (error) {
      //alert("Error deleting file.");
      showAlert("Errore cancellando il file selezionato");
      console.error("Error deleting file:", error);
      setLoading(false);
    }
  };


  const findFilePath = (tree, targetName, currentPath = []) => {
    
    if (tree.files && tree.files.some(file => file.name === targetName)) {
      return [...currentPath]; 
    }
  
    if (tree.folders && tree.folders.hasOwnProperty(targetName)) {
        return [...currentPath, targetName]; 
    }
    
    if (tree.folders) {
      for (let folderName in tree.folders) {
        const result = findFilePath(tree.folders[folderName], targetName, [...currentPath, folderName]);
        if (result) {
          return result;
        }
      }
    }
  
    return null; 
  };

  const handleDeleteFolder = async () => {
    if( properties.verboseLog==1)
      console.log("HDF",selectedItem.fullPath);
    if (!selectedItem.name) return;
    if( properties.verboseLog==1)
      console.log("HDFO",selectedItem.name, selectedItem);
    setLoading(true);
    const formData = new FormData();
        
    formData.append('dir', selectedItem.fullPath); 
    formData.append('id', selectedItem.id); 
    formData.append('action', "folderDelete"); 

    try {        
        const endpoint = `${properties.productionUrl}${properties.productionUrlSuffix}/core/rest/fmFunctions.php`;
        await axios.post(endpoint, formData, {withCredentials: true}
      );
      fetchFiles(); 
      handleCloseContextMenu(); 
      setLoading(false);
    } catch (error) {
      showAlert("Errore cancellando la cartella: la cartella deve essere vuota per poter essere cancellata");      
      console.error("Error deleting folder:", error);
      setLoading(false);
    }
  };
  

  const handleDragStart = (item) => {
    if( properties.verboseLog==1)
      console.log("DRAGGING START", item)
    setDraggedItem(item);
  };

  const handleDrop = async (event, targetFolder) => {    
    setLoading(true);
    if( properties.verboseLog==1)
      console.log("FILE DOPPED", event,targetFolder,draggedItem);
    event.preventDefault();
    
    //if (!draggedItem) return;
    if (!draggedItem){
    const files = event.dataTransfer.files; // Ottieni i file caricati
    if (files.length > 0) {
      console.log('Files dropped:', files[0]);            
      event.stopPropagation(); 
      const endpoint = `${properties.productionUrl}${properties.productionUrlSuffix}/core/rest/fmFunctions.php`;         
      let file=files[0];
      var bodyFormData=new FormData();
      console.log('dir', targetFolder.fullPath, targetFolder.id);
      
      if( properties.verboseLog==1)    
        console.log("PROAT22",file, targetFolder.fullPath);
      bodyFormData.append('UploadedFile', file);    
      bodyFormData.append('dir', targetFolder.fullPath);
      bodyFormData.append('id', targetFolder.id);
      bodyFormData.append('action', "fileUpload");
      
      axios({
          method: 'post',
          url: endpoint,
          data: bodyFormData, 
          withCredentials:true,
          headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {                  
          if( properties.verboseLog==1)
            console.log(response);
          event.target.files=null;
          event.target.value=null;
          fetchFiles();
          handleCloseContextMenu(); 
          setLoading(false);
      })
      .catch(function (response) {       
          showAlert("Esiste già un file con lo stesso nome o si sta cercando di caricare un file non supportato!");
          console.log(response);
          handleCloseContextMenu(); 
          setLoading(false);
      });
    }    
    }  
    else{
    if( properties.verboseLog==1){
      //console.log("2323",Array.from(event, targetFolder));
      console.log("2324",Array.from(event.dataTransfer.files));    
    }
    try {
        const formData = new FormData();
        formData.append('name', targetFolder.fullPath);
        formData.append('dir', draggedItem.fullPath);
        formData.append('id', draggedItem.id);  
        formData.append('action', "fileMove");     
        const endpoint = `${properties.productionUrl}${properties.productionUrlSuffix}/core/rest/fmFunctions.php`;
        await axios.post(endpoint, formData, {withCredentials: true}                
        );                
        setDraggedItem(null);
        fetchFiles(); 
        handleCloseContextMenu(); 
        setLoading(false);
      } catch (error) {
        console.error("Error renaming item", error);
        showAlert("Esiste già un file con lo stesso nome nella cartella di destinazione, modificare il nome del file prima di caricarlo");
        setLoading(false);
      }          
    }
  };

const handleMenuItemClick  = async function(e,item) {           
  if( properties.verboseLog==1){
    console.log("HMIC",item,e);
    console.log("CD", currentDirectory,`${currentDirectory}${item.name}/`);
  }  
    const formData = new FormData();
    var cd=`${currentDirectory}${item.name}/`;    
    formData.append('folderName', 'Nuova Cartella');    
    formData.append('dir', item.fullPath);
    formData.append('id', item.id);
    formData.append('action', "folderCreate");
    try {
      const endpoint = `${properties.productionUrl}${properties.productionUrlSuffix}/core/rest/fmFunctions.php`;
      await axios.post(endpoint, formData, {withCredentials: true}      
      );
      //setNewFolderName('');
      fetchFiles();
      handleCloseContextMenu(); 
    } catch (error) {
      console.error("Error creating folder", error);
    }   
    e.stopPropagation();          
};

  const handleFolderClick = (folderName, fullPath, id) => {
    if( properties.verboseLog==1)
      console.log("HANDLEFOLDERCLiK",folderName, fullPath, id, isEditing,openFolders);
    if (isEditing && isEditing.id === id &&isEditing.fullPath===fullPath) return;
    setOpenFolders((prevOpenFolders) => ({
      ...prevOpenFolders,
      [fullPath]: !prevOpenFolders[fullPath], 
    }));
  };

  const handleFileClick = (fullPath, id) => {
    if( properties.verboseLog==1)
      console.log("HANDLEFileCLiK", fullPath, id);
    window.open(properties.productionUrl+"/fileManager/files/fm24"+fullPath);    
  };

const handleCloseEditing= (event) => {
  if( properties.verboseLog==1)
    console.log("CALLED CLOSE EDITING", isEditing);
  event.stopPropagation();
  event.preventDefault();  
  setIsEditing(null);        
};

const handleRename = () => {
    if (selectedItem) {
      setIsEditing(selectedItem);  
      setEditedName(selectedItem.name);  
      handleCloseContextMenu();      
    }
};

const handleRenameFileSubmit  = async () => {    
    if (!editedName || editedName === isEditing.name) {
      setIsEditing(null);  
      return;
    }
    setLoading(true);
    if( properties.verboseLog==1){
      console.log("1", editedName.split('.').pop())
      console.log("2", isEditing.fullPath.split('.').pop())
    }
    if(editedName.split('.').pop()!=isEditing.fullPath.split('.').pop()){
      showAlert("Non è possibile cambiare l'estensione del file!");
      setIsEditing(null);  
      fetchFiles(); 
      setLoading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append('name', editedName);
      formData.append('dir', isEditing.fullPath);
      formData.append('id', isEditing.id);
      formData.append('action', "fileRename");
      
      const endpoint = `${properties.productionUrl}${properties.productionUrlSuffix}/core/rest/fmFunctions.php`;
      await axios.post(endpoint, formData, {withCredentials: true}    
      );      
      setIsEditing(null);  
      fetchFiles();  
      handleCloseContextMenu(); 
      setLoading(false);
    } catch (error) {
        console.error("Error renaming item", error);
        showAlert("Esiste già un file con il nome utilizzato, scegliere un altro nome di file e riprovare");      
        setIsEditing(null);              
        fetchFiles();  
        handleCloseContextMenu(); 
        setLoading(false);
      }
  };


  const onFileChange= (e,item ) => {   
    setLoading(true);
    if( properties.verboseLog==1){
      console.log("ew3",e); 
      console.log("ew4",item);
    }
        
    e.stopPropagation(); 
    const endpoint = `${properties.productionUrl}${properties.productionUrlSuffix}/core/rest/fmFunctions.php`;         
    let file=e.target.files[0];
    var bodyFormData=new FormData();
    if( properties.verboseLog==1)    
      console.log("PROAT",file, item.fullPath,item.id);
    bodyFormData.append('UploadedFile', file);    
    bodyFormData.append('dir', item.fullPath);
    bodyFormData.append('id', item.id);
    bodyFormData.append('action', "fileUpload");
    
    axios({
        method: 'post',
        url: endpoint,
        data: bodyFormData, 
        withCredentials:true,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(function (response) {                  
        if( properties.verboseLog==1)
          console.log(response);
        e.target.files=null;
        e.target.value=null;
        fetchFiles();
        handleCloseContextMenu(); 
        setLoading(false);
    })
    .catch(function (response) {       
        showAlert("Esiste già un file con lo stesso nome o si sta cercando di caricare un file non supportato!");
        console.log(response);
        handleCloseContextMenu(); 
        setLoading(false);
    });
    
};

  const handleRenameSubmit = async () => {
    if( properties.verboseLog==1)
      console.log("RENAMING AND SETLOADING");
    setLoading(true);
    if (!editedName || editedName === isEditing.name) {
      setIsEditing(null);
      setLoading(false);  
      return;
    }

    try {
        const formData = new FormData();
        formData.append('name', editedName);
        formData.append('dir', isEditing.fullPath);
        formData.append('id', isEditing.id);
        formData.append('action', "folderRename");
        const endpoint = `${properties.productionUrl}${properties.productionUrlSuffix}/core/rest/fmFunctions.php`;
        await axios.post(endpoint, formData, {withCredentials: true}        
        );

      setIsEditing(null); 
      fetchFiles();  
      handleCloseContextMenu(); 
      setLoading(false);
    } catch (error) {
      console.error("Error renaming item", error);
      showAlert("Esiste già una cartella con lo stesso nome, scegliere un altro nome di file e riprovare");      
      setIsEditing(null);              
      fetchFiles();  
      handleCloseContextMenu(); 
      setLoading(false);
    }
  };

  function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    if(hour<10)
      hour="0"+hour;
    var min = a.getMinutes();
    if(min<10)
      min="0"+min;
    var sec = a.getSeconds();
    if(sec<10)
      sec="0"+sec;
    var time = date + ' ' + month + ' ' + year + ' ,' + hour + ':' + min + ':' + sec ;
    return time;
  }

  const renderFileTree = (node, parentPath = '', level = 0) => {
    let draggedStarted=false;

    const autoScroll = (event) => {
      //if( properties.verboseLog==1)
      //  console.log("AUTOSCROLL",event,window);
      const scrollMargin = 150; 
      const scrollSpeed = 10;  
  
      const { clientY } = event;       
      const { innerHeight } = window; 
    
      if (clientY < scrollMargin) {
        window.scrollBy(0, -scrollSpeed);
        //if( properties.verboseLog==1)
        //  console.log("AUTOSCROLL",event,window);        
      }
      
      if (clientY > innerHeight - scrollMargin) {
        window.scrollBy(0, scrollSpeed);
        //if( properties.verboseLog==1)
        //  console.log("AUTOSCROLL",event,window);
      }             
    };

    return (
      <>
        {node.files.map(file => (       
          <ListItem
            key={file.name}            
            
            onContextMenu={(event) => handleContextMenu(event, file)}
            draggable
            onDragStart={() => handleDragStart(file)}
            onDragOver={autoScroll}        
            onClick={() => (isEditing?.id === file.id && isEditing?.fullPath===file.fullPath)? undefined : handleFileClick(file.fullPath,file.id)}            
            style={{ paddingLeft: `${level * 30}px` , cursor: 'pointer' }}  // Indentation
          >
            <ListItemIcon onClick={(event) => handleCloseEditing(event)}>
            
              {file.fullPath.endsWith(".pdf")?(
                <AiOutlineFilePdf size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".jpg") ||file.fullPath.endsWith(".jpeg")||file.fullPath.endsWith(".png")||file.fullPath.endsWith(".gif")?(                
                <AiOutlineFileImage size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".ppt") ||file.fullPath.endsWith(".pptx")?(                                
                <AiFillFilePpt size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".doc") ||file.fullPath.endsWith(".docx")?(                                                
                <AiOutlineFileWord size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".xls") ||file.fullPath.endsWith(".xlsx")?(                                
                <AiOutlineFileExcel size='2.2em' color='#4A4C4D'/>
              )
              :(                
                <AiOutlineFile size='2.2em' color='#4A4C4D'/>
              )}              
            </ListItemIcon>
            
            {(isEditing?.id === file.id && isEditing?.fullPath===file.fullPath) ?(
              <TextField                            
                onFocus={event => {
                  event.target.select();
                }}        
                value={editedName}                   
                fullWidth                         
                variant="outlined"
                label="Nome File"
                onChange={(e) => setEditedName(e.target.value)}
                onBlur={handleRenameSubmit}
                onKeyPress={(e) => e.key === 'Enter' && handleRenameFileSubmit()}                
              />
            ) : (
              <ListItemText primary={file.name}  
                  secondary={timeConverter(file.id)}></ListItemText> 
            )}
          </ListItem>        
        ))}
        
        {Object.keys(node.folders).map(key => {
          const folder = node.folders[key];
          if( properties.verboseLog==1)
            console.log("RFT23",node,key, folder,folder.fullPath, );
          return (
            <React.Fragment key={key}>
              <ListItem                
                onContextMenu={(event) => handleContextMenu(event, { name: key, isDir: true, fullPath: folder.fullPath, id: folder.id })}
                onClick={() => handleFolderClick(key, folder.fullPath,folder.id)}             
                onDragOver={(event) => {event.preventDefault(); autoScroll(event);} }
                onDrop={(event) => handleDrop(event, node.folders[key])}
                style={{ paddingLeft: `${level * 30}px` }}
              >
                <ListItemIcon onClick={(event) => handleCloseEditing(event)}>
                  {(Object.keys(folder.folders).length >0 ||Object.keys(folder.files).length >0) && folder.fullPath.startsWith("/Segretario Generale") ? (                          
                    <AiFillFolderOpen size='2.2em' color='#000000'/>
                  ):
                  (Object.keys(folder.folders).length >0 ||Object.keys(folder.files).length >0) && folder.fullPath.startsWith("/Materiale Istituzionale") ? (
                    <AiFillFolderOpen size='2.2em' color='#BDBDBD'/>
                  ):
                  (Object.keys(folder.folders).length >0 ||Object.keys(folder.files).length >0) && folder.fullPath.startsWith("/Comitato di Gestione") ? (
                    <AiFillFolderOpen size='2.2em' color='#30720f'/>
                  ):
                  (Object.keys(folder.folders).length >0 ||Object.keys(folder.files).length >0) && folder.fullPath.startsWith("/Consiglio Generale") ? (
                    <AiFillFolderOpen size='2.2em' color='#BF360C'/>
                  ):                  
                  (Object.keys(folder.folders).length ==0 && Object.keys(folder.files).length ==0) && folder.fullPath.startsWith("/Segretario Generale") ? (                          
                    <AiFillFolder size='2.2em' color='#000000'/>
                  ):
                  (Object.keys(folder.folders).length ==0 && Object.keys(folder.files).length ==0) && folder.fullPath.startsWith("/Materiale Istituzionale") ? (
                    <AiFillFolder size='2.2em' color='#BDBDBD'/>
                  ):
                  (Object.keys(folder.folders).length ==0 && Object.keys(folder.files).length ==0) && folder.fullPath.startsWith("/Comitato di Gestione") ? (
                    <AiFillFolder size='2.2em' color='#30720f'/>
                  ):
                  (Object.keys(folder.folders).length ==0 && Object.keys(folder.files).length ==0) && folder.fullPath.startsWith("/Consiglio Generale") ? (
                    <AiFillFolder size='2.2em' color='#BF360C'/>
                  ):
                  (
                    <AiFillFolder size='2.2em' color='#30720f'/>
                  )}
                </ListItemIcon>
                {isEditing?.id === folder.id && isEditing?.fullPath===folder.fullPath ? (
                  <TextField     
                  onFocus={event => {
                    if( properties.verboseLog==1)
                      console.log("EVENT",event);                                   
                    event.target.select();
                  }}                     
                    value={editedName}
                    variant="outlined"
                    
                    label="Nome cartella"                    
                    onChange={(e) => setEditedName(e.target.value)}
                    onBlur={handleRenameSubmit}
                    onKeyPress={(e) => e.key === 'Enter' && handleRenameSubmit()}
                    fullWidth   
                  />
                ) : (
                  <ListItemText primary={key}  />
                
                )}
                {openFolders[folder.fullPath] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openFolders[folder.fullPath]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {renderFileTree(folder, `${parentPath}/${key}`, level + 1)}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <div className="content">
 {loading ? (
    <div style={{ width:"25%",   display: "flex",
        marginLeft: "auto", marginRight: "auto" }}>
         <ThreeDots color="#30720f" height='350' width='150'/>
    </div>
    ):(
    <div style={{ backgroundColor:"white", padding: '20px', paddingLeft:"30px",paddingRight:"30px", borderRadius:4,
    border: "1px solid #C5C5C5"}}>   
      <Tooltip title="Aggiorna" placement="left">
            <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {fetchFiles() }}                
                style={{
                    margin: 0,
                    background: "#30720f",
                    top: 'auto',
                    right: 5,
                    bottom: 25,
                    //color: '0xFF0000',
                    left: 'auto',
                    position: 'fixed',}}>
                    <RefreshIcon fontSize='large'/>
                </Fab>          
            </Tooltip> 
      <List >
        {renderFileTree(fileTree)}
      </List>

      <Menu
        sx={{padding:"20px"}}
        open={contextMenu !== null}
        aria-hidden={contextMenu == null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
          {selectedItem && selectedItem.isDir && (
        <MenuItem  styles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,selectedItem)}> 
        <CreateNewFolderIcon fontSize="large" />
          
         <Typography variant="body1"  style={{  paddingLeft:"10px" }}>
         Crea Nuova Cartella
        </Typography>
         </MenuItem>  
        
        )}
            {selectedItem && selectedItem.isDir && (
            <div>
                <input id={"file-upload"+selectedItem.id} type="file" 
                onChange={(event)=> onFileChange(event,selectedItem)} 
                style={{ display: "none" }} 
                accept=".doc,.docx,.pdf,.xls,.xlsx,.gif,.png,.jpg,.jpeg"
                />    
                <label htmlFor={"file-upload"+selectedItem.id}>  
                <MenuItem  styles={{backgroundColor:'#F3F3F3', width:'90%'}} > <PublishIcon fontSize="large"/> 
                
                <Typography variant="body1"  style={{  paddingLeft:"10px" }}>
                Carica File 
                </Typography>
                </MenuItem>  
                </label>
            </div>    
        )}
        <MenuItem onClick={handleRename}>
          <EditIcon fontSize="large"/> 
          <Typography variant="body1"  style={{  paddingLeft:"10px"}}>
          Rinomina {selectedItem?.isDir ? 'Cartella' : 'File'}
          </Typography>
          
        </MenuItem>
        {selectedItem && selectedItem.isDir && (
          <MenuItem onClick={handleDeleteFolder}>
            <DeleteIcon fontSize="large"/> 
            <Typography variant="body1"  style={{  paddingLeft:"10px" }}>
              Elimina Cartella
            </Typography>
          </MenuItem>
        )}
        {selectedItem && !selectedItem.isDir && (
          <MenuItem onClick={handleDeleteFile}>
            <DeleteIcon fontSize="large"/> 
            <Typography variant="body1"  style={{  paddingLeft:"10px" }}>
              Elimina File
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>)}
    <div>
        {alert}        
    </div>
    </div>
     
  );
};

export default FileExplorer;
